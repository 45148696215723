import { ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { CatalogBarrelReducer } from "../reducer/CatalogBarrelReducer";

const CatalogBarrelContext = createContext({
  state: CatalogBarrelReducer.getInitialState(),
  actions: CatalogBarrelReducer.actions,
});

export const useCatalogBarrelContext = () => useContext(CatalogBarrelContext);

export const CatalogBarrelProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [state, actions] = useComplexState({
    initialState: CatalogBarrelReducer.getInitialState(),
    reducers: CatalogBarrelReducer.caseReducers,
  });
  return (
    <CatalogBarrelContext.Provider value={{ state, actions }}>
      {children}
    </CatalogBarrelContext.Provider>
  );
};
