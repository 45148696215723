export interface CatalogCarrier {
	PartId: number | null;
	PartName: string;
	RFIDTag: string | null;
	LocationName: string | null;
	RollsetId: number | null;
	UpperRollId: number | null;
	UpperRollName: string | null;
	LowerRollId: number | null;
	LowerRollName: string | null;
	UMChockId: number | null;
	UMChockName: string | null;
	LMChockId: number | null;
	LMChockName: string | null;
	UOChockId: number | null;
	UOChockName: string | null;
	LOChockId: number | null;
	LOChockName: string | null;
	EntryGuideId: number | null;
	EntryGuideName: string | null;
	ExitGuideId: number | null;
	ExitGuideName: string | null;
	CartridgeId: number | null;
	Bait: number | null;
	WeighUp: number | null;
	ScheduledSectionId: number | null;
	ScheduledSectionName: string | null;
	ScheduledLocationId: number | null;
	ScheduledLocationName: string | null;
	IsActive: boolean | null;
}

export const CATALOG_CARRIER_INITIAL_VALUES: CatalogCarrier = {
	
	PartId: null,
	PartName: '',
	RFIDTag: '',
	LocationName: '',
	RollsetId: null,
	UpperRollId: null,
	UpperRollName: '',
	LowerRollId: null,
	LowerRollName: '',
	UMChockId: null,
	UMChockName: '',
	LMChockId: null,
	LMChockName: '',
	UOChockId: null,
	UOChockName: '',
	LOChockId: null,
	LOChockName: '',
	EntryGuideId: null,
	EntryGuideName: '',
	ExitGuideId: null,
	ExitGuideName: '',
	CartridgeId: null,
	Bait: null,
	WeighUp: null,
	ScheduledSectionId: null,
	ScheduledSectionName: '',
	ScheduledLocationId: null,
	ScheduledLocationName: '',
	IsActive: true
};