import { getMomentTz, MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { CatalogChock } from "../../models/parts/CatalogChock";

const moment = getMomentTz();

export const getChocks = async (partId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (partId)
		parameters.push({ name: "PartId", value: partId });

	const resp = await apiService.callV2("[M2].[GetChocks]", parameters);
	if (resp.ok){

		let rows = get(resp, "data.tables[0].rows", []);
	
		rows = rows.map((chock: CatalogChock) => ({
		  ...chock,
		  RebuildTimestamp: chock.BearingChangeTimestamp ? moment.utc(chock["RebuildTimestamp"]).toDate() : null,
		  BearingChangeTimestamp: chock.BearingChangeTimestamp ? moment.utc(chock["BearingChangeTimestamp"]).toDate() : null
		}));
		
		return {
		  ok: true,
		  data: rows,
		};
	  } else {
		return {
			ok: false,
			message: resp.message!,
		};
	  }
};
export const upsertChock = async (chock: CatalogChock) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: chock.PartId },
		{ name: "PartName", value: chock.PartName },
	];
	
	if (chock.RebuildTimestamp)
		parameters.push({ name: "RebuildTimestamp", value: chock.RebuildTimestamp });
		
	if (chock.RebuildTimestamp)
		parameters.push({ name: "BearingChangeTimestamp", value: chock.BearingChangeTimestamp });

	return await apiService.callV2("[M2].[UpsertChock]", parameters);
};

export const deleteChock = async (partId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: partId }
	];

	return await apiService.callV2("[M2].[DeleteChock]", parameters);
};

export const getChocksV2 = async (partId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (partId)
		parameters.push({ name: "PartId", value: partId });

	const resp = await apiService.callV2("[M2].[GetChocks]", parameters);

	if (resp.ok){
		return get(resp, "data.tables[0].rows", []) as CatalogChock[];
	}
	else {
		throw new Error(resp.message || "Error fetching chocks");
	}
};