import { ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { CatalogDonutReducer } from "../reducer/CatalogDonutReducer";

const CatalogDonutContext = createContext({
  state: CatalogDonutReducer.getInitialState(),
  actions: CatalogDonutReducer.actions,
});

export const useCatalogDonutContext = () => useContext(CatalogDonutContext);

export const CatalogDonutProvider = ({ children }: { children: ReactNode }) => {
  const [state, actions] = useComplexState({
    initialState: CatalogDonutReducer.getInitialState(),
    reducers: CatalogDonutReducer.caseReducers,
  });
  return (
    <CatalogDonutContext.Provider value={{ state, actions }}>
      {children}
    </CatalogDonutContext.Provider>
  );
};
