import React, { useState, useMemo, useCallback } from "react";
import { Slider, Grid, makeStyles } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { dxtToLocalServerTime } from "@dexteel/mesf-core";

type Props = {
  startTime: Date;
  endTime: Date;
  onTimeChange: Function;
  initialValue: Date | null;
};
const formatTime = (date: Date) => {
  return date.toTimeString().split(" ")[0];
};
const getTimeFromValue = (value: number, scale: number, startTime: Date) => {
  const secondsFromStart = value * scale;
  return new Date(startTime.getTime() + secondsFromStart * 1000);
};
const formatMark = (date: string) => {
  return date.split(" ")[0];
};
const useStyles = makeStyles((theme) => ({
  sliderContainer: {
    position: "relative",
    height: 40,
    marginBottom: theme.spacing(4),
  },
  currentLabel: {
    position: "absolute",
    top: 20,
    transform: "translateX(-50%)",
    textAlign: "center",
    fontSize: "0.875rem",
  },
}));
const CustomTimeSlider = ({
  startTime,
  endTime,
  initialValue,
  onTimeChange,
}: Props) => {
  const [scale, setScale] = useState(1);
  const theme = useMemo(
    () =>
      createMuiTheme({
        overrides: {
          MuiSlider: {
            root: { height: 8 },
            thumb: {
              color: "#3f51b5",
              height: 20,
              width: 20,
              marginTop: -7,
            },
            track: {
              color: "#3f51b5",
              height: 8,
            },
            rail: {
              color: "green",
              height: 8,
            },
          },
        },
      }),
    []
  );

  const totalSeconds = useMemo(
    () =>
      Math.floor((endTime.getTime() - startTime.getTime()) / 1000) - scale * 2,
    [startTime, endTime]
  );
  const steps = useMemo(
    () => Math.floor(totalSeconds / scale),
    [totalSeconds, scale]
  );

  const [sliderValue, setSliderValue] = useState<number>(() => {
    return Math.floor(steps / 2);
  });

  const handleSliderChange = useCallback(
    (_event: React.ChangeEvent<{}>, newValue: number | number[]): void => {
      const singleValue = Array.isArray(newValue) ? newValue[0] : newValue;
      setSliderValue(singleValue);
      const newTime = getTimeFromValue(singleValue, scale, startTime);
      onTimeChange(newTime, formatTime(newTime));
    },
    [onTimeChange, scale, startTime]
  );

  const generateMarks = (): { value: number; label: string }[] => {
    const minStarterTime = startTime;
    minStarterTime.setSeconds(startTime.getSeconds() + scale);
    return [
      {
        value: 0,
        label: formatMark(dxtToLocalServerTime(startTime, "HH:mm:ss")),
      },
      {
        value: steps,
        label: formatMark(dxtToLocalServerTime(endTime, "HH:mm:ss")),
      },
    ];
  };

  const marks = useMemo(() => generateMarks(), [generateMarks]);

  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Slider
            defaultValue={sliderValue}
            onChange={handleSliderChange}
            step={1}
            marks={marks}
            min={0}
            max={steps}
          />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default CustomTimeSlider;
export { formatTime };
