import { CatalogBarrelProvider } from "./context/CatalogBarrelContext";
import { CatalogBarrelPage } from "./CatalogBarrelPage";

const CatalogBarrel = () => {
  return (
    <CatalogBarrelProvider>
      <CatalogBarrelPage />
    </CatalogBarrelProvider>
  );
};
export default CatalogBarrel;
