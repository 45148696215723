import { createContext, ReactNode, useContext } from "react";
import { CategoriesReducer } from "../reducers/CatalogCategoriesReducer";
import { useComplexState } from "use-complex-state";

const CategoriesContext = createContext({
  state: CategoriesReducer.getInitialState(),
  actions: CategoriesReducer.actions,
});

export const useCatalogCategoryContext = () => useContext(CategoriesContext);

export const CategoriesProvider = ({ children }: { children: ReactNode }) => {
  const [state, actions] = useComplexState({
    initialState: CategoriesReducer.getInitialState(),
    reducers: CategoriesReducer.caseReducers,
  });

  return (
    <CategoriesContext.Provider value={{ state, actions }}>
      {children}
    </CategoriesContext.Provider>
  );
};
