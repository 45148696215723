import { Backdrop, CircularProgress } from "@material-ui/core";

export const LazyLoading = () => {
  return <div>Loading Please Wait..</div>;
};

export const CenteredLazyLoading = () => {
  return (
    <div
      style={{
        display: 'flex',
        minHeight: "300px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress/>
    </div>
  );
};

type LazyBackDropLoadingProps = {
  open: boolean;
};
export const LazyBackDropLoading = (props: LazyBackDropLoadingProps) => {
  return (
    <Backdrop open={props.open} style={{ color: "#fff", zIndex: 100000 }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
