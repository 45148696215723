import { useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import {
  GetContextMenuItemsParams,
  GridApi,
  MenuItemDef,
  RowClassParams,
  RowStyle,
} from "ag-grid-community";
import { ErrorModal, useContextMenuMESF } from "@dexteel/mesf-core";

import { useGridDefinitions } from "../../../../../controls/ag-grid/components/GridDefinitions";
import { ConfirmationDialogRaw } from "../../../../../controls/modals/ConfirmationDialogRaw";

import { useDelaysManagerContext } from "../../context/DelaysManagerContext";
import { useCatalogCategoryContext } from "../../../categories/context/CategoriesContext";

import { DelayByShift } from "../../models/DelayByShift";
import { SplitDelays } from "../SplitDelays";
import { UpsertJustifyDelay } from "../UpsertJustifyDelay";
import { useTableData } from "../../hooks/hooks";
import { useDelaysOptionFunctions } from "../../hooks/useDelaysOptionFunctions";
import { useSearchDelays } from "../../../codes/hooks/useSearchDelays";
import { useGridStyles } from "../../styles/gridStyles";
import { deleteDelay } from "../../repositories/DelaysManagerRepository";
import { getCatalogCategories } from "../../../categories/repositories/CatalogCategoriesRepository";
import { backgroundColor } from "../../../../../utils/theme";
type Props = {
  loadingGrid: boolean;
  rows: DelayByShift[];
  refreshData: Function;
};
export const DelaysManagerTable = ({
  loadingGrid,
  rows,
  refreshData,
}: Props) => {
  const classes = useGridStyles();

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    state: { selectedDelay, errorMessage, delaySelected },
    actions: { setSelectedDelay, setNotificationMessage, setErrorMessage },
  } = useDelaysManagerContext();
  const {
    actions: { setCategories },
  } = useCatalogCategoryContext();

  const {
    createData,
    deleteData,
    justifyData,
    getMenuOptions,
    showDeleteModal,
    showEditModal,
    showJustifyModal,
    messageDeleteModal,
    setShowDeleteModal,
    setShowEditModal,
    setMessageDeleteModal,
    setShowJustifyModal,
    showSplitModal,
    setShowSplitModal,
  } = useDelaysOptionFunctions();
  const { showContextMenu, registerConfig } = useContextMenuMESF();
  const { columnDefs } = useTableData({
    justifyData: justifyData,
    deleteData: deleteData,
    showContextMenu: showContextMenu,
  });
  const { columnSimpleDefaults } = useGridDefinitions({});

  const handleDeleteDelay = async (value?: string) => {
    setShowDeleteModal(false);
    setMessageDeleteModal("");
    if (value !== "OK") return;
    if (selectedDelay === null) return;
    setIsLoading(true);
    const resp = await deleteDelay(selectedDelay.DelayId);
    if (resp.ok) {
      setNotificationMessage("Delay deleted successfully");
      setSelectedDelay(null);
      await refreshData();
    } else {
      setErrorMessage(resp.message);
    }
    setIsLoading(false);
  };
  const getContextMenuItems = (
    params: GetContextMenuItemsParams
  ): (string | MenuItemDef)[] => {
    const data = params.node?.data;
    showContextMenu(event as any, data, "delayManagerContext");
    if (data) {
      params.api.deselectAll();
      params.node?.setSelected(true);
      setSelectedDelay(data);
    }
    return [];
  };
  const getRowStyle = (
    params: RowClassParams<any, any>
  ): RowStyle | undefined => {
    if (params.data.DelayId === delaySelected) {
      return { backgroundColor: backgroundColor };
    }
    if (!params.data.IsActive) {
      return { background: "#E8EBEB" };
    }
    return undefined;
  };
  useEffect(() => {
    if (isLoading) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  }, [isLoading, rows, gridApi]);
  useEffect(() => {
    registerConfig({
      id: "delayManagerContext",
      getOptions: getMenuOptions,
    });
  }, []);
  return (
    <div onContextMenu={(e) => e.preventDefault()}>
      <div>
        <Grid container className={classes.root}>
          <Grid
            item
            md={12}
            xs={12}
            style={{
              border: "4px solid #ccc",
              borderRadius: 10,
              padding: 10,
              height: "calc(100vh - 300px)",
              minHeight: "calc(100vh - 300px)",
              margin: "20px 0 10px",
            }}
            className={"ag-theme-balham"}
          >
            <ConfirmationDialogRaw
              title="Confirmation"
              message={messageDeleteModal}
              open={showDeleteModal}
              onClose={handleDeleteDelay}
            />
            <UpsertJustifyDelay
              show={showEditModal || showJustifyModal}
              onHide={() => {
                setShowEditModal(false), setShowJustifyModal(false);
              }}
              refreshData={refreshData}
              delay={selectedDelay}
              ifJustified={showJustifyModal}
            />
            <SplitDelays
              show={showSplitModal}
              onHide={() => setShowSplitModal(false)}
              refreshData={refreshData}
              delay={selectedDelay}
            />
            <AgGridReact
              onGridReady={(params) => setGridApi(params.api)}
              rowSelection="single"
              pagination={false}
              animateRows={true}
              rowHeight={38}
              headerHeight={35}
              singleClickEdit={true}
              onCellDoubleClicked={(e) => justifyData(e.data)}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              rowData={rows}
              columnDefs={columnDefs}
              //columnTypes={columnTypes}
              defaultColDef={columnSimpleDefaults}
              getContextMenuItems={getContextMenuItems}
              getRowStyle={getRowStyle}
            />
            <Grid container justifyContent="flex-end">
              <Grid item md={2} xs={12} className={classes.btnModal}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ margin: "2rem 0px 0px" }}
                  onClick={() => {
                    createData();
                  }}
                >
                  NEW DELAY
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <ErrorModal error={errorMessage} onHide={() => setErrorMessage("")} />
    </div>
  );
};
