import moment from "moment";
import { ColDef, ICellRendererParams } from "ag-grid-enterprise";
import { dxtToLocalServerTime } from "@dexteel/mesf-core";
import { CategoryTag } from "../components/CategoryTag";
import { GridButtons } from "../components/GridButtons";
type Props = {
  justifyData: Function;
  deleteData: Function;
  showContextMenu: Function;
};
const applyCommonColumnSettings = (column: ColDef): ColDef => {
  return {
    ...column,
    sortable: true,
    wrapText: true,
    wrapHeaderText: true,
  };
};
export const useTableData = ({
  justifyData,
  deleteData,
  showContextMenu,
}: Props) => {
  const columnDefs: ColDef[] = [
    applyCommonColumnSettings({
      field: "AssetName",
      headerName: "Asset",
      maxWidth: 100,
      editable: false,
      flex: 1,
    }),
    applyCommonColumnSettings({
      field: "Start",
      headerName: "Start",
      valueGetter: (params) =>
        dxtToLocalServerTime(params.data?.Start, "HH:mm:ss") || "",
      maxWidth: 120,
      flex: 1,
    }),
    applyCommonColumnSettings({
      field: "End",
      headerName: "End",
      valueGetter: (params) =>
        dxtToLocalServerTime(params.data?.End, "HH:mm:ss") || "",
      maxWidth: 120,
      flex: 1,
    }),
    applyCommonColumnSettings({
      field: "Duration",
      headerName: "Duration",
      valueFormatter: (params) => {
        if (params.value) {
          const duration = moment.duration(params.value, "seconds");
          const minutes = Math.floor(duration.asMinutes());
          const seconds = duration.seconds();
          return `${minutes}m ${seconds}s`;
        }
        return "";
      },
      maxWidth: 120,
      flex: 1,
    }),
    applyCommonColumnSettings({
      field: "DelayCategory",
      headerName: "Category",
      valueGetter: (params) => {
        const delayCategoryName = params.data?.DelayCategoryName;
        const delayCategoryColor = params.data?.DelayCategoryColor;
        return { name: delayCategoryName, color: delayCategoryColor };
      },
      cellRendererFramework: CategoryTag,
      maxWidth: 90,
      editable: false,
      flex: 1,
    }),
    applyCommonColumnSettings({
      field: "DelayCodeName",
      headerName: "Code",
      maxWidth: 150,
      editable: false,
      flex: 1,
    }),
    applyCommonColumnSettings({
      field: "Comments",
      headerName: "Comments",
      flex: 1,
    }),
    applyCommonColumnSettings({
      field: "JustifiedByUser",
      headerName: "User",
      maxWidth: 150,
      editable: false,
      flex: 1,
    }),
    applyCommonColumnSettings({
      field: "JustifiedDatetime",
      headerName: "Justification Date",
      valueGetter: (params) =>
        dxtToLocalServerTime(params.data?.JustifiedDatetime, "MM-dd HH:mm"),
      maxWidth: 150,
      flex: 1,
    }),
    {
      cellRenderer: GridButtons,
      cellRendererParams: (params: ICellRendererParams) => ({
        params: params,
        justifyData: justifyData,
        deleteData: deleteData,
        showContextMenu: () =>
          showContextMenu(event, params.data, "delayManagerContext"),
      }),
      wrapText: false,
      wrapHeaderText: false,
      headerName: "",
      sortable: false,
      flex: 1,
      maxWidth: 150,
      minWidth: 150,
      filter: false,
    },
  ];
  return { columnDefs };
};
