export interface CatalogCategory {
  DelayCategoryId: null | number;
  DelayCategoryName: string;
  Color: string;
  DelayAreaAssetId: null | number;
  IsActive: boolean;
}

export const CATALOG_CATEGORY_INITIAL_VALUES: CatalogCategory = {
	DelayCategoryId: null,
	DelayAreaAssetId: null,
	DelayCategoryName: "",
	Color: "",
	IsActive: true
};
