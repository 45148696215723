import { CatalogSectionProvider } from "./context/CatalogSectionContext";
import { CatalogSectionPage } from "./CatalogSectionPage";

const CatalogSection = () => {
  return (
    <CatalogSectionProvider>
      <CatalogSectionPage />
    </CatalogSectionProvider>
  );
};
export default CatalogSection;
