import { createSlice } from "@reduxjs/toolkit";
import { CatalogPassShape } from "../../../models/CatalogPassShape";

const CatalogPassShapeInitialState: {
	passShapes: CatalogPassShape[] | null;
} = {
	passShapes: null,
};

export const CatalogPassShapeReducer = createSlice({
	name: "__",
	initialState: CatalogPassShapeInitialState,
	reducers: {
		setPassShapes(state, { payload }) {
			state.passShapes = payload;
		},
	},
});
