import { MenuOptionType } from "@dexteel/mesf-core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";

import { CatalogBarrel } from "../../../models/CatalogBarrel";

type Props = {
  setBarrelId: (id: number) => void;
  setShowModal: (modal: "create" | "update" | "delete" | "") => void;
};
export const useBarrelOptionsFunctions = ({
  setBarrelId,
  setShowModal,
}: Props) => {
  const getMenuOptions = (data: CatalogBarrel) => {
    let options: MenuOptionType[] = [];
    options = options.concat([
      {
        name: "New Barrel",
        key: "new_barrel",
        onClick: () => {
          setShowModal("create");
        },
        icon: <PlaylistAddIcon />,
      },
    ]);
    if (data) {
      options = options.concat([
        {
          name: "Edit Barrel",
          key: "edit_barrel",
          onClick: () => {
            setBarrelId(data.BarrelId!);
            setShowModal("update");
          },
          icon: <EditIcon />,
        },
        {
          name: "Delete Barrel",
          key: "delete_barrel",
          onClick: () => {
            setBarrelId(data.BarrelId!);
            setShowModal("delete");
          },
          icon: <DeleteIcon />,
        },
      ]);
    }
    return options;
  };
  return {
    getMenuOptions,
  };
};
