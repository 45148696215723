import {createSlice} from "@reduxjs/toolkit";
import { TreeParameters } from "../models/TreeParameter";
const ConfigurationAssetInitialState: {
	assetCodes: any;
	allAssetNodes: TreeParameters[];
} = {
	assetCodes: {},
	allAssetNodes: []
};
export const ConfigurationAssetReducer = createSlice({
	name: "__",
	initialState: ConfigurationAssetInitialState,
	reducers: {
		setAssetCodes(state, { payload }) {
			state.assetCodes = payload;
		},
		setAllAssetNodes(state, { payload }) {
			state.assetCodes = payload;
		},
	},
});
