import { ErrorModal, MesfModal, TreePickerControlV2 } from "@dexteel/mesf-core";
import {
  Checkbox,
  DialogProps,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { get } from "lodash-es";
import { CenteredLazyLoading } from "../../../../../controls/LazyLoading";
import { FooterModalButtons } from "../../../../../controls/buttons/FooterModalButtons";
import { NotificationSnackBar } from "../../../../../controls/snackbars/notification-snackbar";
import { useCatalogCategoryContext } from "../../../categories/context/CategoriesContext";
import { useConfigurationDelayCodeContext } from "../../context/ConfigurationDelayCodeContext";
import { useDelayCodes } from "../../hooks/delays/useDelayCodes";
import {
  CATALOG_DELAY_INITIAL_VALUES,
  CatalogDelayCode,
} from "../../models/CatalogDelayCode";
import {
  deleteDelay,
  getDelay,
  upsertDelay,
} from "../../repositories/ConfigurationDelayRepository";
import { useStyles } from "./styles";
type Props = {
  DelayId: number | null | undefined;
  modal: "create" | "update" | "delete" | "view" | "";
  onHide: (shouldUpdate: boolean) => void;
};

export const UpsertDeleteViewDelay = ({ DelayId, modal, onHide }: Props) => {
  const classes = useStyles();

  const maxWidth: DialogProps["maxWidth"] = "sm";
  const [error, setError] = useState<string>("");
  const [title, setTitle] = useState<string>("CREATE");
  const [notificationMessage, setNotificationMessage] = useState<string>("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const succesfullyMessage = `The delay code was ${modal}d succesfully`;

  const {
    state: { categories },
  } = useCatalogCategoryContext();
  const {
    state: {
      delayAreaAsset,
      delayNodeSelectedInTree,
      delayCodesDatasource,
      allDelayCodes,
    },
    actions: { setDelayParentDelayIdToCreateDelay },
  } = useConfigurationDelayCodeContext();
  const { loadFilterDelays } = useDelayCodes();

  const { control, reset, setValue, handleSubmit } = useForm<CatalogDelayCode>({
    defaultValues: delayNodeSelectedInTree
      ? delayNodeSelectedInTree
      : CATALOG_DELAY_INITIAL_VALUES,
  });

  const handleCancel = () => {
    onHide(false);
  };

  const onSubmit: SubmitHandler<CatalogDelayCode> = async (data: CatalogDelayCode) => {
    setIsSubmitLoading(true);
    if (modal === "delete") {
      const delayResp = await deleteDelay(
        delayNodeSelectedInTree.DelayCodeId as number
      );
      if (delayResp.ok) {
        setNotificationMessage(succesfullyMessage);
        onHide(true);
      } else {
        setError(delayResp.message);
      }
    } else {
      const delayResp = await upsertDelay(data);
      if (delayResp.ok) {
        setNotificationMessage(succesfullyMessage);
        onHide(true);
      } else {
        setError(delayResp.message);
      }
    }
    setIsSubmitLoading(false);
  };

  useEffect(() => {
    if (modal !== "") {
      reset();
      loadFilterDelays();
      setTitle(
        modal === "create"
          ? "CREATE"
          : modal === "update"
          ? "EDIT"
          : modal === "delete"
          ? "DELETE"
          : "VIEW"
      );
      if (modal !== "create") {
        (async () => {
          setIsLoading(true);
          const response = await getDelay(DelayId!);
          if (response.ok) {
            const delayCode = get(
              response,
              "data.tables[0].rows[0]",
              null
            ) as CatalogDelayCode;
            if (delayCode) {
              setValue("ParentDelayCodeId", delayCode.ParentDelayCodeId);
              setValue("DelayAreaAssetId", delayCode.DelayAreaAssetId);
              setValue("DelayCodeId", delayCode.DelayCodeId);
              setValue("DelayCodeName", delayCode.DelayCodeName);
              setValue("DelayCategoryId", delayCode.DelayCategoryId);
              setValue("IsBranch", delayCode?.IsBranch);
            }
          } else {
            setError(response.message);
            return;
          }
          setIsLoading(false);
        })();
      } else {
        const parentRoot: CatalogDelayCode = allDelayCodes.find(
          (node: CatalogDelayCode) => node.ParentDelayCodeId === null
        );
        if (parentRoot) {
          setDelayParentDelayIdToCreateDelay(parentRoot.DelayCodeId);
          setValue("ParentDelayCodeId", parentRoot.DelayCategoryId);
        }
        setValue("DelayCodeName", "");
        setValue("DelayCategoryId", null);
        setValue("DelayAreaAssetId", delayAreaAsset?.AssetId!)
      }
    }
  }, [modal]);

  return (
    <div className={classes.root}>
      <MesfModal
        title={`${title} LOCATION`}
        open={modal !== ""}
        maxWidth={maxWidth}
        handleClose={() => handleCancel()}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <MesfModal.Content dividers style={{ padding: "10px 30px" }}>
            {isLoading ? (
              <CenteredLazyLoading />
            ) : (
              <>
                <Grid container spacing={1}>
                  <Grid item md={3} sm={12} xs={10}>
                    <Controller
                      name="DelayAreaAssetId"
                      control={control}
                      render={() => (
                        <>
                          <TextField
                            label="Asset"
                            variant="outlined"
                            value={delayAreaAsset?.AssetName}
                            disabled
                            margin="dense"
                            size="small"
                            fullWidth
                          />
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item md={9} sm={12} xs={10}>
                    <Controller
                      name="DelayCodeName"
                      control={control}
                      rules={{ required: "Name is required" }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormControl error={!!error} fullWidth>
                          <TextField
                            label="Delay Code Name"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            autoComplete="off"
                            onChange={onChange}
                            value={value}
                            disabled={modal === "delete" || modal === "view"}
                          />
                          {error && (
                            <FormHelperText>{error.message}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  {(delayNodeSelectedInTree.ParentDelayCodeId ||
                    modal === "create") && (
                    <Grid item md={12} xs={12}>
                      <Controller
                        name="ParentDelayCodeId"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <FormControl error={!!error} fullWidth>
                              <TreePickerControlV2
                                inputTitle="Parent"
                                title="Parent"
                                dataSource={delayCodesDatasource}
                                value={
                                  delayNodeSelectedInTree?.ParentDelayCodeId ||
                                  1
                                }
                                onSelect={(value) => {
                                  field.onChange(value);
                                }}
                                selectBranch={true}
                                selectActive={false}
                                selectInternal={false}
                                disabled={
                                  modal === "delete" || modal === "view"
                                }
                              />
                              {error && (
                                <FormHelperText>{error.message}</FormHelperText>
                              )}
                            </FormControl>
                          </>
                        )}
                      />
                    </Grid>
                  )}
                  <Grid item md={12} xs={12}>
                    <Controller
                      name="DelayCategoryId"
                      control={control}
                      rules={{ required: "Category is required" }}
                      render={({ field, fieldState: { error } }) => (
                        <Autocomplete
                          id="clear-on-escape"
                          clearOnEscape
                          options={categories ?? []}
                          getOptionLabel={(option) => option.DelayCategoryName}
                          onChange={(event, newValue) => {
                            field.onChange(
                              newValue ? newValue.DelayCategoryId : null
                            );
                          }}
                          renderInput={(params) => (
                            <FormControl error={!!error} fullWidth>
                              <TextField
                                {...params}
                                label="Default Category"
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                              {error && (
                                <FormHelperText>{error.message}</FormHelperText>
                              )}
                            </FormControl>
                          )}
                          value={
                            categories?.find(
                              (category) =>
                                category.DelayCategoryId === field.value
                            ) || null
                          }
                          disabled={modal === "delete" || modal === "view"}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Controller
                      name="IsBranch"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <FormControlLabel
                          className={classes.checkbox}
                          control={
                            <Checkbox
                              checked={field.value as boolean}
                              onChange={(e) => field.onChange(e.target.checked)}
                              name="IsAsset"
                              color="primary"
                              disabled
                            />
                          }
                          label="Has Children"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </MesfModal.Content>
          <MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
            <FooterModalButtons
              isSubmitLoading={isSubmitLoading}
              onHide={handleCancel}
            />
          </MesfModal.Actions>
        </form>
      </MesfModal>
      <ErrorModal error={error} onHide={() => setError("")} />
      <NotificationSnackBar
        message={notificationMessage}
        onHide={() => setNotificationMessage("")}
      />
    </div>
  );
};
