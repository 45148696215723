import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { get } from "lodash-es";
import {
	Button,
	CircularProgress,
	Grid,
	makeStyles,
	TextField,
    Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { dxtToLocalServerTime, ErrorModal, MesfModal, TreePickerControl, useUTLSettingsContext } from "@dexteel/mesf-core";

import { useCatalogCategoryContext } from "../../categories/context/CategoriesContext";
import { useConfigurationDelayCodeContext } from "../../codes/context/ConfigurationDelayCodeContext";

import { DelayByShift } from "../../manager/models/DelayByShift"; 
import { useSearchDelays } from "../../codes/hooks/delays/useSearchDelays";
import { useDelayCodes } from "../../codes/hooks/delays/useDelayCodes";
import CustomTimeSlider from "../components/CustomTimeSlider";
import { splitDelay } from "../../manager/repositories/DelaysManagerRepository";
import { CatalogCategory } from "../../categories/models/CatalogCategory";
import { getCatalogCategories } from "../../categories/repositories/CatalogCategoriesRepository";
import { useDelaysManagerContext } from "../context/DelaysManagerContext";
import { NotificationSnackBar } from "../../../../controls/snackbars/notification-snackbar";
const useStyles = makeStyles((theme) => ({
    sliderContainer: {
        position: 'relative',
        marginBottom: theme.spacing(4),
        paddingTop: theme.spacing(3),
    },
    durationLabel: {
        position: 'absolute',
        top: 0,
        transform: 'translateX(-50%)',
        textAlign: 'center',
        fontSize: '0.75rem',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        padding: '2px 4px',
        borderRadius: '4px',
        fontWeight: 'bold'
    },
    infoLabel: {
        fontSize: 12,
        color: "#757575",
    },
}));
const formatDuration = (duration: number): string => {
    const hours = Math.floor(duration / (1000 * 60 * 60));
    const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((duration % (1000 * 60)) / 1000);
    return `${hours} hs ${minutes} min ${seconds} sec`;
};
type Props = {
	show: boolean;
	onHide: () => void;
    delay: DelayByShift | null;
    refreshData: Function;
};
interface CombinedDelayForm {
    delay1: DelayByShift;
    delay2: DelayByShift;
}
export const SplitDelays = ({ show, onHide, delay, refreshData }: Props) => {
    const {state} = useUTLSettingsContext();
	const minDelayDuration = Number(get(state, `settings.MinValidDelay`, 10));
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState("");
    const [sliderValue, setSliderValue] = useState<Date | null>(null);
	const [notificationMessage, setNotificationMessage] = useState("");
	const [notificationErrorMessage, setNotificationErrorMessage] = useState("");

    const startTime = new Date();
    const endTime = new Date();
    startTime.setMinutes(endTime.getMinutes() - 30)

    const { actions: {setErrorMessage} } = useDelaysManagerContext();
    const { state: {allDelayCodes, delayCodesDatasource} } = useConfigurationDelayCodeContext();
    const {state: {categories}, actions: {setCategories}} = useCatalogCategoryContext();
    
    const { loadFilterDelays } = useDelayCodes();
    const { searchDelays } = useSearchDelays({
        setSearchError: setError,
        setAllDelayLoading: setIsLoading,
    });
    const searchCategories = async (delayAreaAssetId: number | undefined) => {
        setIsLoading(true);
        const res = await getCatalogCategories(delayAreaAssetId);
        if (res.ok) {
          setCategories(res.data.tables[0].rows);
        } else {
          setErrorMessage(res.message);
        }
        setIsLoading(false);
    };

    const onSubmit: SubmitHandler<CombinedDelayForm> = async (data: CombinedDelayForm) => {
        if ((data.delay1.End?.getTime()! - data.delay1.Start?.getTime()! <= minDelayDuration * 1000)
            || (data.delay2.End?.getTime()! - data.delay2.Start?.getTime()! <= minDelayDuration * 1000)
        ){
            setNotificationErrorMessage(`The minimum duration allowed is ${minDelayDuration} seconds`);
        }
        else {
            const resp = await splitDelay(data.delay1, data.delay2);
            if (resp.ok){
                setNotificationMessage("The operation was completed successfully");
                await refreshData();
            }
            else {
                setError(resp.message)
            }
            onHide();
        }
      };

    const { control, handleSubmit, setValue, getValues, reset, formState: { errors } } = useForm<CombinedDelayForm>({});
	useEffect(() => {
        if (show && delay) {
            reset();
            (async () => {
                setIsLoading(true);
                searchDelays(delay.AssetId ? delay.AssetId : 0);
                searchCategories(delay.AssetId ? delay.AssetId : 0);
                
                const startDate = delay?.Start ?? new Date();
                const endDate = delay?.End ?? new Date();
                const middleDate = new Date((startDate.getTime() + endDate.getTime()) / 2);
                setValue("delay1.DelayId", delay.DelayId);
                setValue("delay1.Start", startDate);
                setValue("delay1.End", middleDate);
                setValue("delay2.Start", middleDate);
                setValue("delay2.End", endDate);
                setValue("delay1.DelayCategoryId", delay.DelayCategoryId);
                setValue("delay1.Comments", delay.Comments);
                setValue("delay1.DelayCodeId", delay.DelayCodeId);
                setSliderValue(middleDate);
                setIsLoading(false);
            })();
        }
    }, [show, delay]);

    useEffect(() => {
        if (allDelayCodes.length > 0){
            loadFilterDelays();
            const delayCode = allDelayCodes.find(
                (node: any) => node.DelayCodeId === delay?.DelayCodeId,
            );
            if (delayCode) {
                setValue("delay1.DelayCodeName", delayCode.DelayCodeName)
            }
        }
    }, [allDelayCodes])

    const [selectedTime, setSelectedTime] = useState(delay?.Start);

	return (
		<>
            <MesfModal
                title="SPLIT DELAY"
                open={show}
                handleClose={() => onHide()}
                id="create-grade-modal"
                maxWidth="lg"
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <MesfModal.Content dividers>
                        <Grid container justifyContent="center">
                            <Grid md={10} style={{marginBottom: 10}} className={classes.sliderContainer}>
                            <Typography 
                                className={classes.durationLabel} 
                                style={{ left: '25%', color: '#3f51b5' }}
                            >
                                {formatDuration(getValues("delay1.End")?.getTime()! - getValues("delay1.Start")?.getTime()!)}
                            </Typography>
                            <Typography 
                                className={classes.durationLabel}  
                                style={{ left: '75%', color: 'green' }}
                            >
                                {formatDuration(getValues("delay2.End")?.getTime()! - getValues("delay2.Start")?.getTime()!)}
                            </Typography>
                                <CustomTimeSlider
                                    startTime={delay?.Start ?? new Date()}
                                    endTime={delay?.End ?? new Date()}
                                    initialValue={sliderValue}
                                    onTimeChange={(newTime: Date) => {
                                            setSelectedTime(newTime);
                                            setValue("delay1.End", newTime);
                                            setValue("delay2.Start", newTime);
                                    }}
                                />
                            </Grid>
                            <Grid container md={5}>
                                <Typography>Delay 1</Typography>
                                <Grid container md={12} spacing={1} style={{marginTop: 10}}>
                                    <Grid item md={6}>
                                        <Controller
                                            name="delay1.Start"
                                            control={control}
                                            rules={{ required: 'Delay code is required' }}
                                            render={({field}) => (
                                                <TextField
                                                    label="Start"
                                                    variant="outlined"
                                                    value={dxtToLocalServerTime(field?.value, "HH:mm:ss")}
                                                    error={!!errors.delay1?.Start}
                                                    onChange={() => {}}
                                                    disabled
                                                    fullWidth
                                                    margin="dense"
                                                    autoComplete="off"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item md={6}>
                                        <Controller
                                            name="delay1.DelayCodeName"
                                            control={control}
                                            render={({field}) => (
                                                <>
                                                    <TreePickerControl
                                                        
                                                        inputTitle="Delay"
                                                        title="Delay" 
                                                        dataSource={delayCodesDatasource}
                                                        value={delay?.DelayCodeId || 0}
                                                        description={field.value ? field?.value : ""}
                                                        onSelect={(value, description) => {
                                                            setValue("delay1.DelayCodeId", value);
                                                            setValue("delay1.DelayCodeName", description);
                                                        }}
                                                        selectBranch={true}
                                                        selectActive={false}
                                                        selectInternal={false}
                                                    />
                                                    {errors.delay1?.DelayCodeId && (
                                                    <span>
                                                        {errors.delay1?.DelayCodeId.message}
                                                    </span>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item md={6}>
                                        <Controller
                                            name="delay1.End"
                                            control={control}
                                            render={({field}) =>  
                                                <TextField
                                                    label="End"
                                                    variant="outlined"
                                                    value={dxtToLocalServerTime(field?.value, "HH:mm:ss")}
                                                    disabled
                                                    fullWidth
                                                    margin="dense"
                                                    autoComplete="off"
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Grid item md={6} style={{marginTop: 8}}>
                                        <Controller
                                            name="delay1.DelayCategoryId"
                                            control={control}
                                            render={({ field }) => (
                                                <Autocomplete
                                                    id="clear-on-escape"
                                                    clearOnEscape
                                                    options={categories?.filter((category: CatalogCategory) => category.DelayAreaAssetId === delay?.AssetId) ?? []}
                                                    getOptionLabel={(option) => option?.DelayCategoryName}
                                                    onChange={(event, newValue) => {
                                                        field.onChange(
                                                            newValue ? newValue.DelayCategoryId : null,
                                                        );
                                                    }}
                                                    renderInput={(params) => (
                                                        <>
                                                            <TextField
                                                                {...params}
                                                                label="Category"
                                                                variant="outlined"
                                                                error={!!errors.delay1?.DelayCategoryId}
                                                                size="small"
                                                                fullWidth
                                                            />
                                                            {errors.delay1?.DelayCategoryId && (
                                                                <span>
                                                                    {errors.delay1?.DelayCategoryId.message}
                                                                </span>
                                                            )}
                                                        </>
                                                    )}
                                                    value={
                                                        categories?.find(
                                                            (category) => category.DelayCategoryId === field.value,
                                                        ) || null
                                                    }
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item md={12}>
                                        <Controller
                                            name="delay1.Comments"
                                            control={control}
                                            render={({field}) => (
                                                <>
                                                    <TextField
                                                        label="Comments"
                                                        variant="outlined"
                                                        error={!!errors.delay1?.Comments}
                                                        value={field?.value}
                                                        fullWidth
                                                        maxRows={5}
                                                        multiline
                                                        margin="dense"
                                                        autoComplete="off"
                                                        onChange={(event) => {
                                                            if (event.target.value.length <= 200){
                                                                setValue("delay1.Comments", event.target.value)
                                                            }
                                                        }}
                                                    />
                                                    {(getValues("delay1.Comments")?.length as number) !== 200 && (
                                                        <span
                                                            className={classes.infoLabel}
                                                            style={{ marginLeft: 5 }}
                                                        >
                                                            {`${getValues("delay1.Comments")?.length ?? 0}/200 max.`}
                                                        </span>
                                                    )}
                                                    {(getValues("delay1.Comments")?.length as number) == 200 && (
                                                        <span
                                                            className={classes.infoLabel}
                                                            style={{ marginLeft: 5 }}
                                                        >
                                                            Max. 200
                                                        </span>
                                                    )}
                                                </>
                                                )
                                            }
                                        /> 
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid md={1}/>
                            <Grid container md={5}>
                                <Typography>Delay 2</Typography>
                                <Grid container md={12} spacing={1} style={{marginTop: 10}}>
                                    <Grid item md={6}>
                                        <Controller
                                            name="delay2.Start"
                                            control={control}
                                            render={({field}) => (     
                                                <TextField
                                                    label="Start"
                                                    variant="outlined"
                                                    value={dxtToLocalServerTime(field?.value, "HH:mm:ss")}
                                                    error={!!errors.delay2?.Start}
                                                    disabled
                                                    fullWidth
                                                    margin="dense"
                                                    autoComplete="off"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item md={6}>
                                        <Controller
                                            name="delay2.DelayCodeName"
                                            control={control}
                                            render={({field}) => (
                                                <>
                                                    <TreePickerControl
                                                        inputTitle="Delay"
                                                        title="Delay"
                                                        dataSource={delayCodesDatasource}
                                                        value={delay?.DelayCodeId || 0}
                                                        description={field.value ? field?.value : ""}
                                                        onSelect={(value, description) => {
                                                            setValue("delay2.DelayCodeId", value);
                                                            setValue("delay2.DelayCodeName", description);
                                                        }}
                                                        selectBranch={true}
                                                        selectActive={false}
                                                        selectInternal={false}
                                                    />
                                                    {errors.delay2?.DelayCodeId && (
                                                    <span>
                                                        {errors.delay2?.DelayCodeId.message}
                                                    </span>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item md={6}>
                                        <Controller
                                            name="delay2.End"
                                            control={control}
                                            render={({field}) =>  
                                                <TextField
                                                    label="End"
                                                    variant="outlined"
                                                    value={dxtToLocalServerTime(field?.value, "HH:mm:ss")}
                                                    disabled
                                                    fullWidth
                                                    margin="dense"
                                                    autoComplete="off"
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Grid item md={6} style={{marginTop: 8}}>
                                        <Controller
                                            name="delay2.DelayCategoryId"
                                            control={control}
                                            render={({ field }) => (
                                                <Autocomplete
                                                    id="clear-on-escape"
                                                    clearOnEscape
                                                    options={categories?.filter((category: CatalogCategory) => category.DelayAreaAssetId === delay?.AssetId) ?? []}
                                                    getOptionLabel={(option) => option?.DelayCategoryName}
                                                    onChange={(event, newValue) => {
                                                        field.onChange(
                                                            newValue ? newValue.DelayCategoryId : null,
                                                        );
                                                    }}
                                                    renderInput={(params) => (
                                                        <>
                                                            <TextField
                                                                {...params}
                                                                label="Category"
                                                                variant="outlined"
                                                                error={!!errors.delay2?.DelayCategoryId}
                                                                size="small"
                                                                fullWidth
                                                            />
                                                            {errors.delay2?.DelayCategoryId && (
                                                                <span>
                                                                    {errors.delay2?.DelayCategoryId.message}
                                                                </span>
                                                            )}
                                                        </>
                                                    )}
                                                    value={
                                                        categories?.find(
                                                            (category) => category.DelayCategoryId === field.value,
                                                        ) || null
                                                    }
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item md={12}>
                                        <Controller
                                            name="delay2.Comments"
                                            control={control}
                                            render={({field}) => (
                                                <>
                                                    <TextField
                                                        label="Comments"
                                                        variant="outlined"
                                                        error={!!errors.delay2?.Comments}
                                                        value={field?.value}
                                                        fullWidth
                                                        maxRows={5}
                                                        margin="dense"
                                                        autoComplete="off"
                                                        onChange={(event) => {
                                                            if (event.target.value.length <= 200){
                                                                setValue("delay2.Comments", event.target.value)
                                                            }
                                                        }}
                                                    />
                                                    {(getValues("delay2.Comments")?.length as number) !== 200 && (
                                                        <span
                                                            className={classes.infoLabel}
                                                            style={{ marginLeft: 5 }}
                                                        >
                                                            {`${getValues("delay2.Comments")?.length ?? 0}/200 max.`}
                                                        </span>
                                                    )}
                                                    {(getValues("delay2.Comments")?.length as number) == 200 && (
                                                        <span
                                                            className={classes.infoLabel}
                                                            style={{ marginLeft: 5 }}
                                                        >
                                                            Max. 200
                                                        </span>
                                                    )}
                                                </>
                                                )
                                            }
                                        /> 
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </MesfModal.Content>
                    <MesfModal.Actions style={{ padding: "20px 90px 30px" }}>
                        <Grid container spacing={1} justifyContent="flex-end">
                            <Grid item md={2} xs={12}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="default"
                                    onClick={() => onHide()}
                                    size="medium"
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item md={2} xs={12}>
                                <Button
                                    fullWidth
                                    startIcon={
                                        isLoading && <CircularProgress size="1rem" />
                                    }
                                    disabled={isLoading}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    size="medium"
                                >
                                    Split
                                </Button>
                            </Grid>
                        </Grid>
                    </MesfModal.Actions>
                </form>
            </MesfModal>
            <NotificationSnackBar
                message={notificationErrorMessage}
                onHide={() => setNotificationErrorMessage("")}
                severity="error"
            />
            <NotificationSnackBar
                message={notificationMessage}
                onHide={() => setNotificationMessage("")}
            />
			<ErrorModal error={error} onHide={() => setError("")} title="Error Creating Grade"/>
		</>
	);
};