import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { StartFilter, EndFilter } from "./StartEndFilter";
import { DelayAreaFilter } from "./DelayAreaFilter";
import { FormControlLabel, Checkbox } from '@mui/material';
import { Moment } from 'moment';

const FilterContainer = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
  width: '100%',
  '& > *': {
    marginRight: '33px',
  },
});

const FilterWrapper = styled('div')({
  width: 'calc(25% - 8px)',
});

type Props = {
  onFilterChange: (startDate: Moment, endDate: Moment) => void;
  delayAreas: Array<{ AssetId: number, AssetName: string }>;
  selectedDelayArea: number | null;
  onDelayAreaChange: (assetId: number | null) => void;
  initialStartDate: Moment;
  initialEndDate: Moment;
  includeMicrodelays: boolean;
  onIncludeMicrodelaysChange: (include: boolean) => void;
};

export const Filters: React.FC<Props> = ({
  onFilterChange,
  delayAreas,
  selectedDelayArea,
  onDelayAreaChange,
  initialStartDate,
  initialEndDate,
  includeMicrodelays,
  onIncludeMicrodelaysChange
}) => {
  const [startDate, setStartDate] = React.useState<Moment>(initialStartDate);
  const [endDate, setEndDate] = React.useState<Moment>(initialEndDate);

  useEffect(() => {
    setStartDate(initialStartDate);
    setEndDate(initialEndDate);
  }, [initialStartDate, initialEndDate]);

  const handleStartDateChange = (date: Moment | null) => {
    if (date) {
      setStartDate(date);
      onFilterChange(date, endDate);
    }
  };

  const handleEndDateChange = (date: Moment | null) => {
    if (date) {
      setEndDate(date);
      onFilterChange(startDate, date);
    }
  };

  return (
    <FilterContainer>
      <FilterWrapper>
        <StartFilter value={startDate} onChange={handleStartDateChange} />
      </FilterWrapper>
      <FilterWrapper>
        <EndFilter value={endDate} onChange={handleEndDateChange} />
      </FilterWrapper>
      {/* <FilterWrapper>
        <DelayAreaFilter 
          delayAreas={delayAreas}
          selectedDelayArea={selectedDelayArea}
          onChange={onDelayAreaChange}
        />
      </FilterWrapper> */}
      <FilterWrapper>
        <FormControlLabel
          control={
            <Checkbox
              checked={includeMicrodelays}
              onChange={(e) => onIncludeMicrodelaysChange(e.target.checked)}
            />
          }
          label="Include Microdelays"
          style={{marginBottom: 0, marginLeft: 1}}
        />
      </FilterWrapper>
    </FilterContainer>
  );
};