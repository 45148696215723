import { CatalogLocation } from "../../../models/CatalogLocation";
import { TreeNode } from "../../../models/tree/TreeNode";
import { useCatalogLocationContext } from "../context/CatalogLocationContext";

export const useLocations = () => {
  const {
    state: { allLocations },
    actions: { setLocations}
  } = useCatalogLocationContext();

  const buildTreeLocations = (nodes: TreeNode[]) => {
    if (nodes === undefined) return;
    //tree build functions
    const idMapping = nodes.reduce((acc: any, el: any, i: number) => {
      acc[el.id] = i;
      return acc;
    }, {});
    let root;
    nodes.forEach((el) => {
      // Handle the root element
      if (el.parentId === null) {
        root = el;
        return;
      }
      // Use our mapping to locate the parent element in our data array
      const parentEl = nodes[idMapping[el.parentId]];
      // Handle the case where the element has no parent
      if (!parentEl) {
        console.log(el);
        return;
      }
      // Add our current el to its parent's `children` array
      if (!parentEl["children"]) {
        parentEl["children"] = [];
      }
      parentEl["children"] = [...(parentEl["children"] || []), el];
    });
    setLocations(root as any);
  };

  const loadFilterLocations = () => {
    const nodes = allLocations.map(
      (del: CatalogLocation) =>
        ({
          id: del.LocationId,
          parentId: del.ParentLocationId,
          name: del.LocationName,
          isLeaf: false,
          isActive: true,
          children: [],
        } as TreeNode)
    );
    buildTreeLocations(nodes);
  };

  return { loadFilterLocations };
};
