export interface CatalogSection {
	SectionId: number | null;
	SectionName: string;
	IsActive: boolean | null;
}

export const CATALOG_SECTION_INITIAL_VALUES: CatalogSection = {
	SectionId: null,
	SectionName: '',
	IsActive: true
};