import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { CatalogDelayCode } from "../models/CatalogDelayCode";

export const upsertDelay = async (DelayCode: CatalogDelayCode) => {
  const apiService = new MESApiService();
  const parameters: spParameter[] = [];
  parameters.push({
    name: "DelayAreaAssetId",
    value: DelayCode.DelayAreaAssetId,
  });
  parameters.push({ name: "DelayCodeId", value: DelayCode.DelayCodeId });
  parameters.push({ name: "DelayCodeName", value: DelayCode.DelayCodeName });
  parameters.push({
    name: "ParentDelayCodeId",
    value:
      DelayCode.ParentDelayCodeId === 0 ? null : DelayCode.ParentDelayCodeId,
  });
  parameters.push({
    name: "DefaultDelayCategoryId",
    value: DelayCode.DelayCategoryId,
  });
  parameters.push({ name: "IsActive", value: DelayCode.IsActive });

  const resp = await apiService.callV2(`[DEL].[UpsertDelayCode]`, parameters);
  return resp;
};

export const deleteDelay = async (DelayCodeId: number) => {
  const apiService = new MESApiService();
  const parameters: spParameter[] = [];

  parameters.push({ name: "DelayCodeId", value: DelayCodeId });

  const resp = await apiService.callV2(`[DEL].[DeleteDelayCode]`, parameters);
  return resp;
};

export const getDelays = async (assetId?: number) => {
  const apiService = new MESApiService();
  const parameters: spParameter[] = [];
  if (assetId) parameters.push({ name: "DelayAreaAssetId", value: assetId });
  return await apiService.callV2("[DEL].[GetDelayCodes]", parameters);
};

export const getDelay = async (DelayCodeId: number) => {
  const apiService = new MESApiService();
  const parameters: spParameter[] = [];
  parameters.push({ name: "DelayCodeId", value: DelayCodeId });
  return await apiService.callV2("[DEL].[GetDelayCode]", parameters);
};
