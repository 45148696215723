import {createContext, ReactNode, useContext} from "react";
import {useComplexState} from "use-complex-state";
import { CatalogLocationReducer } from "../reducer/CatalogLocationReducer";

const CatalogLocationContext = createContext({
	state: CatalogLocationReducer.getInitialState(),
	actions: CatalogLocationReducer.actions,
});

export const useCatalogLocationContext = () =>
	useContext(CatalogLocationContext);

export const CatalogLocationProvider = ({
	children,
}: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: CatalogLocationReducer.getInitialState(),
		reducers: CatalogLocationReducer.caseReducers,
	});

	return (
		<CatalogLocationContext.Provider value={{ state, actions }}>
			{children}
		</CatalogLocationContext.Provider>
	);
};
