import { ErrorModal } from "@dexteel/mesf-core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { get } from "lodash-es";
import { useEffect, useState } from "react";

import { getGuides } from "../../../repositories/parts/CatalogGuideRepository";
import { usePageStyles } from "../../../styles/pageStyles";
import { TableGuide } from "./components/TableGuide";
import { useCatalogGuideContext } from "./context/CatalogGuideContext";

export const CatalogGuidePage = () => {
  const classes = usePageStyles();
  const {
    actions: { setGuides },
  } = useCatalogGuideContext();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  const getGuidesFromAPI = async () => {
    setIsLoading(true);
    const resp = await getGuides();
    if (resp.ok) {
      const guides = get(resp, "data.tables[0].rows", []);
      setGuides(guides);
    } else {
      setError(resp.message!);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getGuidesFromAPI();
  }, []);

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={12} xs={12} style={{ margin: 0 }}>
          <Paper elevation={1} className={classes.paper}>
            <TableGuide
              isLoading={isLoading}
              getCatalogGuidesFromAPI={getGuidesFromAPI}
            />
          </Paper>
        </Grid>
      </Grid>
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
