import { CategoriesProvider } from "./context/CategoriesContext";
import { CatalogCategoriesPage } from "./CatalogCategoriesPage";

type Props = {};

const CatalogCategories = (props: Props) => {
  return (
      <CategoriesProvider>
        <CatalogCategoriesPage />
      </CategoriesProvider>
  );
};

export default CatalogCategories;
