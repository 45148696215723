import { ErrorModal, MesfModal } from "@dexteel/mesf-core";
import { Grid } from "@material-ui/core";
import { get } from "lodash-es";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { FooterModalButtons } from "../../../../../../controls/buttons/FooterModalButtons";
import { CenteredLazyLoading } from "../../../../../../controls/LazyLoading";
import { NotificationSnackBar } from "../../../../../../controls/snackbars/notification-snackbar";

import {
  CATALOG_CARTRIDGE_INITIAL_VALUES,
  CatalogCartridge,
} from "../../../../models/parts/CatalogCartridge";
import { CatalogCartridgeChock } from "../../../../models/parts/CatalogCartridgeChock";
import { getCartridgeChocks } from "../../../../repositories/parts/CatalogCartridgeChockRepository";
import {
  deleteCartridge,
  getCartridges,
  upsertCartridge,
} from "../../../../repositories/parts/CatalogCartridgeRepository";
import { PartFields } from "../../PartFields";
import { CartridgeChockField } from "./CartridgeChockField";

type Props = {
  CartridgeId: number | null;
  modal: "create" | "update" | "delete" | "";
  onHide: (shouldUpdate: boolean) => void;
};
export const UpsertDeleteModal = ({ CartridgeId, modal, onHide }: Props) => {
  const [title, setTitle] = useState<string>("CREATE");
  const [cartridgeChocks, setCartridgeChocks] =
    useState<CatalogCartridgeChock[]>();

  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const succesfullyMessage = `The cartridge was ${modal}d succesfully`;

  const { control, setValue, reset, handleSubmit } = useForm<CatalogCartridge>({
    defaultValues: CATALOG_CARTRIDGE_INITIAL_VALUES,
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<CatalogCartridge> = async (data) => {
    setIsSubmitLoading(true);
    if (modal === "delete") {
      const cartridgeResp = await deleteCartridge(data.PartId);
      if (cartridgeResp.ok) {
        setNotificationMessage(succesfullyMessage);
        onHide(true);
      } else {
        setError(cartridgeResp.message);
      }
    } else {
      const cartridgeResp = await upsertCartridge(data);
      if (cartridgeResp.ok) {
        setNotificationMessage(succesfullyMessage);
        onHide(true);
      } else {
        setError(cartridgeResp.message);
      }
    }
    setIsSubmitLoading(false);
  };

  const searchChocks = async () => {
    setIsLoading(true);
    const resp = await getCartridgeChocks();
    if (resp.ok) {
      const chocks = get(resp, "data.tables[0].rows", []);
      setCartridgeChocks(chocks);
    } else {
      setError(resp.message!);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (modal !== "") {
      setIsSubmitLoading(false);
      reset();
      setTitle(
        modal === "create" ? "CREATE" : modal === "update" ? "EDIT" : "DELETE"
      );
      searchChocks();
      if (modal !== "create")
        (async () => {
          setIsLoading(true);
          const response = await getCartridges(CartridgeId);
          if (response.ok) {
            const cartridge = get(
              response,
              "data.tables[0].rows[0]",
              null
            ) as CatalogCartridge;
            if (cartridge) {
              setValue("PartId", cartridge?.PartId);
              setValue("PartName", cartridge?.PartName);
              setValue("RFIDTag", cartridge?.RFIDTag);
              setValue("LocationName", cartridge?.LocationName);
              setValue("RivCartridgeChockId", cartridge?.RivCartridgeChockId);
              setValue("AveCartridgeChockId", cartridge?.AveCartridgeChockId);
            }
          } else {
            setError(response.message!);
            return;
          }
          setIsLoading(false);
        })();
    }
  }, [modal]);

  return (
    <>
      <MesfModal
        title={`${title} CARTRIDGE`}
        open={modal !== ""}
        handleClose={() => onHide(false)}
        maxWidth="sm"
        id="cartridge-modal"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <MesfModal.Content dividers style={{ padding: "10px 30px" }}>
            {isLoading && <CenteredLazyLoading />}
            {!isLoading && (
              <>
                <Grid container spacing={1}>
                  <PartFields<CatalogCartridge>
                    control={control}
                    disabled={modal === "delete"}
                  />
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="RivCartridgeChockId"
                      control={control}
                      render={({ field }) => (
                        <CartridgeChockField
                          label="River Chock"
                          value={field.value}
                          onChange={field.onChange}
                          modal={modal}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="AveCartridgeChockId"
                      control={control}
                      render={({ field }) => (
                        <CartridgeChockField
                          label="Avenue Chock"
                          value={field.value}
                          onChange={field.onChange}
                          modal={modal}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </MesfModal.Content>
          <MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
            <FooterModalButtons
              isSubmitLoading={isSubmitLoading}
              onHide={onHide}
              buttonLabel={modal === "delete" ? "Delete" : "Save"}
              buttonColor={modal === "delete" ? "secondary" : "primary"}
            />
          </MesfModal.Actions>
        </form>
      </MesfModal>
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error In Cartridge"
      />
    </>
  );
};
