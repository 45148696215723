import { useState, useEffect } from "react";
import { NavDropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const CustomNavbar = () => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const location = useLocation();

	useEffect(() => {
		setDropdownOpen(false);
	}, [location]);

	return (
		<>
			<NavDropdown title="Delays" id="nav-dropdown">
				<NavDropdown.Item as={Link} to="/delays-manager">
					Manager
				</NavDropdown.Item>
				<NavDropdown.Item as={Link} to="/delays-report">
					Report
				</NavDropdown.Item>
			</NavDropdown>
		</>
	);
};

export { CustomNavbar };
