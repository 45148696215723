import { createStyles, makeStyles } from "@material-ui/core";

export const usePageStyles = makeStyles(() =>
	createStyles({
		paper: {
            width: "100%",
            padding: "10px 0 20px",
            borderRadius: "0.75rem",
            height: "auto",
        }
	}),
);