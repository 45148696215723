import { ErrorModal, MesfModal } from "@dexteel/mesf-core";
import { Grid } from "@material-ui/core";
import { get } from "lodash-es";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { FooterModalButtons } from "../../../../../../controls/buttons/FooterModalButtons";
import { CenteredLazyLoading } from "../../../../../../controls/LazyLoading";
import { NotificationSnackBar } from "../../../../../../controls/snackbars/notification-snackbar";

import {
  CATALOG_DONUT_INITIAL_VALUES,
  CatalogDonut,
} from "../../../../models/parts/CatalogDonut";
import {
  deleteDonut,
  getDonuts,
  upsertDonut,
} from "../../../../repositories/parts/CatalogDonutRepository";
import { PartFields } from "../../PartFields";

type Props = {
  DonutId: number | null;
  modal: "create" | "update" | "delete" | "";
  onHide: (shouldUpdate: boolean) => void;
};
export const UpsertDeleteModal = ({ DonutId, modal, onHide }: Props) => {
  const [title, setTitle] = useState<string>("CREATE");

  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const succesfullyMessage = `The donut was ${modal}d succesfully`;

  const { control, setValue, reset, handleSubmit } = useForm<CatalogDonut>({
    defaultValues: CATALOG_DONUT_INITIAL_VALUES,
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<CatalogDonut> = async (data) => {
    setIsSubmitLoading(true);
    if (modal === "delete") {
      const donutResp = await deleteDonut(data.PartId);
      if (donutResp.ok) {
        setNotificationMessage(succesfullyMessage);
        onHide(true);
      } else {
        setError(donutResp.message);
      }
    } else {
      const donutResp = await upsertDonut(data);
      if (donutResp.ok) {
        setNotificationMessage(succesfullyMessage);
        onHide(true);
      } else {
        setError(donutResp.message);
      }
    }
    setIsSubmitLoading(false);
  };

  useEffect(() => {
    if (modal !== '') {
      setIsSubmitLoading(false);
      reset();
      setTitle(
        modal === "create" ? "CREATE" : modal === "update" ? "EDIT" : "DELETE"
      );
      if (modal !== "create")
        (async () => {
          setIsLoading(true);
          const response = await getDonuts(DonutId);
          if (response.ok) {
            const donut = get(
              response,
              "data.tables[0].rows[0]",
              null
            ) as CatalogDonut;
            if (donut) {
              setValue("PartId", donut?.PartId);
              setValue("PartName", donut?.PartName);
              setValue("RFIDTag", donut?.RFIDTag);
              setValue("LocationName", donut?.LocationName);
            }
          } else {
            setError(response.message!);
            return;
          }
          setIsLoading(false);
        })();
    }
  }, [modal]);

  return (
    <>
      <MesfModal
        title={`${title} DONUT`}
        open={modal !== ''}
        handleClose={() => onHide(false)}
        maxWidth="sm"
        id="donut-modal"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <MesfModal.Content dividers style={{ padding: "10px 30px" }}>
            {isLoading && <CenteredLazyLoading />}
            {!isLoading && (
              <>
                <Grid container spacing={1}>
                  <PartFields<CatalogDonut>
                    control={control}
                    disabled={modal === "delete"}
                  />
                </Grid>
              </>
            )}
          </MesfModal.Content>
          <MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
            <FooterModalButtons
              isSubmitLoading={isSubmitLoading}
              onHide={onHide}
              buttonLabel={modal === "delete" ? "Delete" : "Save"}
              buttonColor={modal === "delete" ? "secondary" : "primary"}
            />
          </MesfModal.Actions>
        </form>
      </MesfModal>
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error In Donut"
      />
    </>
  );
};
