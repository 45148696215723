import * as React from "react";
import { useState, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { ErrorModal, MesfModal } from "@dexteel/mesf-core";

import { useCatalogCategoryContext } from "../../context/CategoriesContext";
import { ConfirmModal } from "./ConfirmModal";
import ColorPicker from "../ColorPicker/ColorPicker";
import { CatalogCategory } from "../../models/CatalogCategory";
import { CATALOG_CATEGORY_INITIAL_VALUES } from "../../models/CatalogCategory";
import { LazyLoading } from "../../../../../controls/LazyLoading";
import { FooterModalButtons } from "../../../../../controls/buttons/FooterModalButtons";
import { NotificationSnackBar } from "../../../../../controls/snackbars/notification-snackbar";
import { useModalStyles } from "../../../styles/modalStyles";
import {
  getCatalogCategories,
  getCatalogCategory,
  upsertCatalogCategory,
} from "../../repositories/CatalogCategoriesRepository";

type Props = {
  DelayCategoryId: number | null;
  show: boolean;
  onHide: (shouldUpdate: boolean) => void;
};

export const EditCategory = ({ DelayCategoryId, show, onHide }: Props) => {
  const classes = useModalStyles();
  const [notificationMessage, setNotificationMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [error, setError] = useState("");
  const [categorySelected, setCategorySelected] = useState<any>();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [proceed, setProceed] = useState(false);
  const [categoryName, setCategoryName] = useState("");

  const {
    state: { assetCodes, categories },
    actions: { setAssetIdToFilter }
  } = useCatalogCategoryContext();

  const {
    register,
    control,
    reset,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<CatalogCategory>({ defaultValues: CATALOG_CATEGORY_INITIAL_VALUES });

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setNotificationMessage('');
  };

  const validateNoSpecialChars = (value: string) =>
    /^[a-zA-Z0-9 _-]*$/.test(value) || "No special characters";

  const onSubmit: SubmitHandler<CatalogCategory> = async (
    data: CatalogCategory
  ) => {
    const categoryExists = categories?.some(
      (category) =>
        category.DelayCategoryName === data.DelayCategoryName &&
      category.DelayAreaAssetId === data.DelayAreaAssetId &&
      category.DelayCategoryId !== data.DelayCategoryId
    );
    if (categoryExists) {
      setCategoryName(data.DelayCategoryName);
      setConfirmOpen(true);
    } else {
      await performUpsert(data);
    }
  };
  
  const performUpsert = async (data: CatalogCategory) => {
    setIsSubmitLoading(true);
    const editCategoryResp = await upsertCatalogCategory(data);
    if (editCategoryResp.ok) {
      setNotificationMessage('The category was edited successfully');
      setAssetIdToFilter(data.DelayAreaAssetId);
      onHide(true);
    } else {
      setError(editCategoryResp.message);
      onHide(false);
    }
    setIsSubmitLoading(false);
  };
  const onConfirmModalHandler = async (proceed: boolean) => {
		setConfirmOpen(false);
		if (proceed) {
			await performUpsert(getValues());
		}
	};

  useEffect(() => {
    if (show) {
      reset();
			setProceed(false);
			setConfirmOpen(false);
      setIsSubmitLoading(false);
      (async () => {
        setIsLoading(true);
        const response = await getCatalogCategory(DelayCategoryId);
        if (response.ok) {
          const category = response.data.tables[0].rows[0];
          if (category) {
            setValue("DelayCategoryId", category.DelayCategoryId);
            setValue("DelayAreaAssetId", category.DelayAreaAssetId);
            setValue("DelayCategoryName", category.DelayCategoryName);
            setValue("Color", category.DelayCategoryColor);
            setCategorySelected(category);
          } else {
            setError("Category not found");
          }
        } else {
          setError(response.message);
        }
        setIsLoading(false);
      })();
    }
  }, [show]);

  return (
    <>
      <Grid container>
        <Grid item>
          <MesfModal
            id="edit-category"
            title="EDIT CATEGORY"
            open={show}
            handleClose={() => onHide(false)}
            maxWidth="sm"
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <MesfModal.Content style={{ padding: "15px 30px" }}>
                {isLoading ? (
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ minHeight: "300px" }}
                  >
                    <LazyLoading />
                  </Grid>
                ) : (
                  <>
                    <Grid container spacing={1}>
                      <input
                        type="hidden"
                        {...register("DelayCategoryId")}
                        value={categorySelected?.DelayCategoryId}
                      />
                      <Grid item xs={12} md={12}>
                        <TextField
                          {...register("DelayCategoryName", {
                            required: "Name is required",
                            validate: validateNoSpecialChars,
                          })}
                          label="Name"
                          variant="outlined"
                          error={!!errors.DelayCategoryName}
                          fullWidth
                          margin="dense"
                          autoComplete="off"
                        />
                        {errors.DelayCategoryName && (
                          <span className={classes.errorLabel}>
                            {errors.DelayCategoryName.message}
                          </span>
                        )}
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <Controller
                          name="Color"
                          control={control}
                          rules={{ required: "Color is required" }}
                          render={({ field }) => (
                            <FormControl fullWidth margin="dense">
                              <ColorPicker
                                onChange={(_: string, color: string) =>
                                  field.onChange(color)
                                }
                                color={field.value}
                                disabled={false}
                                label="Color"
                              />
                              {errors.Color && (
                                <span className={classes.errorLabel}>
                                  {errors.Color.message}
                                </span>
                              )}
                            </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <Controller
                          name="IsActive"
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              className={classes.checkbox}
                              control={
                                <Checkbox
                                  checked={field.value}
                                  onChange={field.onChange}
                                  name="IsActive"
                                  color="primary"
                                />
                              }
                              label="is Active"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <Controller
                          name="DelayAreaAssetId"
                          control={control}
                          rules={{ required: "Asset is required" }}
                          render={({ field }) => (
                            <Autocomplete
                              id="clear-on-escape"
                              clearOnEscape
                              options={assetCodes ?? []}
                              getOptionLabel={(option) => option.AssetName}
                              onChange={(event, newValue) => {
                                field.onChange(
                                  newValue ? newValue.AssetId : null
                                );
                              }}
                              renderInput={(params) => (
                                <>
                                  <TextField
                                    {...params}
                                    label={"Asset"}
                                    variant="outlined"
                                    error={!!errors.DelayAreaAssetId}
                                    size="small"
                                    fullWidth
                                  />
                                  {errors.DelayAreaAssetId && (
                                    <span className={classes.errorLabel}>
                                      {errors.DelayAreaAssetId.message}
                                    </span>
                                  )}
                                </>
                              )}
                              value={assetCodes?.find(
                                (asset) => asset.AssetId === field.value
                              )}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </MesfModal.Content>
              <MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
                <FooterModalButtons
                  isSubmitLoading={isSubmitLoading}
                  onHide={onHide}
                />
              </MesfModal.Actions>
            </form>
          </MesfModal>
        </Grid>
      </Grid>

      <ConfirmModal
        show={confirmOpen}
        onHide={onConfirmModalHandler}
        title="WARNING"
        message={`The category "${categoryName}" already exists. Do you want to proceed?`}
        isLoading={isSubmitLoading}
      />
      <NotificationSnackBar
        message={notificationMessage}
        onHide={() => handleClose()}
      />
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
