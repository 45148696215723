import { CatalogCartridgePage } from "./CatalogCartridgePage";
import { CatalogCartridgeProvider } from "./context/CatalogCartridgeContext";

const CatalogCartridge = () => {
  return (
    <CatalogCartridgeProvider>
      <CatalogCartridgePage />
    </CatalogCartridgeProvider>
  );
};
export default CatalogCartridge;
