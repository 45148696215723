import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
} from "@material-ui/core";
import { ErrorModal, MesfModal, useAssetContext } from "@dexteel/mesf-core";

import { useCatalogCategoryContext } from "../../context/CategoriesContext";
import { ConfirmModal } from "./ConfirmModal";
import ColorPicker from "../ColorPicker/ColorPicker";
import { CatalogCategory } from "../../models/CatalogCategory";
import { CATALOG_CATEGORY_INITIAL_VALUES } from "../../models/CatalogCategory";
import { useModalStyles } from "../../../styles/modalStyles";
import { LazyLoading } from "../../../../../controls/LazyLoading";
import { FooterModalButtons } from "../../../../../controls/buttons/FooterModalButtons";
import { NotificationSnackBar } from "../../../../../controls/snackbars/notification-snackbar";
import {
  deleteCatalogCategory,
  getCatalogCategory,
} from "../../repositories/CatalogCategoriesRepository";

type Props = {
  DelayCategoryId: number | null;
  show: boolean;
  onHide: (shouldUpdate: boolean) => void;
};

export const DeleteCategory = ({ DelayCategoryId, show, onHide }: Props) => {
  const classes = useModalStyles();
  const [notificationMessage, setNotificationMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [error, setError] = useState("");
  const [categorySelected, setCategorySelected] = useState<any>();
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [selectedAssetName, setSelectedAssetName] = useState("");

  const {
    state: { allAssets },
  } = useAssetContext();
  const {
    actions: { setAssetIdToFilter },
  } = useCatalogCategoryContext();

  const { control, setValue, handleSubmit } = useForm<CatalogCategory>({
    defaultValues: CATALOG_CATEGORY_INITIAL_VALUES,
  });

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setNotificationMessage('');
  };

  const onSubmit: SubmitHandler<CatalogCategory> = async () => {
    setDeleteAlert(true);
  };

  const handleDelete = async (proceed: boolean) => {
    if (proceed) {
      setIsSubmitLoading(true);
      const deleteCategoryResp = await deleteCatalogCategory(DelayCategoryId);
      if (deleteCategoryResp.ok) {
        onHide(true);
        setNotificationMessage("The category was deleted successfully");
        setAssetIdToFilter(0);
      } else {
        setError(deleteCategoryResp.message);
      }
      setIsSubmitLoading(false);
    }
    setDeleteAlert(false);
  };

  useEffect(() => {
    if (show) {
      setIsSubmitLoading(false);
      (async () => {
        setIsLoading(true);
        const response = await getCatalogCategory(DelayCategoryId);
        if (response.ok) {
          const category = response.data.tables[0].rows[0];
          if (category) {
            setValue("DelayCategoryId", category.DelayCategoryId);
            setValue("DelayAreaAssetId", category.DelayAreaAssetId);
            setValue("DelayCategoryName", category.DelayCategoryName);
            setValue("Color", category.DelayCategoryColor);
            setCategorySelected(category);
            const selectedAsset = allAssets.find(
              (asset: any) => asset.AssetId === category?.DelayAreaAssetId
            );
            if (selectedAsset) {
              setSelectedAssetName(selectedAsset.AssetName);
            }
          } else {
            setError("Category not found");
          }
        } else {
          setError(response.message);
        }
        setIsLoading(false);
      })();
    }
  }, [show]);
  return (
    <>
      <MesfModal
        id="delete-category"
        title="DELETE CATEGORY"
        open={show}
        handleClose={() => onHide(false)}
        maxWidth="sm"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <MesfModal.Content style={{ padding: "15px 30px" }}>
            {isLoading ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "300px" }}
              >
                <LazyLoading />
              </Grid>
            ) : (
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <TextField
                    label="Name"
                    disabled
                    variant="outlined"
                    fullWidth
                    value={categorySelected?.DelayCategoryName || ""}
                    margin="dense"
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={3} md={3}>
                  <Controller
                    name="Color"
                    control={control}
                    rules={{ required: "Color is required" }}
                    render={({ field }) => (
                      <FormControl fullWidth margin="dense">
                        <ColorPicker
                          onChange={(_: string, color: string) =>
                            field.onChange(color)
                          }
                          color={field.value}
                          disabled={true}
                          label="Color"
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={3} md={3}>
                  <Controller
                    name="IsActive"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        className={classes.checkbox}
                        disabled
                        control={
                          <Checkbox
                            checked={field.value}
                            onChange={field.onChange}
                            name="IsActive"
                            color="primary"
                          />
                        }
                        label="is Active"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextField
                    label="Asset"
                    disabled
                    variant="outlined"
                    fullWidth
                    value={selectedAssetName}
                    margin="dense"
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
            )}
          </MesfModal.Content>
          <MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
            <FooterModalButtons
              isSubmitLoading={isSubmitLoading}
              onHide={onHide}
              buttonColor="secondary"
              buttonLabel="Delete"
            />
          </MesfModal.Actions>
        </form>
      </MesfModal>

      <ConfirmModal
        show={deleteAlert}
        onHide={handleDelete}
        title="WARNING"
        message={`Be careful, once deleted you will not be able to recover the category "${categorySelected?.DelayCategoryName}". Are you sure you want to delete it?`}
        isLoading={isSubmitLoading}
        color="secondary"
      />
      <NotificationSnackBar
        message={notificationMessage}
        onHide={() => handleClose()}
      />
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
