import { useEffect, useState } from "react";
import { Grid, Paper } from "@material-ui/core";
import { ErrorModal } from "@dexteel/mesf-core";
import { useCatalogCategoryContext } from "./context/CategoriesContext";
import { LazyLoading } from "../../../controls/LazyLoading";
import { TableCategories } from "./components/CategoriesDataTable/TableCategories";
import {
  getAssetsForDelayArea,
  getCatalogCategories,
} from "./repositories/CatalogCategoriesRepository";
import { get } from "lodash-es";
type Props = {};
export const CatalogCategoriesPage = (props: Props) => {
  const [isLoadingInitial, setIsLoadingInitial] = useState(true);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [error, setError] = useState("");
  const {
    state,
    state: { assetIdToFilter },
    actions: { setAssetCodes, setCategories },
  } = useCatalogCategoryContext();
  
  const getCatalogCategoriesFromAPI = async (
    assetIdToFilter: number | null = null
  ) => {
    setIsLoadingCategories(true);
    const includeAncestor = assetIdToFilter !== 0;
    const resp = await getCatalogCategories(assetIdToFilter, includeAncestor);
    if (resp.ok) {
      const categories = get(resp, "data.tables[0].rows", [])
      setCategories(categories);
    } else {
      setError(resp.message);
    }
    setIsLoadingCategories(false);
  };
  const getAssetsFromAPI = async () => {
    setIsLoadingInitial(true);
    const response = await getAssetsForDelayArea();
    if (response.ok) {
      setAssetCodes(response.data.tables[0].rows);
    } else {
      setError(response.message);
    }
    setIsLoadingInitial(false);
  };

  useEffect(() => {
    getAssetsFromAPI();
  }, []);
  useEffect(() => {
    if (!isLoadingInitial) {
      getCatalogCategoriesFromAPI(assetIdToFilter);
    }
  }, [isLoadingInitial, assetIdToFilter]);

  if (state.categories && state.assetCodes) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={12} xs={12} style={{ margin: 0 }}>
          <Paper
            elevation={1}
            style={{
              width: "100%",
              padding: "10px 0 20px",
              borderRadius: "0.75rem",
            }}
          >
            <TableCategories
              isLoading={isLoadingCategories}
              setIsLoading={setIsLoadingCategories}
              getCatalogCategoriesFromAPI={getCatalogCategoriesFromAPI}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <ErrorModal error={error} onHide={() => setError("")} />
      <LazyLoading />
    </div>
  );
};
