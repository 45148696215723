import { createSlice } from "@reduxjs/toolkit";
import { Asset } from "@dexteel/mesf-core/dist/models/Asset";
import { DelayByShift } from "../models/DelayByShift";

const DelaysManagerInitialState: {
  isLoadingPage: boolean;
  isLoadingBackground: boolean;
  isLoadingGrid: boolean;
  errorMessage: string;
  notificationMessage: string;
  delaySelected: number | null;
  shiftId: number | null | false;
  delays: DelayByShift[];
  assetForDelayArea: Asset[] | null;
  selectedDelay: DelayByShift | null;
  anchorPoint: any;
} = {
  isLoadingPage: false,
  isLoadingBackground: false,
  isLoadingGrid: false,
  delays: [],
  assetForDelayArea: null,
  selectedDelay: null,
  errorMessage: "",
  notificationMessage: "",
  delaySelected: null,
  shiftId: false,
  anchorPoint: {
    x: 0,
    y: 0,
  },
};

export const DelaysManagerReducer = createSlice({
  name: "__",
  initialState: DelaysManagerInitialState,
  reducers: {
    setIsLoadingPage(state, { payload }) {
      state.isLoadingPage = payload;
    },
    setIsLoadingBackground(state, { payload }) {
      state.isLoadingBackground = payload;
    },
    setIsLoadingGrid(state, { payload }) {
      state.isLoadingGrid = payload;
    },
    setDelays(state, { payload }) {
      state.delays = payload;
    },
    setAssetForDelayArea(state, { payload }) {
      state.assetForDelayArea = payload;
    },
    setSelectedDelay(state, { payload }) {
      state.selectedDelay = payload;
    },
    setErrorMessage(state, { payload }) {
      state.errorMessage = payload;
    },
    setNotificationMessage(state, { payload }) {
      state.notificationMessage = payload;
    },
    setDelaySelected(state, { payload }) {
      state.delaySelected = payload;
    },
    setShiftId(state, { payload }) {
      state.shiftId = payload;
    },
    setAnchorPointX(state, { payload }) {
      state.anchorPoint.x = payload;
    },
    setAnchorPointY(state, { payload }) {
      state.anchorPoint.y = payload;
    },
  },
});
