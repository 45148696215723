import { AuthConfig } from "@dexteel/mesf-core/dist/account/AuthProvider";

export const AuthenticationConfig: AuthConfig = {
	guestIsEnabled: false,
	useEmailAndPassword: true,
	useAzureAD: false,
	useWindowsAuth: false,
	AzureConfig: {
		clientId:
			import.meta.env.VITE_AZURE_AD_CLIENT_ID ||
			"7c77d607-51e0-4e03-bd50-d7aa18a7163f",
		authority:
			import.meta.env.VITE_AZURE_AD_AUTHORITY ||
			"https://login.microsoftonline.com/common",
		redirectUri:
			import.meta.env.VITE_AZURE_AD_REDIRECT_URI || "http://localhost:5000/",
	},
};
