export interface CatalogBarrel {
	BarrelId: number | null;
	BarrelName: string;
	BottomDiameter: number | null;
	TopDiameter: number | null;
	BottomDiameterMin: number | null;
	TopDiameterMin: number | null;
	Comments: string;
	IsActive: boolean | null;
}

export const CATALOG_BARREL_INITIAL_VALUES: CatalogBarrel = {
	BarrelId: null,
	BarrelName: '',
	BottomDiameter: null,
	TopDiameter: null,
	BottomDiameterMin: null,
	TopDiameterMin: null,
	Comments: '',
	IsActive: true
};