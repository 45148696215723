import { dxtToLocalServerTime, useShiftNavigator } from "@dexteel/mesf-core";

import DeleteIcon from "@material-ui/icons/Delete";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { useCallback, useState } from "react";
import { MenuOptionType } from "../../../../controls/contextMenu/models/MenuOptionType";
import { useDelaysManagerContext } from "../context/DelaysManagerContext";
import { DelayByShift } from "../models/DelayByShift";
import Edit from "@material-ui/icons/Edit";
import { CallSplit, Comment } from "@material-ui/icons";

export const useDelaysOptionFunctions = () => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showJustifyModal, setShowJustifyModal] = useState(false);
  const [showSplitModal, setShowSplitModal] = useState(false);
  const [messageDeleteModal, setMessageDeleteModal] = useState("");

  const {
    state: {},
    actions: { setSelectedDelay },
  } = useDelaysManagerContext();

  const { shiftInfo } = useShiftNavigator();

  //Create
  const createData = useCallback(() => {
    setSelectedDelay({
      DelayId: 0,
      eafOpen: shiftInfo?.CurrentProductionDate ?? new Date(),
      eafClose: shiftInfo?.CurrentProductionDate ?? new Date(),
      eafOpenString: "",
      eafCloseString: "",
      IsActive: true,
    });
    setShowEditModal(true);
  }, [shiftInfo?.CurrentProductionDate, setSelectedDelay]);

  // Edit
  const editData = (data: DelayByShift) => {
    setSelectedDelay(data);
    if (data.IsDelayManual) {
      setShowEditModal(true);
    }
  };

  // Justify
  const justifyData = (data: DelayByShift) => {
    setSelectedDelay(data);
    setShowJustifyModal(true);
  };

  // Split
  const splitData = (data: DelayByShift) => {
    setSelectedDelay(data);
    setShowSplitModal(true);
  };

  // Delete
  const deleteData = (data: DelayByShift) => {
    setSelectedDelay(data);
    const dateOpen = dxtToLocalServerTime(data.Start, "HH:mm:ss");

    setMessageDeleteModal(
      `The delay at ${dateOpen} will be deleted. Do you want to continue?`
    );
    setShowDeleteModal(true);
  };

  // Get Menu options
  const getMenuOptions = (data: DelayByShift) => {
    let options: MenuOptionType[] = [];

    if (data) {
      options = options.concat([
        {
          name: "Justify Delay",
          key: "justify_delay",
          onClick: () => justifyData(data),
          icon: <Comment />,
        },
        {
          name: "Edit Delay",
          key: "edit_delay",
          onClick: () => editData(data),
          icon: <Edit />,
          disabled: !data.IsDelayManual,
        },
        {
          name: "Delete Delay",
          key: "delete_delay",
          onClick: () => deleteData(data),
          icon: <DeleteIcon />,
          disabled: !data.IsDelayManual,
        },
        {
          name: "Split Delay",
          key: "split_delay",
          onClick: () => splitData(data),
          icon: <CallSplit />,
        }
      ]);
    }
    options.push(
      {
        name: "New Delay",
        key: "create_new_delay",
        onClick: () => createData(),
        icon: <PlaylistAddIcon />,
      }
    )

    return options;
  };

  return {
    createData,
    editData,
    deleteData,
    justifyData,
    getMenuOptions,
    showDeleteModal,
    showEditModal,
    showJustifyModal,
    showSplitModal,
    messageDeleteModal,
    setShowDeleteModal,
    setShowEditModal,
    setShowJustifyModal,
    setShowSplitModal,
    setMessageDeleteModal,
  };
};
