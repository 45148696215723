import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
	fixedBottom: {
		position: "fixed",
		bottom: 0,
		right: 0,
		padding: "0px 1.5rem",
		textAlign: "right",
	},
	labelNormalClass: {
		fontSize: "0.75rem",
		padding: "0.275rem 0.45rem",
		border: "none",
		backgroundColor: "#ced2cc",
		borderTopLeftRadius: "0.25rem !important",
		borderBottomLeftRadius: "0.25rem !important",
	},
	labelNormalButtonClass: {
		fontSize: "0.75rem",
		padding: "0.275rem 0.45rem",
		border: "none",
		backgroundColor: "#ced2cc",
		cursor: "pointer",
	},
	labelCheckboxClass: {
		fontSize: "0.75rem",
		padding: "0.275rem 0.50rem",
		border: "none",
		backgroundColor: "#ced2cc",
		borderTopLeftRadius: "0.25rem !important",
		borderBottomLeftRadius: "0.25rem !important",
	},
	labelScheduledClass: {
		fontSize: "0.75rem",
		padding: "0.275rem 0.45rem",
		backgroundColor: "#B1C381 !important",
		border: "none",
		borderTopLeftRadius: "0!important",
		borderBottomLeftRadius: "0!important",
	},
	labelMeltingClass: {
		fontSize: "0.75rem",
		padding: "0.275rem 0.45rem",
		backgroundColor: "#AFC8AD !important",
		border: "none",
	},
	labelTappedClass: {
		fontSize: "0.75rem",
		padding: "0.275rem 0.45rem",
		backgroundColor: "#F2D3A2 !important",
		border: "none",
	},
	labelOpenedClass: {
		fontSize: "0.75rem",
		padding: "0.275rem 0.45rem",
		backgroundColor: "#BEADFA !important",
		border: "none",
	},
	labelClosedClass: {
		fontSize: "0.75rem",
		padding: "0.275rem 0.45rem",
		backgroundColor: "#FF8080 !important",
		border: "none",
	},
	labelReturnedClass: {
		fontSize: "0.75rem",
		padding: "0.275rem 0.45rem",
		backgroundColor: "#82A0D8 !important",
		border: "none",
	},
	labelFinishedClass: {
		fontSize: "0.75rem",
		padding: "0.275rem 0.45rem",
		backgroundColor: "#7C9D96 !important",
		border: "none",
	},

	inputHeatNavigator: {
		textAlign: "center",
		fontSize: "22px",
		maxWidth: "130px",
	},
	inputShiftNavigator: {
		cursor: "pointer",
		textDecoration: "underline",
		textDecorationColor: "blue",
		color: "blue",
	},

	inputNormalClass: {
		fontSize: "0.60rem",
		borderTopLeftRadius: "0!important",
		borderBottomLeftRadius: "0!important",
	},
	styleLabel: {
		width: "150px",
		maxWidth: "150px",
	},

	normalItem: {
		padding: "4px 0px",
	},
	groupCheckBoxItem: {
		backgroundColor: "transparent !important",
		position: "relative",
		flex: "1 1 auto",
		width: "1%",
		minWidth: "0",
		marginBottom: "0",
	},
	checkBoxItem: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderTopRightRadius: "0.25rem !important",
		borderBottomRightRadius: "0.25rem !important",
		borderTopLeftRadius: "0!important",
		borderBottomLeftRadius: "0!important",
		backgroundColor: "#fff !important",
		margin: "auto",
		//maxWidth: "10rem",
		padding: " 0",
		// width: "10rem",
		//minWidth: "0",
		border: "1px solid #ced4da",
	},
	simpleCellHeader: {
		padding: "0.275rem 0.45rem",
		fontSize: "0.75rem",
		backgroundColor: "#ced2cc   !important",
		border: "1px solid #ced4da",
		color: "#495057",
		textAlign: "center",
	},
	simpleCellText: {
		padding: "0.275rem 0.45rem",
		fontSize: "0.75rem",
		borderRadius: "0px",
		height: "35px",
	},
	simpleCellCheckBox: {
		border: "1px solid #ced4da",
		textAlign: "center",
		margin: "auto",
		height: "2.7rem",
	},
	simpleCellNumeric: {
		fontSize: "0.75rem",
		borderRadius: "0px",
		padding: "0px 0.6rem",
		height: "35px",
		minWidth: "30px",
		textAlign: "right",
	},

	simpleButton: {
		borderRadius: "0.25rem",
		backgroundColor: "white",
		width: " 25px",
		fontSize: "14px",
		minWidth: " 25px",
		height: "25px",
		cursor: "pointer !important",
		border: "1px solid black",
		"&:hover": {
			backgroundColor: "white",
		},
		margin: "0px 2px",
		color: "black",
	},

	manualButton: {
		backgroundColor: "#D80073",
		"&:hover": {
			backgroundColor: "#D80073",
		},
		color: "white",
	},
	automaticButton: {
		backgroundColor: "#008A00",
		"&:hover": {
			backgroundColor: "#008A00",
		},
		color: "white",
	},

	historicButton: {
		backgroundColor: "blue",
		width: " 30px",
		height: "30px",
		color: "white",
	},
	lockButton: {
		borderRadius: "0px",
		backgroundColor: "#efefef",
		width: " 20px",
		height: "20px",
		cursor: "pointer !important",
		border: "1px solid #000",
		"&:hover": {
			backgroundColor: "#efefef",
		},
		margin: "0px 2px",
	},
	deleteButton: {
		borderRadius: "0px",

		width: " 20px",
		height: "20px",
		cursor: "pointer !important",
		"&:hover": {
			backgroundColor: "#efefef",
		},
		color: "red",
		margin: "0px 2px",
	},

	headerButton: {
		minWidth: "150px",
	},

	iconButton: {
		color: "white",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		fontSize: "14px",
		borderRadius: "0.25rem",
	},
}));
