import { createSlice } from "@reduxjs/toolkit";
import { CatalogCartridgeChock } from "../../../../models/parts/CatalogCartridgeChock";

const CatalogCartridgeChockInitialState: {
	cartridgeChocks: CatalogCartridgeChock[] | null;
} = {
	cartridgeChocks: null,
};

export const CatalogCartridgeChockReducer = createSlice({
	name: "__",
	initialState: CatalogCartridgeChockInitialState,
	reducers: {
		setCartridgeChocks(state, { payload }) {
			state.cartridgeChocks = payload;
		},
	},
});
