import { MenuItem, MenuList } from "@material-ui/core";
import { ReactNode } from "react";
import { Link, useParams } from "react-router-dom";
import CatalogBarrel from "../pages/configuration/pages/barrels";
import CatalogLocation from "../pages/configuration/pages/locations";
import CatalogCarrier from "../pages/configuration/pages/parts/carriers";
import CatalogCartridgeChock from "../pages/configuration/pages/parts/cartridge-chocks";
import CatalogCartridge from "../pages/configuration/pages/parts/cartridges";
import CatalogChock from "../pages/configuration/pages/parts/chocks";
import CatalogDonut from "../pages/configuration/pages/parts/donuts";
import CatalogGuide from "../pages/configuration/pages/parts/guides";
import CatalogRoll from "../pages/configuration/pages/parts/rolls";
import CatalogPassShape from "../pages/configuration/pages/pass-shapes";
import CatalogSection from "../pages/configuration/pages/sections";
import CatalogCategories from "../pages/delays/categories";
import CatalogDelayCodes from "../pages/delays/codes";

type Component = {
  path: string;
  sidebar: () => ReactNode;
  main: () => ReactNode;
};

const components: Component[] = [
  {
    path: "delay-categories",
    sidebar: () => <div>Categories</div>,
    main: () => <CatalogCategories />,
  },
  {
    path: "delay-codes",
    sidebar: () => <div>Codes</div>,
    main: () => <CatalogDelayCodes delayAreaAssetName="Mill #2" />,
  },
  {
    path: "barrels",
    sidebar: () => <div>Barrels</div>,
    main: () => <CatalogBarrel />,
  },
  {
    path: "carriers",
    sidebar: () => <div>Carriers</div>,
    main: () => <CatalogCarrier />,
  },
  {
    path: "cartridges",
    sidebar: () => <div>Cartridges</div>,
    main: () => <CatalogCartridge />,
  },
  {
    path: "cartridge-chocks",
    sidebar: () => <div>Cartridge Chocks</div>,
    main: () => <CatalogCartridgeChock />,
  },
  {
    path: "chocks",
    sidebar: () => <div>Chocks</div>,
    main: () => <CatalogChock />,
  },
  {
    path: "donuts",
    sidebar: () => <div>Donuts</div>,
    main: () => <CatalogDonut />,
  },
  {
    path: "guides",
    sidebar: () => <div>Guides</div>,
    main: () => <CatalogGuide />,
  },
  {
    path: "locations",
    sidebar: () => <div>Locations</div>,
    main: () => <CatalogLocation />,
  },
  {
    path: "pass-shapes",
    sidebar: () => <div>Pass Shapes</div>,
    main: () => <CatalogPassShape />,
  },
  {
    path: "rolls",
    sidebar: () => <div>Rolls</div>,
    main: () => <CatalogRoll />,
  },
  {
    path: "sections",
    sidebar: () => <div>Sections</div>,
    main: () => <CatalogSection />,
  },
];

const sidebar = () => {
  const { option } = useParams();

  return (
    <>
      <h3 className="mes-menu-group">Mill</h3>
      <MenuList className="mes-submenu">
        <MenuItem selected={option === "barrels"} className="p-0">
          <Link to="/configuration/barrels" className="nav-link">
            Barrels
          </Link>
        </MenuItem>
        <MenuItem selected={option === "carriers"} className="p-0">
          <Link to="/configuration/carriers" className="nav-link">
            Carriers
          </Link>
        </MenuItem>
        <MenuItem selected={option === "cartridges"} className="p-0">
          <Link to="/configuration/cartridges" className="nav-link">
            Cartridges
          </Link>
        </MenuItem>
        <MenuItem selected={option === "cartridge-chocks"} className="p-0">
          <Link to="/configuration/cartridge-chocks" className="nav-link">
            Cartridge Chocks
          </Link>
        </MenuItem>
        <MenuItem selected={option === "chocks"} className="p-0">
          <Link to="/configuration/chocks" className="nav-link">
            Chocks
          </Link>
        </MenuItem>
        <MenuItem selected={option === "donuts"} className="p-0">
          <Link to="/configuration/donuts" className="nav-link">
            Donuts
          </Link>
        </MenuItem>
        <MenuItem selected={option === "guides"} className="p-0">
          <Link to="/configuration/guides" className="nav-link">
            Guides
          </Link>
        </MenuItem>
        <MenuItem selected={option === "locations"} className="p-0">
          <Link to="/configuration/locations" className="nav-link">
            Locations
          </Link>
        </MenuItem>
        <MenuItem selected={option === "pass-shapes"} className="p-0">
          <Link to="/configuration/pass-shapes" className="nav-link">
            Pass Shapes
          </Link>
        </MenuItem>
        <MenuItem selected={option === "rolls"} className="p-0">
          <Link to="/configuration/rolls" className="nav-link">
            Rolls
          </Link>
        </MenuItem>
        <MenuItem selected={option === "sections"} className="p-0">
          <Link to="/configuration/sections" className="nav-link">
            Sections
          </Link>
        </MenuItem>
      </MenuList>
      <h3 className="mes-menu-group">Delays</h3>
      <MenuList className="mes-submenu">
        <MenuItem selected={option === "categories"} className="p-0">
          <Link to="/configuration/delay-categories" className="nav-link">
            Categories
          </Link>
        </MenuItem>
        <MenuItem selected={option === "codes"} className="p-0">
          <Link to="/configuration/delay-codes" className="nav-link">
            Codes
          </Link>
        </MenuItem>
      </MenuList>
    </>
  );
};

const customConfigurations: any = [components, sidebar];

export { customConfigurations };
