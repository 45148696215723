import { ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { CatalogCartridgeReducer } from "../reducer/CatalogCartridgeReducer";

const CatalogCartridgeContext = createContext({
  state: CatalogCartridgeReducer.getInitialState(),
  actions: CatalogCartridgeReducer.actions,
});

export const useCatalogCartridgeContext = () =>
  useContext(CatalogCartridgeContext);

export const CatalogCartridgeProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [state, actions] = useComplexState({
    initialState: CatalogCartridgeReducer.getInitialState(),
    reducers: CatalogCartridgeReducer.caseReducers,
  });
  return (
    <CatalogCartridgeContext.Provider value={{ state, actions }}>
      {children}
    </CatalogCartridgeContext.Provider>
  );
};
