import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { CatalogCategory } from "../models/CatalogCategory";

export const getCatalogCategories = async (
  DelayAreaAssetId?: number | null,
  IncludeAncestor?: boolean
) => {
  const apiService = new MESApiService();
  const parameters: spParameter[] = [];
  if (DelayAreaAssetId)
    parameters.push({ name: "DelayAreaAssetId", value: DelayAreaAssetId });
  if (IncludeAncestor)
    parameters.push({ name: "IncludeAncestor", value: IncludeAncestor });
  return await apiService.callV2("[DEL].[GetDelayCategories]", parameters);
};

export const getCatalogCategory = async (DelayCategoryId: number | null) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [];

  parameters.push({ name: "DelayCategoryId", value: DelayCategoryId });

  return await apiService.callV2("[DEL].[GetDelayCategory]", parameters);
};

export const getAssetsForDelayArea = async () => {
  const apiService = new MESApiService();
  const parameters: spParameter[] = [];
  parameters.push({ name: "DelayAreaAssetId", value: null });
  return await apiService.callV2("[DEL].[GetAssetsFordelayArea]", parameters);
};

export const deleteCatalogCategory = async (DelayCategoryId: number | null) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [];

  parameters.push({ name: "DelayCategoryId", value: DelayCategoryId });
  return await apiService.callV2("[DEL].[DeleteDelayCategory]", parameters);
};

export const upsertCatalogCategory = async (category: CatalogCategory) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [];

  parameters.push({ name: "DelayCategoryId", value: category.DelayCategoryId });
  parameters.push({
    name: "DelayAreaAssetId",
    value: category.DelayAreaAssetId,
  });
  parameters.push({
    name: "DelayCategoryName",
    value: category.DelayCategoryName,
  });
  parameters.push({ name: "DelayCategoryColor", value: category.Color });
  parameters.push({ name: "IsActive", value: category.IsActive });

  return await apiService.callV2("[DEL].[UpsertDelayCategory]", parameters);
};
