import { DelaysManagerPage } from "./DelaysManagerPage";

import { DelaysManagerProvider } from "./context/DelaysManagerContext";
import { ConfigurationDelayCodeProvider } from "../../delays/codes/context/ConfigurationDelayCodeContext";
import { CategoriesProvider } from "../../delays/categories/context/CategoriesContext";

type Props = {};
const DelaysManager = (props: Props) => {
  return (
    <ConfigurationDelayCodeProvider>
      <CategoriesProvider>
        <DelaysManagerProvider>
          <DelaysManagerPage />
        </DelaysManagerProvider>
      </CategoriesProvider>
    </ConfigurationDelayCodeProvider>
  );
};
export default DelaysManager;
