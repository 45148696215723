import { MESApiService, ResponseMESF, spParameter } from "@dexteel/mesf-core";
import { get, isNil } from "lodash-es";

export const getDelays = async (startDate?: Date, endDate?: Date, delayAreaAssetId?: number | null, includeMicrodelays: boolean = false): Promise<ResponseMESF> => {
  const apiService = new MESApiService();
  const parameters: spParameter[] = [
    { name: "IncludeMicrodelays", value: includeMicrodelays }
  ];

  if (startDate)
    parameters.push({ name: "StartDate", value: startDate});
  if (endDate)
    parameters.push({ name: "EndDate", value: endDate });
  if (!isNil(delayAreaAssetId))
    parameters.push({ name: "DelayAreaAssetId", value: delayAreaAssetId });

  const resp = await apiService.callV2("[DEL].[GetDelays]", parameters);

  if (resp.ok){

    let rows = get(resp, "data.tables[0].rows", []);
    
    return {
      ok: true,
      data: rows,
    };
  } else {
    return {
        ok: false,
        message: resp.message!,
    };
  }
};

export const getDelayAreas = async () => {
  const apiService = new MESApiService();
  return await apiService.callV2("[DEL].[GetDelayAreas]", []);
};