import { TimeService } from "@dexteel/mesf-core";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment-timezone";
import { Routes } from "react-router-dom";
import { DelayRoutes } from "./routes/delay.routes";

const timezone = TimeService.getInstance().getServerTimeZone();
moment.tz.setDefault(timezone);

const CustomRoutes = () => (
  <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
    <Routes>{DelayRoutes}</Routes>
  </LocalizationProvider>
);

export { CustomRoutes };
