import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { CatalogCarrier } from "../../models/parts/CatalogCarrier";

export const getCarriers = async (partId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (partId)
		parameters.push({ name: "PartId", value: partId });

	return await apiService.callV2("[M2].[GetCarriers]", parameters);
};
export const upsertCarrier = async (carrier: CatalogCarrier) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: carrier.PartId },
		{ name: "PartName", value: carrier.PartName },
	];

	return await apiService.callV2("[M2].[UpsertCarrier]", parameters);
};

export const deleteCarrier = async (partId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: partId }
	];

	return await apiService.callV2("[M2].[DeleteCarrier]", parameters);
};