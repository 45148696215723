import { createSlice } from "@reduxjs/toolkit";
import { CATALOG_LOCATION_INITIAL_VALUES, CatalogLocation } from "../../../models/CatalogLocation";
import { TreeParameters } from "../../../models/tree/TreeParameter";
const CatalogLocationInitialState: {
	openLocations: { [locationId: number | string]: number | string };
	allLocationNodes: TreeParameters[];
	dataToCreateLocation: CatalogLocation;
	allLocations: any;
	locationsDatasource: any;
	locationNodeSelectedInTree: CatalogLocation;
} = {
	openLocations: {},
	allLocationNodes: [],
	dataToCreateLocation: CATALOG_LOCATION_INITIAL_VALUES,
	allLocations: {},
	locationsDatasource: {},
	locationNodeSelectedInTree: CATALOG_LOCATION_INITIAL_VALUES,
};
export const CatalogLocationReducer = createSlice({
	name: "__",
	initialState: CatalogLocationInitialState,
	reducers: {
		setOpenLocations(state, { payload }) {
			state.openLocations = payload;
		},
		setAllLocationNodes(state, { payload }) {
			state.allLocationNodes = payload;
		},
		setDataToCreateLocation(state, { payload }) {
			state.dataToCreateLocation = payload;
		},
		setLocationNameToCreateLocation(state, { payload }) {
			state.dataToCreateLocation.LocationName = payload;
		},
		setLocationParentIdToCreateLocation(state, { payload }) {
			state.dataToCreateLocation.ParentLocationId = payload;
		},
		setLocations(state, { payload }) {
			state.locationsDatasource = payload;
		},
		setAllLocations(state, { payload }) {
			state.allLocations = payload;
		},
		setLocationNodeSelectedInTree(state, { payload }) {
			state.locationNodeSelectedInTree = payload;
		},
	},
});
