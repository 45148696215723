import { CatalogGuidePage } from "./CatalogGuidePage";
import { CatalogGuideProvider } from "./context/CatalogGuideContext";

const CatalogGuide = () => {
  return (
    <CatalogGuideProvider>
      <CatalogGuidePage />
    </CatalogGuideProvider>
  );
};
export default CatalogGuide;
