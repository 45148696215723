import { createSlice } from "@reduxjs/toolkit";
import { CatalogDonut } from "../../../../models/parts/CatalogDonut";

const CatalogDonutInitialState: {
	donuts: CatalogDonut[] | null;
} = {
	donuts: null,
};

export const CatalogDonutReducer = createSlice({
	name: "__",
	initialState: CatalogDonutInitialState,
	reducers: {
		setDonuts(state, { payload }) {
			state.donuts = payload;
		},
	},
});
