export interface CatalogDelayCode {
	DelayCodeId: number | null;
	DelayCodeName: string;
	DelayCategoryId: number | null;
 	ParentDelayCodeId: number | null;
	DelayAreaAssetId: string | number | null;
	IsBranch: boolean;
	IsDelayManual: boolean | null;
	IsActive: boolean | null;
}

export const CATALOG_DELAY_INITIAL_VALUES: CatalogDelayCode = {
	DelayCodeId: null,
	DelayCodeName: '',
	DelayCategoryId: null,
	ParentDelayCodeId: null,
	DelayAreaAssetId: null,
	IsBranch: false,
	IsDelayManual: false,
	IsActive: true
};

export interface DelayAreaAsset {
	AssetId: number,
	AssetName: string,
}