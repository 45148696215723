import { createSlice } from "@reduxjs/toolkit";
import { CatalogBarrel } from "../../../models/CatalogBarrel";

const CatalogBarrelInitialState: {
	barrels: CatalogBarrel[] | null;
} = {
	barrels: null,
};

export const CatalogBarrelReducer = createSlice({
	name: "__",
	initialState: CatalogBarrelInitialState,
	reducers: {
		setBarrels(state, { payload }) {
			state.barrels = payload;
		},
	},
});
