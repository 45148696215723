import { ErrorModal } from "@dexteel/mesf-core";
import { CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { get } from "lodash-es";
import { useEffect, useState } from "react";
import { CatalogLocation } from "../../../../../models/CatalogLocation";
import { getLocations } from "../../../../../repositories/CatalogLocationRepository";

type Props = {
  label: string;
  value: number | null;
  onChange: Function;
  modal: "create" | "update" | "delete" | "";
};

export const ScheduledLocationField = ({
  label,
  value,
  onChange,
  modal,
}: Props) => {
  const [locations, setLocations] = useState<CatalogLocation[]>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [error, setError] = useState<string>("");

  const searchSections = async () => {
    setIsLoading(true);
    const resp = await getLocations();
    if (resp.ok) {
      const locations = get(resp, "data.tables[0].rows", []);
      setLocations(locations);
    } else {
      setError(resp.message!);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    searchSections();
  }, []);

  return (
    <>
      <Autocomplete
        id="clear-on-escape"
        clearOnEscape
        options={locations || []}
        getOptionLabel={(option) => option.LocationName}
        disabled={modal === "delete"}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </>
              ),
            }}
          />
        )}
        value={locations?.find((roll) => roll.LocationId === value)}
        onChange={(event, newValue) => {
          onChange(newValue?.LocationId);
        }}
      />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error loading sections"
      />
    </>
  );
};
