import { CatalogPassShapeProvider } from "./context/CatalogPassShapeContext";
import { CatalogPassShapePage } from "./CatalogPassShapePage";

const CatalogPassShape = () => {
  return (
    <CatalogPassShapeProvider>
      <CatalogPassShapePage />
    </CatalogPassShapeProvider>
  );
};
export default CatalogPassShape;
