import { ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { CatalogGuideReducer } from "../reducer/CatalogGuideReducer";

const CatalogGuideContext = createContext({
  state: CatalogGuideReducer.getInitialState(),
  actions: CatalogGuideReducer.actions,
});

export const useCatalogGuideContext = () => useContext(CatalogGuideContext);

export const CatalogGuideProvider = ({ children }: { children: ReactNode }) => {
  const [state, actions] = useComplexState({
    initialState: CatalogGuideReducer.getInitialState(),
    reducers: CatalogGuideReducer.caseReducers,
  });
  return (
    <CatalogGuideContext.Provider value={{ state, actions }}>
      {children}
    </CatalogGuideContext.Provider>
  );
};
