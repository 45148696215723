import { ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { DelaysManagerReducer } from "../reducers/DelaysManagerReducer";

const DelaysManagerContext = createContext({
  state: DelaysManagerReducer.getInitialState(),
  actions: DelaysManagerReducer.actions,
});

export const useDelaysManagerContext = () => useContext(DelaysManagerContext);

export const DelaysManagerProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [state, actions] = useComplexState({
    initialState: DelaysManagerReducer.getInitialState(),
    reducers: DelaysManagerReducer.caseReducers,
  });

  return (
    <DelaysManagerContext.Provider value={{ state, actions }}>
      {children}
    </DelaysManagerContext.Provider>
  );
};
