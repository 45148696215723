
import { Button, Grid, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FormatListBulletedSharpIcon from "@material-ui/icons/FormatListBulletedSharp";
const useStyles = makeStyles(() => ({
    buttons: {
      opacity: 0,
      transition: "opacity 0.1s ease-in-out",
      ".ag-row-hover &": {
        opacity: 1,
      },
      ".ag-row-focus &": {
        opacity: 1,
      },
      ".ag-row-selected &": {
        opacity: 1,
      },
    },
}));
type Props = {
    setId: (id: number) => void;
    id: number;
    setShowModal: (modal: "create" | "update" | "delete" | "") => void;
    showContextMenu: () => void
}
export const GridButtons = ({setId, id, setShowModal, showContextMenu}: Props) => {
    const classes = useStyles();
    return (
        <Grid
          container
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className={classes.buttons}
        >
          <Grid
            item
            xs={12}
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              onClick={() => {
                (async () => {
                  setId(id);
                  setShowModal('update');
                })();
              }}
              title="Edit"
            >
              <EditIcon style={{ height: "auto" }} color="primary" />
            </Button>
            <Button
              onClick={() => {
                    setId(id);
                    setShowModal('delete');
              }}
              title="Delete"
            >
              <DeleteIcon style={{ height: "auto" }} color="secondary" />
            </Button>
            <Button
              style={{}}
              onClick={showContextMenu}
              title="Options"
            >
              <FormatListBulletedSharpIcon
                style={{ height: "auto" }}
                color="action"
              />
            </Button>
          </Grid>
        </Grid>
      );
}