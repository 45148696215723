import { createSlice } from "@reduxjs/toolkit";
import { CatalogCategory } from "../models/CatalogCategory";
import { Asset } from "@dexteel/mesf-core/dist/models/Asset";
const CategoriesInitialState: {
  categories: CatalogCategory[] | null;
  assetCodes: Asset[] | null;
  assetIdToFilter: number;
  firstTime: boolean;
} = {
  categories: null,
  assetCodes: null,
  assetIdToFilter: 0,
  firstTime: false,
};
export const CategoriesReducer = createSlice({
  name: "__",
  initialState: CategoriesInitialState,
  reducers: {
    setCategories(state, { payload }) {
      state.categories = payload;
    },
    setAssetCodes(state, { payload }) {
      state.assetCodes = payload;
    },
    setAssetIdToFilter(state, { payload }) {
      state.assetIdToFilter = payload;
    },
    setFirstTime(state, { payload }) {
      state.firstTime = payload;
    },
  },
});
