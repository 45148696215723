import { ColDef, ICellRendererParams } from "ag-grid-enterprise";
import { GridButtons } from "../../../../../../controls/buttons/GridButtons";
import { CatalogCarrier } from "../../../../models/parts/CatalogCarrier";
import { useCatalogCarrierContext } from "../context/CatalogCarrierContext";

type Props = {
  setCarrierId: (id: number) => void;
  setShowModal: (modal: "create" | "update" | "delete" | "") => void;
  showContextMenu: Function;
};
export const useTableData = ({
  setCarrierId,
  setShowModal,
  showContextMenu,
}: Props) => {
  const { state } = useCatalogCarrierContext();

  const rows: CatalogCarrier[] =
    state.carriers?.map((carrier: CatalogCarrier) => ({
      ...carrier,
    })) || [];

  const cellStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const columnDefs: ColDef[] = [
    {
      field: "PartName",
      headerName: "Name",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "RFIDTag",
      headerName: "Tag",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "LocationName",
      headerName: "Location",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "UpperRollName",
      headerName: "Upper Roll",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "LowerRollName",
      headerName: "Lower Roll",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "UMChockName",
      headerName: "UM Chock",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "LMChockName",
      headerName: "LM Chock",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "UOChockName",
      headerName: "UO Chock",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "LOChockName",
      headerName: "LO Chock",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "EntryGuideName",
      headerName: "Entry Guide",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "ExitGuideName",
      headerName: "Exit Guide",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "Bait",
      headerName: "Bait",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "WeighUp",
      headerName: "Weigh Up",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "ScheduledSectionName",
      headerName: "Scheduled Section",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "ScheduledLocationName",
      headerName: "Scheduled Location",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      cellRenderer: GridButtons,
      cellRendererParams: (params: ICellRendererParams) => ({
        setId: setCarrierId,
        id: (params.data as CatalogCarrier).PartId,
        setShowModal: setShowModal,
        showContextMenu: () =>
          showContextMenu(event, params.data, "carrierContext"),
      }),
      wrapText: false,
      autoHeight: false,
      wrapHeaderText: false,
      headerName: "",
      sortable: false,
      flex: 1,
      minWidth: 200,
      filter: false,
      autoHeaderHeight: true,
    },
  ];
  return { rows, columnDefs };
};
