import { MenuOptionType } from "@dexteel/mesf-core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";

import { CatalogCarrier } from "../../../../models/parts/CatalogCarrier";

type Props = {
  setCarrierId: (id: number) => void;
  setShowModal: (modal: "create" | "update" | "delete" | "") => void;
};
export const useCarrierOptionsFunctions = ({
  setCarrierId,
  setShowModal,
}: Props) => {
  const getMenuOptions = (data: CatalogCarrier) => {
    let options: MenuOptionType[] = [];
    options = options.concat([
      {
        name: "New Carrier",
        key: "new_carrier",
        onClick: () => {
          setShowModal("create");
        },
        icon: <PlaylistAddIcon />,
      },
    ]);
    if (data) {
      options = options.concat([
        {
          name: "Edit Carrier",
          key: "edit_carrier",
          onClick: () => {
            setCarrierId(data.PartId!);
            setShowModal("update");
          },
          icon: <EditIcon />,
        },
        {
          name: "Delete Carrier",
          key: "delete_carrier",
          onClick: () => {
            setCarrierId(data.PartId!);
            setShowModal("delete");
          },
          icon: <DeleteIcon />,
        },
      ]);
    }
    return options;
  };
  return {
    getMenuOptions,
  };
};
