import { ErrorModal, MesfModal } from "@dexteel/mesf-core";
import {
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from "@material-ui/core";
import { get } from "lodash-es";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { FooterModalButtons } from "../../../../../controls/buttons/FooterModalButtons";
import { CenteredLazyLoading } from "../../../../../controls/LazyLoading";
import { NotificationSnackBar } from "../../../../../controls/snackbars/notification-snackbar";

import {
  CATALOG_SECTION_INITIAL_VALUES,
  CatalogSection,
} from "../../../models/CatalogSection";
import {
  deleteSection,
  getSections,
  upsertSection,
} from "../../../repositories/CatalogSectionRepository";

type Props = {
  SectionId: number | null;
  modal: "create" | "update" | "delete" | "";
  onHide: (shouldUpdate: boolean) => void;
};
export const UpsertDeleteModal = ({ SectionId, modal, onHide }: Props) => {
  const [title, setTitle] = useState<string>("CREATE");

  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const succesfullyMessage = `The section was ${modal}d succesfully`;

  const { control, setValue, reset, handleSubmit } = useForm<CatalogSection>({
    defaultValues: CATALOG_SECTION_INITIAL_VALUES,
  });

  const onSubmit: SubmitHandler<CatalogSection> = async (data) => {
    setIsSubmitLoading(true);
    if (modal === "delete") {
      const sectionResp = await deleteSection(data.SectionId);
      if (sectionResp.ok) {
        setNotificationMessage(succesfullyMessage);
        onHide(true);
      } else {
        setError(sectionResp.message);
      }
    } else {
      const sectionResp = await upsertSection(data);
      if (sectionResp.ok) {
        setNotificationMessage(succesfullyMessage);
        onHide(true);
      } else {
        setError(sectionResp.message);
      }
    }
    setIsSubmitLoading(false);
  };

  useEffect(() => {
    if (modal !== "") {
      setIsSubmitLoading(false);
      reset();
      setTitle(
        modal === "create" ? "CREATE" : modal === "update" ? "EDIT" : "DELETE"
      );
      if (modal !== "create")
        (async () => {
          setIsLoading(true);
          const response = await getSections(SectionId);
          if (response.ok) {
            const section = get(
              response,
              "data.tables[0].rows[0]",
              null
            ) as CatalogSection;
            if (section) {
              setValue("SectionId", section?.SectionId);
              setValue("SectionName", section?.SectionName);
            }
          } else {
            setError(response.message);
            return;
          }
          setIsLoading(false);
        })();
    }
  }, [modal]);

  return (
    <>
      <MesfModal
        title={`${title} SECTION`}
        open={modal !== ""}
        handleClose={() => onHide(false)}
        maxWidth="sm"
        id="section-modal"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <MesfModal.Content dividers style={{ padding: "10px 30px" }}>
            {isLoading && <CenteredLazyLoading />}
            {!isLoading && (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Controller
                      name="SectionName"
                      control={control}
                      rules={{ required: "Name is required" }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormControl error={!!error} fullWidth>
                          <TextField
                            label="Name"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            autoComplete="off"
                            value={value}
                            disabled={modal === "delete"}
                            onChange={onChange}
                          />
                          {error && (
                            <FormHelperText>{error.message}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </MesfModal.Content>
          <MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
            <FooterModalButtons
              isSubmitLoading={isSubmitLoading}
              onHide={onHide}
              buttonLabel={modal === "delete" ? "Delete" : "Save"}
              buttonColor={modal === "delete" ? "secondary" : "primary"}
            />
          </MesfModal.Actions>
        </form>
      </MesfModal>
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error In Section"
      />
    </>
  );
};
