import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { MenuOptionType } from "../../../../controls/contextMenu/models/MenuOptionType";
import { TreeParameters } from "../models/TreeParameter";
type Props = {
  setDelayCodeId: Function;
  setShowModal: (modal: "create" | "update" | "delete" | "view" | "") => void;
};
export const useDelayCodeOptionsFunctions = ({
  setDelayCodeId,
  setShowModal,
}: Props) => {
  const getMenuOptions = (data: TreeParameters) => {
    let options: MenuOptionType[] = [];
    options = options.concat([
      {
        name: "New Delay Code",
        key: "new_delay_code",
        onClick: () => {
          setShowModal("create");
        },
        icon: <PlaylistAddIcon />,
      },
    ]);
    if (data) {
      options = options.concat([
        {
          name: "Edit Delay Code",
          key: "edit_delay_code",
          onClick: () => {
            setDelayCodeId(data.id);
            setShowModal("update");
          },
          icon: <EditIcon />,
        },
        {
          name: "View Delay Code",
          key: "view_delay_code",
          onClick: () => {
            setDelayCodeId(data.id);
            setShowModal("view");
          },
          icon: <FindInPageIcon />,
        },
      ]);
      if (!data.droppable) {
        options = options.concat([
          {
            name: "Delete Delay Code",
            key: "delete_delay_code",
            onClick: () => {
              setDelayCodeId(data.id);
              setShowModal("delete");
            },
            icon: <DeleteIcon />,
          },
        ]);
      }
    }
    return options;
  };
  return {
    getMenuOptions,
  };
};
