import { useState, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { ErrorModal, MesfModal } from "@dexteel/mesf-core";

import { useCatalogCategoryContext } from "../../context/CategoriesContext";

import ColorPicker from "../ColorPicker/ColorPicker";
import { ConfirmModal } from "./ConfirmModal";
import { CatalogCategory } from "../../models/CatalogCategory";
import { CATALOG_CATEGORY_INITIAL_VALUES } from "../../models/CatalogCategory";
import { useModalStyles } from "../../../styles/modalStyles";
import { FooterModalButtons } from "../../../../../controls/buttons/FooterModalButtons";
import { NotificationSnackBar } from "../../../../../controls/snackbars/notification-snackbar";
import {
  upsertCatalogCategory,
} from "../../repositories/CatalogCategoriesRepository";

type Props = {
  show: boolean;
  onHide: (categoryCreated: boolean) => void;
};

export const CreateCategory = ({ show, onHide }: Props) => {
  const classes = useModalStyles();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [error, setError] = useState("");
  const [notificationMessage, setNotificationMessage] = useState('');
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [proceed, setProceed] = useState(false);
  const [categoryName, setCategoryName] = useState("");

  const {
    state: { assetCodes, categories },
    actions: { setAssetIdToFilter }
  } = useCatalogCategoryContext();

  const {
    register,
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<CatalogCategory>({
    defaultValues: CATALOG_CATEGORY_INITIAL_VALUES,
  });

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setNotificationMessage('');
  };

  const validateNoSpecialChars = (value: string) =>
    /^[a-zA-Z0-9 _-]*$/.test(value) || "No special characters";

  const onSubmit: SubmitHandler<CatalogCategory> = async (
    data: CatalogCategory
  ) => {
      const categoryExists = categories?.some(
        (category) =>
          category.DelayCategoryName === data.DelayCategoryName &&
          category.DelayAreaAssetId === data.DelayAreaAssetId
      );
      if (categoryExists) {
        setCategoryName(data.DelayCategoryName);
        setConfirmOpen(true);
      } else {
        await performUpsert(data);
      }
  };
  const performUpsert = async (data: CatalogCategory) => {
    setIsSubmitLoading(true);
		const createCategoryResp = await upsertCatalogCategory(data);
		if (createCategoryResp.ok) {
      setAssetIdToFilter(data.DelayAreaAssetId);
      setNotificationMessage("The category was created successfully");
      onHide(true);
		} else {
			setError(createCategoryResp.message);
			onHide(false);
		}
		setIsSubmitLoading(false);
	};
  const onConfirmModalHandler = async (proceed: boolean) => {
		setConfirmOpen(false);
		if (proceed) {
			await performUpsert(getValues());
		}
	};

	useEffect(() => {
		if (show) {
			reset();
			setProceed(false);
			setConfirmOpen(false);
		}
	}, [show]);

  return (
    <>
      <Grid container>
        <Grid item>
          <MesfModal
            id="create-category"
            title="NEW CATEGORY"
            open={show}
            handleClose={() => onHide(false)}
            maxWidth="sm"
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <MesfModal.Content style={{ padding: "15px 30px" }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      {...register("DelayCategoryName", {
                        required: "Name is required",
                        validate: validateNoSpecialChars,
                      })}
                      label="Name"
                      variant="outlined"
                      error={!!errors.DelayCategoryName}
                      fullWidth
                      margin="dense"
                      autoComplete="off"
                    />
                    {errors.DelayCategoryName && (
                      <span className={classes.errorLabel}>
                        {errors.DelayCategoryName.message}
                      </span>
                    )}
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <Controller
                      name="Color"
                      control={control}
                      rules={{ required: "Color is required" }}
                      render={({ field }) => (
                        <FormControl fullWidth margin="dense">
                          <ColorPicker
                            onChange={(_: string, color: string) =>
                              field.onChange(color)
                            }
                            color={field.value}
                            disabled={false}
                            label="Color"
                          />
                          {errors.Color && (
                            <span className={classes.errorLabel}>
                              {errors.Color.message}
                            </span>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <Controller
                      name="IsActive"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          disabled
                          className={classes.checkbox}
                          control={
                            <Checkbox
                              checked={field.value}
                              onChange={field.onChange}
                              name="IsActive"
                              color="primary"
                              disabled
                            />
                          }
                          label="is Active"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Controller
                      name="DelayAreaAssetId"
                      control={control}
                      rules={{ required: "Asset is required" }}
                      render={({ field }) => (
                        <Autocomplete
                          id="clear-on-escape"
                          clearOnEscape
                          options={assetCodes ?? []}
                          getOptionLabel={(option) => option.AssetName}
                          onChange={(event, newValue) => {
                            field.onChange(
                              newValue ? newValue.AssetId : null
                            );
                          }}
                          renderInput={(params) => (
                            <>
                              <TextField
                                {...params}
                                label="Asset"
                                variant="outlined"
                                error={!!errors.DelayAreaAssetId}
                                size="small"
                                fullWidth
                              />
                              {errors.DelayAreaAssetId && (
                                <span className={classes.errorLabel}>
                                  {errors.DelayAreaAssetId.message}
                                </span>
                              )}
                            </>
                          )}
                          value={assetCodes?.find(
                            (asset) => asset.AssetId === field.value
                          )}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </MesfModal.Content>
              <MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
                <FooterModalButtons
                  isSubmitLoading={isSubmitLoading}
                  onHide={onHide}
                />
              </MesfModal.Actions>
            </form>
          </MesfModal>
          <NotificationSnackBar
            message={notificationMessage}
            onHide={() => handleClose()}
          />
        </Grid>
      </Grid>
      <ConfirmModal
        show={confirmOpen}
        onHide={onConfirmModalHandler}
        title="WARNING"
        message={`The category "${categoryName}" already exists. Do you want to proceed?`}
        isLoading={isSubmitLoading}
      />
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
