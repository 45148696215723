import { ErrorModal } from "@dexteel/mesf-core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { get } from "lodash-es";
import { useEffect, useState } from "react";

import { getCarriers } from "../../../repositories/parts/CatalogCarrierRepository";
import { usePageStyles } from "../../../styles/pageStyles";
import { TableCarrier } from "./components/TableCarrier";
import { useCatalogCarrierContext } from "./context/CatalogCarrierContext";

export const CatalogCarrierPage = () => {
  const classes = usePageStyles();
  const {
    actions: { setCarriers },
  } = useCatalogCarrierContext();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  const getCarriersFromAPI = async () => {
    setIsLoading(true);
    const resp = await getCarriers();
    if (resp.ok) {
      const carriers = get(resp, "data.tables[0].rows", []);
      setCarriers(carriers);
    } else {
      setError(resp.message!);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCarriersFromAPI();
  }, []);

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={12} xs={12} style={{ margin: 0 }}>
          <Paper elevation={1} className={classes.paper}>
            <TableCarrier
              isLoading={isLoading}
              getCatalogCarriersFromAPI={getCarriersFromAPI}
            />
          </Paper>
        </Grid>
      </Grid>
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
