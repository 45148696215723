import { ColDef, ICellRendererParams } from "ag-grid-enterprise";
import { GridButtons } from "../../../../../controls/buttons/GridButtons";
import { CatalogBarrel } from "../../../../configuration/models/CatalogBarrel";
import { useCatalogBarrelContext } from "../context/CatalogBarrelContext";

type Props = {
  setBarrelId: (id: number) => void;
  setShowModal: (modal: "create" | "update" | "delete" | "") => void;
  showContextMenu: Function;
};
export const useTableData = ({
  setBarrelId,
  setShowModal,
  showContextMenu,
}: Props) => {
  const { state } = useCatalogBarrelContext();

  const rows: CatalogBarrel[] =
    state.barrels?.map((barrel: CatalogBarrel) => ({
      ...barrel,
    })) || [];

  const cellStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const columnDefs: ColDef[] = [
    {
      field: "BarrelName",
      headerName: "Name",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "BottomDiameter",
      headerName: "Bottom Diameter",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "TopDiameter",
      headerName: "Top Diameter",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "BottomDiameterMin",
      headerName: "Bottom Diameter Min",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "TopDiameterMin",
      headerName: "Top Diameter Min",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "Comments",
      headerName: "Comments",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      cellRenderer: GridButtons,
      cellRendererParams: (params: ICellRendererParams) => ({
        setId: setBarrelId,
        id: (params.data as CatalogBarrel).BarrelId,
        setShowModal: setShowModal,
        showContextMenu: () =>
          showContextMenu(event, params.data, "barrelContext"),
      }),
      wrapText: false,
      autoHeight: false,
      wrapHeaderText: false,
      headerName: "",
      sortable: false,
      flex: 1,
      minWidth: 200,
      filter: false,
      autoHeaderHeight: true,
    },
  ];
  return { rows, columnDefs };
};
