import { CatalogLocationProvider } from "./context/CatalogLocationContext";
import { CatalogLocationPage } from "./CatalogLocationPage";

const CatalogLocation = () => {
  return (
    <CatalogLocationProvider>
      <CatalogLocationPage />
    </CatalogLocationProvider>
  );
};
export default CatalogLocation;
