import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@material-ui/core";

export interface ConfirmationDialogRawProps {
	title: string;
	message?: string;
	open: boolean;
	onClose: (value?: string) => void;
}
export const ConfirmationDialogRaw = ({
	title,
	message,
	open,
	onClose,
	...other
}: ConfirmationDialogRawProps) => {
	const handleCancel = () => {
		onClose("Cancel");
	};
	const handleOk = () => {
		onClose("OK");
	};
	return (
		<Dialog maxWidth="xs" open={open} {...other}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent dividers>
				<DialogContentText>{message}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={handleCancel}>
					Cancel
				</Button>
				<Button onClick={handleOk}>Ok</Button>
			</DialogActions>
		</Dialog>
	);
};
