import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { CatalogRoll } from "../../models/parts/CatalogRoll";

export const getRolls = async (partId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (partId)
		parameters.push({ name: "PartId", value: partId });

	return await apiService.callV2("[M2].[GetRolls]", parameters);
};
export const upsertRoll = async (roll: CatalogRoll) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: roll.PartId },
		{ name: "PartName", value: roll.PartName },
	];

	if (roll.DonutPartId)
		parameters.push({ name: "DonutPartId", value: roll.DonutPartId })
	if (roll.Diameter)
		parameters.push({ name: "Diameter", value: roll.Diameter })
	if (roll.RollStatusCode)
		parameters.push({ name: "RollStatusCode", value: roll.RollStatusCode })
	if (roll.RollTypeCode)
		parameters.push({ name: "RollTypeCode", value: roll.RollTypeCode })

	return await apiService.callV2("[M2].[UpsertRoll]", parameters);
};

export const deleteRoll = async (partId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: partId }
	];

	return await apiService.callV2("[M2].[DeleteRoll]", parameters);
};

export const getRollsV2 = async (partId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (partId)
		parameters.push({ name: "PartId", value: partId });

	const resp = await apiService.callV2("[M2].[GetRolls]", parameters);

	if (resp.ok){
		return get(resp, "data.tables[0].rows", []) as CatalogRoll[];
	}
	else {
		throw new Error(resp.message || "Error fetching rolls");
	}
};