import { createStyles, makeStyles } from "@material-ui/core";

export const useModalStyles = makeStyles(() =>
	createStyles({
		checkbox: {
			userSelect: "none",
		},
		modalBody: {},
		title: {
			fontSize: 15,
		},
		chip: {
			margin: 2,
		},
		errorLabel: {
			fontSize: 12,
			color: "#F44336",
		},
		infoLabel: {
			fontSize: 12,
			color: "#757575",
		},
	}),
);
