import { ColDef, ICellRendererParams } from "ag-grid-enterprise";
import { CatalogPassShape } from "../../../models/CatalogPassShape";
import { GridButtons } from "../../../../../controls/buttons/GridButtons";
import { useCatalogPassShapeContext } from "../context/CatalogPassShapeContext";

type Props = {
  setPassShapeId: (id: number) => void;
  setShowModal: (modal: "create" | "update" | "delete" | "") => void;
  showContextMenu: Function;
};
export const useTableData = ({
  setPassShapeId,
  setShowModal,
  showContextMenu,
}: Props) => {
  const { state } = useCatalogPassShapeContext();

  const rows: CatalogPassShape[] =
    state.passShapes?.map((passShape: CatalogPassShape) => ({
      ...passShape,
    })) || [];

  const cellStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const columnDefs: ColDef[] = [
    {
      field: "PassShapeName",
      headerName: "Name",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      cellRenderer: GridButtons,
      cellRendererParams: (params: ICellRendererParams) => ({
        setId: setPassShapeId,
        id: (params.data as CatalogPassShape).PassShapeId,
        setShowModal: setShowModal,
        showContextMenu: () =>
          showContextMenu(event, params.data, "passShapeContext"),
      }),
      wrapText: false,
      autoHeight: false,
      wrapHeaderText: false,
      headerName: "",
      sortable: false,
      flex: 1,
      minWidth: 200,
      filter: false,
      autoHeaderHeight: true,
    },
  ];
  return { rows, columnDefs };
};
