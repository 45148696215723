import { ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { CatalogCartridgeChockReducer } from "../reducer/CatalogCartridgeChockReducer";

const CatalogCartridgeChockContext = createContext({
  state: CatalogCartridgeChockReducer.getInitialState(),
  actions: CatalogCartridgeChockReducer.actions,
});

export const useCatalogCartridgeChockContext = () =>
  useContext(CatalogCartridgeChockContext);

export const CatalogCartridgeChockProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [state, actions] = useComplexState({
    initialState: CatalogCartridgeChockReducer.getInitialState(),
    reducers: CatalogCartridgeChockReducer.caseReducers,
  });
  return (
    <CatalogCartridgeChockContext.Provider value={{ state, actions }}>
      {children}
    </CatalogCartridgeChockContext.Provider>
  );
};
