import { ErrorModal } from "@dexteel/mesf-core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { get } from "lodash-es";
import { useEffect, useState } from "react";

import { getCartridges } from "../../../repositories/parts/CatalogCartridgeRepository";
import { usePageStyles } from "../../../styles/pageStyles";
import { TableCartridge } from "./components/TableCartridge";
import { useCatalogCartridgeContext } from "./context/CatalogCartridgeContext";

export const CatalogCartridgePage = () => {
  const classes = usePageStyles();
  const {
    actions: { setCartridges },
  } = useCatalogCartridgeContext();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  const getCartridgesFromAPI = async () => {
    setIsLoading(true);
    const resp = await getCartridges();
    if (resp.ok) {
      const cartridges = get(resp, "data.tables[0].rows", []);
      setCartridges(cartridges);
    } else {
      setError(resp.message!);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCartridgesFromAPI();
  }, []);

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={12} xs={12} style={{ margin: 0 }}>
          <Paper elevation={1} className={classes.paper}>
            <TableCartridge
              isLoading={isLoading}
              getCatalogCartridgesFromAPI={getCartridgesFromAPI}
            />
          </Paper>
        </Grid>
      </Grid>
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
