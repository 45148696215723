import { ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { CatalogPassShapeReducer } from "../reducer/CatalogPassShapeReducer";

const CatalogPassShapeContext = createContext({
  state: CatalogPassShapeReducer.getInitialState(),
  actions: CatalogPassShapeReducer.actions,
});

export const useCatalogPassShapeContext = () =>
  useContext(CatalogPassShapeContext);

export const CatalogPassShapeProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [state, actions] = useComplexState({
    initialState: CatalogPassShapeReducer.getInitialState(),
    reducers: CatalogPassShapeReducer.caseReducers,
  });
  return (
    <CatalogPassShapeContext.Provider value={{ state, actions }}>
      {children}
    </CatalogPassShapeContext.Provider>
  );
};
