import { ErrorModal, useContextMenuMESF } from "@dexteel/mesf-core";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { Tree } from "@minoru/react-dnd-treeview";
import {
  DragLayerMonitorProps,
  NodeModel,
} from "@minoru/react-dnd-treeview/dist/types";
import React, { useEffect, useMemo, useState } from "react";
import { FooterButton } from "../../../../../controls/buttons/FooterButton";
import { CenteredLazyLoading } from "../../../../../controls/LazyLoading";
import { NotificationSnackBar } from "../../../../../controls/snackbars/notification-snackbar";
import { useCatalogCategoryContext } from "../../../categories/context/CategoriesContext";
import { getCatalogCategories } from "../../../categories/repositories/CatalogCategoriesRepository";
import { useConfigurationDelayCodeContext } from "../../context/ConfigurationDelayCodeContext";
import { useSearchAssets } from "../../hooks/assets/useSearchAssets";
import { useSearchDelays } from "../../hooks/delays/useSearchDelays";
import { useDelayCodeOptionsFunctions } from "../../hooks/useDelayCodeOptionsFunctions";
import { CatalogDelayCode } from "../../models/CatalogDelayCode";
import { FileProperties } from "../../models/FileProperties";
import { TreeParameters } from "../../models/TreeParameter";
import { CustomNode } from "../CustomNode";
import { CustomDragPreview } from "../DragPreview/CustomDragPreview";
import { MultipleDragPreview } from "../DragPreview/MultipleDragPreview";
import { UpsertDeleteViewDelay } from "../modals/UpsertDeleteViewDelay";

const useStyles = makeStyles(() => ({
  root: {},
  assetRoot: {
    boxSizing: "border-box",
    height: "100%",
    padding: "32px",
  },
  dropTarget: {
    boxShadow: "0 0 0 1px #1967d2 inset",
  },
}));
interface TreeViewDelay {
  delayAreaAssetId?: number;
  delayAreaAssetName?: string;
}
export const TreeViewDelay = ({
  delayAreaAssetId,
  delayAreaAssetName,
}: TreeViewDelay) => {
  const classes = useStyles();

  const [selectedNodes, setSelectedNodes] = useState<
    NodeModel<FileProperties>[]
  >([]);
  const [showModal, setShowModal] = useState<
    "create" | "update" | "delete" | "view" | ""
  >("");

  const [isDragging, setIsDragging] = useState(false);
  const [isCtrlPressing, setIsCtrlPressing] = useState(false);

  const [errorUpdate, setErrorUpdate] = useState<string>("");
  const [searchError, setSearchError] = useState<string>("");

  const [delayCodeId, setDelayCodeId] = useState<number | null>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [error, setError] = useState("");
  const [updatedSuccessfullyMessage, setUpdatedSuccessfullyMessage] =
    useState("");

  const {
    state: { allDelayNodes, allDelayCodes, openDelays },
    actions: { setDelayNodeSelectedInTree, setDelayAreaAsset },
  } = useConfigurationDelayCodeContext();
  const { actions } = useCatalogCategoryContext();

  const { showContextMenu, registerConfig } = useContextMenuMESF();
  const { getMenuOptions } = useDelayCodeOptionsFunctions({
    setDelayCodeId,
    setShowModal,
  });

  const { searchDelays } = useSearchDelays({
    setSearchError,
    setAllDelayLoading: setIsLoading,
  });
  const { searchAssets } = useSearchAssets({
    setSearchError,
    setAllAssetLoading: setIsLoading,
  });

  const searchCategories = async (delayAreaAssetId: number | undefined) => {
    setIsLoadingCategories(true);
    const res = await getCatalogCategories(delayAreaAssetId, true);
    if (res.ok) {
      actions.setCategories(res.data.tables[0].rows);
    } else {
      setError(res.message);
    }
    setIsLoadingCategories(false);
  };

  // In the future, this would be the drag and drop functionality
  /* const { handleDrop, handleCtrlClick, handleDragStart, handleDragEnd } =
    useDelayActions({
      delay: allDelayNodes,
      selectedNodes,
      setSelectedNodes,
      setIsDragging,
      isCtrlPressing,
      setIsCtrlPressing,
      onDelayUpdateStart: () => {
        setIsLoading(true);
      },
      onDelayUpdateEnd: (result) => {
        if (result.ok) {
          setUpdatedSuccessfullyMessage(true);
          searchDelays(delayAreaAssetId);
        } else {
          setErrorUpdate(result.message);
        }
      },
    }); */

  const handleContextMenu = (
    data: NodeModel<FileProperties>,
    e: React.MouseEvent<HTMLElement>
  ) => {
    e.preventDefault();
    if (e.type === "contextmenu") {
      setSelectedNodes([data]);
      showContextMenu(e, data, "delayCodeContext");
      const selectedNode = allDelayCodes.find(
        (node: CatalogDelayCode) => node.DelayCodeId === data.id
      );
      if (selectedNode) {
        setDelayNodeSelectedInTree(selectedNode);
      }
    }
  };

  const onNodeDoubleClicked = (
    data: NodeModel<FileProperties>,
    e: React.MouseEvent<HTMLElement>
  ) => {
    e.preventDefault();
    setDelayCodeId((data as TreeParameters).id);
    setShowModal("view");
  };

  const onModalHide = (shouldUpdate: boolean) => {
    if (shouldUpdate) searchDelays(delayAreaAssetId);
    setShowModal("");
  };

  const openDelaysArray = useMemo(() => {
    return Object.keys(openDelays).map((el) => parseInt(el));
  }, [openDelays]);

  useEffect(() => {
    registerConfig({
      id: "delayCodeContext",
      getOptions: getMenuOptions,
    });
  }, []);

  useEffect(() => {
    const delayAreaAsset = {
      AssetId: delayAreaAssetId,
      AssetName: delayAreaAssetName,
    };
    setDelayAreaAsset(delayAreaAsset);
    searchCategories(delayAreaAssetId);
    searchAssets();
    searchDelays(delayAreaAssetId);
  }, [delayAreaAssetId]);

  if (!isLoadingCategories)
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <div onContextMenu={(e) => showContextMenu(e, null, "delayCodeContext")}>
          <div>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className={classes.root}
            >
              <Paper
                elevation={1}
                style={{
                  width: "100%",
                  padding: "15px 20px 25px",
                  borderRadius: "0.75rem",
                }}
              >
                <Typography
                  variant="h5"
                  style={{ margin: "20px 0 20px", fontWeight: 600 }}
                >
                  Delay Codes Tree{" "}
                  {delayAreaAssetName ? `- ${delayAreaAssetName}` : ""}
                </Typography>
                {isLoading ? (
                  <CenteredLazyLoading />
                ) : (
                  <Paper
                    elevation={2}
                    style={{
                      width: "100%",
                      padding: "15px 20px 10px",
                      borderRadius: "0.75rem",
                      marginBottom: 15,
                    }}
                  >
                    <Grid container alignItems="center">
                      <Grid container>
                        <div
                          style={{
                            height: "60vh",
                            width: "100%",
                            overflowY: "auto",
                            paddingBottom: 10,
                          }}
                        >
                          <Grid>
                            <Tree
                              rootId={0}
                              initialOpen={openDelaysArray}
                              tree={allDelayNodes}
                              enableAnimateExpand={true}
                              classes={{
                                root: classes.assetRoot,
                                dropTarget: classes.dropTarget,
                              }}
                              onDrop={(handleDrop) => {}}
                              onDragStart={(handleDragStart) => {}}
                              onDragEnd={(handleDragEnd) => {}}
                              canDrop={(asset: any, options: any): boolean => {
                                return !selectedNodes.some(
                                  (selectedNode) => false
                                  //selectedNode.id === options.dropTargetId
                                );
                              }}
                              render={(node, options) => {
                                const selected = selectedNodes.some(
                                  (selectedNode) => selectedNode.id === node.id
                                );
                                return (
                                  <CustomNode
                                    setContextMenuOver={handleContextMenu}
                                    node={node}
                                    {...options}
                                    isSelected={selected}
                                    isDragging={selected && isDragging}
                                    onClick={() => {}}
                                    onDoubleClick={onNodeDoubleClicked}
                                  />
                                );
                              }}
                              dragPreviewRender={(
                                monitorProps: DragLayerMonitorProps<FileProperties>
                              ) => {
                                if (selectedNodes.length > 1) {
                                  return (
                                    <MultipleDragPreview
                                      dragSources={selectedNodes}
                                    />
                                  );
                                }
                                return (
                                  <CustomDragPreview
                                    monitorProps={monitorProps}
                                  />
                                );
                              }}
                            />
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  </Paper>
                )}
                <FooterButton
                  label="DELAY CODE"
                  showCreateModal={showModal === "create"}
                  setShowCreateModal={(showCreateModal) =>
                    showCreateModal ? setShowModal("create") : setShowModal("")
                  }
                />
              </Paper>
            </Grid>
            <UpsertDeleteViewDelay
              DelayId={delayCodeId}
              modal={showModal}
              onHide={onModalHide}
            />
            <NotificationSnackBar
              message={updatedSuccessfullyMessage}
              onHide={() => setUpdatedSuccessfullyMessage("")}
            />
          </div>
        </div>
        <ErrorModal error={errorUpdate} onHide={() => setErrorUpdate("")} />
        <ErrorModal error={error} onHide={() => setError("")} />
        <ErrorModal error={searchError} onHide={() => setSearchError("")} />
      </div>
    );

  return <CenteredLazyLoading />;
};
