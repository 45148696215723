import {createContext, ReactNode, useContext} from "react";
import {useComplexState} from "use-complex-state";
import { ConfigurationDelayCodeReducer } from "../reducers/ConfigurationDelayCodeReducer";

const ConfigurationDelayCodeContext = createContext({
	state: ConfigurationDelayCodeReducer.getInitialState(),
	actions: ConfigurationDelayCodeReducer.actions,
});

export const useConfigurationDelayCodeContext = () =>
	useContext(ConfigurationDelayCodeContext);

export const ConfigurationDelayCodeProvider = ({
	children,
}: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: ConfigurationDelayCodeReducer.getInitialState(),
		reducers: ConfigurationDelayCodeReducer.caseReducers,
	});

	return (
		<ConfigurationDelayCodeContext.Provider value={{ state, actions }}>
			{children}
		</ConfigurationDelayCodeContext.Provider>
	);
};
