import { ErrorModal } from "@dexteel/mesf-core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { get } from "lodash-es";
import { useEffect, useState } from "react";

import { getSections } from "../../repositories/CatalogSectionRepository";
import { usePageStyles } from "../../styles/pageStyles";
import { TableSection } from "./components/TableSection";
import { useCatalogSectionContext } from "./context/CatalogSectionContext";

export const CatalogSectionPage = () => {
  const classes = usePageStyles();
  const {
    actions: { setSections },
  } = useCatalogSectionContext();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  const getSectionsFromAPI = async () => {
    setIsLoading(true);
    const resp = await getSections();
    if (resp.ok) {
      const sections = get(resp, "data.tables[0].rows", []);
      setSections(sections);
    } else {
      setError(resp.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getSectionsFromAPI();
  }, []);

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={12} xs={12} style={{ margin: 0 }}>
          <Paper elevation={1} className={classes.paper}>
            <TableSection
              isLoading={isLoading}
              getCatalogSectionsFromAPI={getSectionsFromAPI}
            />
          </Paper>
        </Grid>
      </Grid>
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
