import { createSlice } from "@reduxjs/toolkit";
import { CatalogRoll } from "../../../../models/parts/CatalogRoll";

const CatalogRollInitialState: {
	rolls: CatalogRoll[] | null;
} = {
	rolls: null,
};

export const CatalogRollReducer = createSlice({
	name: "__",
	initialState: CatalogRollInitialState,
	reducers: {
		setRolls(state, { payload }) {
			state.rolls = payload;
		},
	},
});
