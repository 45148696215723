import { makeStyles } from "@material-ui/core";
import { DragLayerMonitorProps } from "@minoru/react-dnd-treeview";
import React from "react";
import { FileProperties } from "../../../../models/tree/FileProperties";
import { TypeIcon } from "../TypeIcon";

const useStyles = makeStyles(() => ({
  root: {
    alignItems: "center",
    backgroundColor: "#1967d2",
    borderRadius: "4px",
    boxShadow:
      " 0 12px 24px -6px rgba(0, 0, 0, .25); 0 0 0 1px rgba(0, 0, 0, .08)",
    color: "#fff",
    display: "inline-grid",
    fontSize: "14px",
    gap: "8px",
    gridTemplateColumns: "auto auto",
    padding: "4px 8px",
    pointerEvents: "none",
  },

  icon: {
    alignItems: "center",
    display: "flex",
  },
  label: {
    alignItems: "center",
    display: "flex",
  },
}));

type Props = {
  monitorProps: DragLayerMonitorProps<FileProperties>;
};

export const CustomDragPreview: React.FC<Props> = (props) => {
  const classes = useStyles();

  const item = props.monitorProps.item;

  return (
    <div className={classes.root}>
      <div className={classes.icon}>
        <TypeIcon droppable={item.droppable!} fileType={item?.data?.fileType} />
      </div>
      <div className={classes.label}>{item.text}</div>
    </div>
  );
};
