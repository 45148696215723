import { makeStyles } from "@material-ui/core";

export const useGridStyles = makeStyles(() => ({
	menuContainer: {
		zIndex: 1000,
		minWidth: "10rem",
		padding: "0.5rem 0",
		margin: "0",
		fontSize: "1rem",
		textAlign: "left",
		listStyle: "none",
		backgroundColor: "#fff",
		backgroundClip: "padding-box",
		border: "1px solid rgba(0, 0, 0, 0.15)",
		borderRadius: "0.25rem",
	},
	menuItem: {
		display: "block",
		padding: "0.25rem 1rem",
		clear: "both",
		fontWeight: 400,
		color: "#212529",
		textAlign: "inherit",
		textDecoration: "none",
		whiteSpace: "nowrap",
		border: 0,
		"&:hover": {
			cursor: "pointer",
			background: "rgb(240, 240, 240)",
		},
		"&:active": {
			backgroundColor: "#0091ea",
			borderColor: "#0091ea",
			color: "white",
		},
	},
}));
