import { get } from "lodash-es";
import { Asset } from "@dexteel/mesf-core/dist/models/Asset";
import { useConfigurationAssetContext } from "../../context/ConfigurationAssetContext";
import { TransformAssetModelData } from "../TransformDelayModelData";
import { getAssets } from "../../repositories/ConfigurationAssetRepository";
type Props = {
	setSearchError: (err: string) => void;
	setAllAssetLoading: (loader: boolean) => void;
};
export const useSearchAssets = ({
	setSearchError,
	setAllAssetLoading,
}: Props) => {
	const {
		actions: { setAllAssetNodes },
	} = useConfigurationAssetContext();
	const searchAssets = () => {
		getAssets().then((res: any) => {
			if (res.ok) {
				let mydataSource: any[] = [];
				const rows = get(res, "data.tables[0].rows", []);
				rows.forEach((row: Asset) => {
					const code = TransformAssetModelData(row);
					mydataSource = [...mydataSource, code];
				});
				setAllAssetNodes(mydataSource);
				setAllAssetLoading(false);
			} else setSearchError(res.message);
		});
	};
	return { searchAssets };
};
