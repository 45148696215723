import { CatalogCartridgeChockPage } from "./CatalogCartridgeChockPage";
import { CatalogCartridgeChockProvider } from "./context/CatalogCartridgeChockContext";

const CatalogCartridgeChock = () => {
  return (
    <CatalogCartridgeChockProvider>
      <CatalogCartridgeChockPage />
    </CatalogCartridgeChockProvider>
  );
};
export default CatalogCartridgeChock;
