import { CatalogRollPage } from "./CatalogRollPage";
import { CatalogRollProvider } from "./context/CatalogRollContext";

const CatalogRoll = () => {
  return (
    <CatalogRollProvider>
      <CatalogRollPage />
    </CatalogRollProvider>
  );
};
export default CatalogRoll;
