import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  ErrorModal,
  getMomentTz,
  MesfModal,
  TimeService,
  TreePickerControl,
  useShiftNavigator,
} from "@dexteel/mesf-core";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { DateTimePicker } from "@mui/x-date-pickers";
import { LazyLoading } from "../../../../controls/LazyLoading";
import { Autocomplete } from "@material-ui/lab";
import { Asset } from "@dexteel/mesf-core/dist/models/Asset";
import { useDelaysManagerContext } from "../context/DelaysManagerContext";
import { DelayByShift } from "../models/DelayByShift";
import {
  justifyDelay,
  upsertDelay,
} from "../repositories/DelaysManagerRepository";
import { useDelayCodes } from "../../../delays/codes/hooks/delays/useDelayCodes";
import { useConfigurationDelayCodeContext } from "../../../delays/codes/context/ConfigurationDelayCodeContext";
import { useSearchDelays } from "../../../delays/codes/hooks/delays/useSearchDelays";
import { useCatalogCategoryContext } from "../../../delays/categories/context/CategoriesContext";
import { CatalogCategory } from "../../categories/models/CatalogCategory";
import { FooterModalButtons } from "../../../../controls/buttons/FooterModalButtons";
import { getCatalogCategories } from "../../categories/repositories/CatalogCategoriesRepository";

const useStyles = makeStyles(() => ({
  dateTimePicker: {},
  "@global": {
    "*::-webkit-scrollbar": {
      width: "10px",
      background: "#fff",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "	#C8C8C8",
    },
    "*::-webkit-scrollbar-track": {
      borderRadius: "10px",
    },
    "*::-webkit-scrollbar:horizontal": {
      height: "10px",
    },
  },
  infoLabel: {
    fontSize: 12,
    color: "#757575",
  },
  errorLabel: {
    fontSize: 13,
    color: "#F44336",
  },
}));

const moment = getMomentTz();

type Props = {
  show: boolean;
  onHide: (shouldUpdate: boolean) => void;
  refreshData: Function;
  delay: DelayByShift | null;
  ifJustified: boolean;
};
export const UpsertJustifyDelay = ({
  show,
  onHide,
  refreshData,
  delay,
  ifJustified,
}: Props) => {
  const classes = useStyles();

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isNew, setIsNew] = useState(false);
  const [title, setTitle] = useState("");
  const [duration, setDuration] = useState<string | number>("");
	const {shiftInfo, getShiftDataFromAPI} = useShiftNavigator();

  const {
    state: { assetForDelayArea },
    actions: { setNotificationMessage, setErrorMessage, setSelectedDelay, setDelaySelected},
  } = useDelaysManagerContext();

  const {
    state: { delayCodesDatasource, allDelayCodes },
  } = useConfigurationDelayCodeContext();

  const {
    state: { categories },
    actions: {setCategories}
  } = useCatalogCategoryContext();

  const { loadFilterDelays } = useDelayCodes();

  const { searchDelays } = useSearchDelays({
    setSearchError: setError,
    setAllDelayLoading: setIsLoading,
  });
  const searchCategories = async (delayAreaAssetId: number | undefined) => {
    setIsLoading(true);
    const res = await getCatalogCategories(delayAreaAssetId, true);
    if (res.ok) {
      setCategories(res.data.tables[0].rows);
    } else {
      setErrorMessage(res.message);
    }
    setIsLoading(false);
  };

  const {
    register,
    setValue,
    control,
    handleSubmit,
    getValues,
    reset,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<DelayByShift>({
    defaultValues: {},
  });

  const comments = watch("Comments");
  const start = watch("Start");
  const end = watch("End");

  const onChangeAsset = (event: any, newValue: Asset | null) => {
    if (newValue?.AssetId) {
      searchDelays(newValue?.AssetId);
      searchCategories(newValue?.AssetId);
      setValue("AssetId", newValue?.AssetId ?? null);
      setValue("DelayCodeId", null);
      setValue("DelayCodeName", null);
      setValue("DelayCategoryId", null);
      clearErrors("AssetId");
    }
  };

  const onChangeDelayCodeId = (value: any, description: any) => {
    setValue("DelayCodeId", value);
    setValue("DelayCodeName", description);
  };

  const onSubmit: SubmitHandler<DelayByShift> = async (data: DelayByShift) => {
    setIsSubmitLoading(true);
    if (ifJustified) {
      const response = await justifyDelay(
        data.DelayId!,
        data.DelayCodeId ?? null,
        data.DelayCategoryId ?? null,
        data.Comments ?? null
      );
      if (response.ok) {
        setNotificationMessage("Delay was justified successfully");
        setSelectedDelay(null);
        await refreshData();
      } else {
        setErrorMessage(response.message);
      }
    } else {
      const response = await upsertDelay(
        data.DelayId === 0 ? null : data.DelayId,
        data.AssetId,
        data.Start,
        data.End,
        data.DelayCodeId,
        data.DelayCategoryId,
        data.Comments
      );
      if (response.ok) {
        setNotificationMessage(
          "Delay was " + (isNew ? "created" : "edited") + " successfully"
        );
        setSelectedDelay(null);
        // Navigate to new delay
        await getShiftDataFromAPI(data.Start, 0);
        const successResponse = response as { ok: true; data: any; };
        setDelaySelected(successResponse.data.returnValue)
        await refreshData();
      } else {
        setErrorMessage(response.message);
      }
    }
    setIsSubmitLoading(false);
    onHide(true);
  };

  useEffect(() => {
    if (show) {
      setIsLoading(true);
      setIsSubmitLoading(false);
      if (ifJustified) {
        setTitle("JUSTIFY DELAY");
      } else {
        setTitle(delay?.DelayId === 0 ? "NEW DELAY" : "EDIT DELAY");
      }
      (async () => {
        if (delay) {
          if (delay?.DelayId === 0) {
            reset();
            setIsNew(true);
          } else {
            setIsNew(false);
          }
          if (delay?.AssetId) {
            searchDelays(delay?.AssetId ?? 0);
            searchCategories(delay?.AssetId ?? 0);
          }
          setValue("AssetId", delay.AssetId);
          setValue("DelayCodeId", delay.DelayCodeId);

          setValue("DelayId", delay.DelayId);
          setValue(
            "Start",
            delay.Start === null || delay.Start === undefined
              ? shiftInfo?.CurrentStart || null
              : delay.Start,
          );
          const defaultEnd = new Date(shiftInfo?.CurrentStart!);
          defaultEnd.setHours(defaultEnd.getHours() + 1);
          setValue(
            "End",
            delay.End === null || delay.End === undefined
              ? defaultEnd || null
              : delay.End
          );
          setValue("DelayCategoryId", delay.DelayCategoryId);
          setValue("Comments", delay.Comments);
        }
      })();
      setIsLoading(false);
    } else {
      reset();
    }
  }, [show]);

  useEffect(() => {
    if (start && end) {
      const duration = moment.duration(moment(end).diff(moment(start)));
      const minutes = Math.floor(duration.asMinutes());
      const seconds = duration.seconds();
      setDuration(`${minutes}m ${seconds}s`);
    } else {
      setDuration("");
    }
  }, [start, end]);

  useEffect(() => {
    if (allDelayCodes.length >= 0) {
      loadFilterDelays();
      const delayCode = allDelayCodes.find(
        (node: any) => node.DelayCodeId === delay?.DelayCodeId
      );
      if (delayCode) {
        setValue("DelayCodeName", delayCode.DelayCodeName);
      }
    }
  }, [allDelayCodes]);
  return (
    <>
      <Grid container>
        <Grid item className={classes.dateTimePicker}>
          <MesfModal
            maxWidth="md"
            open={show}
            handleClose={() => {
              onHide(true);
            }}
            id="upsertDelay"
            title={title}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <MesfModal.Content style={{ padding: "15px 30px" }} dividers>
                {isLoading && (
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ minHeight: "300px" }}
                  >
                    <LazyLoading />
                  </Grid>
                )}
                {!isLoading && (
                  <>
                    <Grid
                      container
                      spacing={1}
                      style={{ alignItems: "center" }}
                    >
                      <Grid item xs={4} md={4}>
                        <Box style={{height: 35}}>
                          <Controller
                            name="Start"
                            control={control}
                            rules={{
                              required: "Start date is required",
                              validate: {
                                notAfterEnd: (value) => {
                                  const endDate = watch("End");
                                  if (value && endDate && value > endDate) {
                                    return "Start date cannot be after End date.";
                                  }
                                  return true;
                                },
                                notInFuture: (value) => {
                                  if (value && value > new Date()) {
                                    return "Start date cannot be in the future.";
                                  }
                                  return true;
                                },
                              },
                            }}
                            render={({ field, fieldState: {error} }) => (
                              <FormControl fullWidth error={!!error}>
                                <DateTimePicker
                                  disabled={ifJustified}
                                  label="Delay Start"
                                  timezone={TimeService.getInstance().getServerTimeZone()}
                                  format="MM/DD/YYYY HH:mm:ss"
                                  ampm={false}
                                  value={moment(field.value)}
                                  onChange={field.onChange}
                                  inputRef={field.ref}
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      error: !!errors.Start,
                                    },
                                  }}
                                  />
                                {error && (
                                  <FormHelperText>{error.message}</FormHelperText>
                                )}
                              </FormControl>

                            )}
                            />
                        </Box>
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <Box style={{height: 35}}>
                          <Controller
                            name="End"
                            control={control}
                            rules={{
                              validate: {
                                notBeforeStart: (value) => {
                                  const startDate = watch("Start");
                                  if (value && startDate && value < startDate) {
                                    return "End date cannot be before Start date.";
                                  }
                                  return true;
                                },
                                notInFuture: (value) => {
                                  if (value && value > new Date()) {
                                    return "End date cannot be in the future.";
                                  }
                                  return true;
                                },
                                notSameAsStart: (value) => {
                                  const startDate = watch("Start");
                                  if (
                                    value &&
                                    startDate &&
                                    moment(value).isSame(moment(startDate))
                                  ) {
                                    return "End date cannot be the same as Start date.";
                                  }
                                  return true;
                                },
                              },
                            }}
                            render={({ field, fieldState: {error} }) => (
                              <FormControl fullWidth error={!!error}>
                                <DateTimePicker
                                  disabled={ifJustified}
                                  label="Delay End"
                                  format="MM/DD/YYYY HH:mm:ss"
                                  ampm={false}
                                  timezone={TimeService.getInstance().getServerTimeZone()}
                                  value={moment(field.value)}
                                  onChange={field.onChange}
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      error: !!error,
                                    },
                                  }}
                              />
                              {error && (
                                <FormHelperText>{error.message}</FormHelperText>
                              )}
                              </FormControl>
                            )}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={4} md={4} style={{ marginBottom: "3px" }}>
                        <Box style={{height: 45}}>
                          <TextField
                            label="Duration (min)"
                            disabled
                            variant="outlined"
                            fullWidth
                            value={duration}
                            margin="dense"
                            autoComplete="off"
                            />
                          </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={1}
                      style={{ alignItems: "center", marginTop: "12px" }}
                    >
                      <Grid item xs={4} md={4}>
                        <Box style={{height: 45}}>
                          <Controller
                            name="AssetId"
                            control={control}
                            rules={{ required: "Asset is required" }}
                            render={({ field }) => (
                              <Autocomplete
                                disabled={ifJustified}
                                id="clear-on-escape"
                                clearOnEscape
                                options={assetForDelayArea ?? []}
                                getOptionLabel={(option) => option.AssetName}
                                onChange={onChangeAsset}
                                renderInput={(params) => (
                                  <>
                                    <TextField
                                      {...params}
                                      label="Asset"
                                      variant="outlined"
                                      error={!!errors.AssetId}
                                      size="small"
                                      fullWidth
                                    />
                                  </>
                                )}
                                value={assetForDelayArea?.find(
                                  (asset: Asset) => asset.AssetId === field.value
                                )}
                                />
                            )}
                          />
                          {errors.AssetId && (
                            <span className={classes.errorLabel}>
                              {errors.AssetId.message}
                            </span>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={4} md={4} style={{ marginBottom: "3px" }}>
                        <Box style={{height: 58}}>
                          <Controller
                            name="DelayCodeName"
                            control={control}
                            render={({ field }) => (
                              <>
                                <TreePickerControl
                                  inputTitle="Code"
                                  title="Code"
                                  dataSource={delayCodesDatasource}
                                  value={delay?.DelayCodeId || 0}
                                  description={field.value || ""}
                                  onSelect={onChangeDelayCodeId}
                                  selectBranch={true}
                                  selectActive={false}
                                  selectInternal={false}
                                />
                                {errors.DelayCodeId && (
                                  <span style={{ color: "red" }}>
                                    {errors.DelayCodeId.message}
                                  </span>
                                )}
                              </>
                            )}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <Box style={{height: 45}}>
                          <Controller
                            name="DelayCategoryId"
                            control={control}
                            render={({ field }) => (
                              <Autocomplete
                                key={categories ? categories.length : 'loading'}
                                id="clear-on-escape"
                                clearOnEscape
                                options={categories ?? []}
                                getOptionLabel={(option) =>
                                  option.DelayCategoryName
                                }
                                onChange={(event, newValue) => {
                                  field.onChange(
                                    newValue ? newValue.DelayCategoryId : null
                                  );
                                }}
                                renderInput={(params) => (
                                  <>
                                    <TextField
                                      {...params}
                                      label="Category"
                                      variant="outlined"
                                      error={!!errors.DelayCategoryId}
                                      size="small"
                                      fullWidth
                                    />
                                    {errors.DelayCategoryId && (
                                      <span style={{ color: "red" }}>
                                        {errors.DelayCategoryId.message}
                                      </span>
                                    )}
                                  </>
                                )}
                                value={categories?.find(
                                  (category: CatalogCategory) => category.DelayCategoryId === field.value
                                )}
                              />
                            )}
                            />
                          </Box>
                      </Grid>
                    </Grid>
                    <Grid item md={12} xs={12} style={{ marginTop: "12px" }}>
                      <Controller
                        name="Comments"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <TextField
                            style={{ margin: "8px 0" }}
                            size="medium"
                            maxRows={5}
                            label="Comments"
                            multiline
                            variant="outlined"
                            fullWidth
                            autoComplete="off"
                            value={value}
                            onChange={(e) => {
                              if (e.target.value.length <= 200) onChange(e);
                            }}
                          />
                        )}
                      />
                      <Grid container style={{ marginBottom: 18 }}>
                        <Grid item>
                          {(comments?.length as number) >= 1 &&
                            (comments?.length as number) !== 200 && (
                              <span
                                className={classes.infoLabel}
                                style={{ marginLeft: 5 }}
                              >{`${comments?.length}/200 max.`}</span>
                            )}
                          {(comments?.length as number) == 200 && (
                            <span
                              className={classes.infoLabel}
                              style={{ marginLeft: 5 }}
                            >
                              Max. 200
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...register("IsDelayManual")}
                            color="primary"
                            checked={isNew || delay?.IsDelayManual}
                            disabled={true}
                          />
                        }
                        label="Manual Delay"
                      />
                    </Grid>
                  </>
                )}
              </MesfModal.Content>
              <MesfModal.Actions style={{ padding: "20px 30px 20px" }}>
                <FooterModalButtons
                  isSubmitLoading={isSubmitLoading}
                  onHide={onHide}
                />
              </MesfModal.Actions>
            </form>
          </MesfModal>
        </Grid>
      </Grid>
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
