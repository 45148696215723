import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  colorCircle: {
    width: "26px",
    height: "26px",
    borderRadius: 4,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    fontWeight: "bold",
  },
});
type CategoryTagProps = {
  value: {
    name: string;
    color: string;
  };
};
export const CategoryTag = ({
  value,
}: {
  value: CategoryTagProps["value"];
}) => {
  const classes = useStyles();
  if (!value || !value.name || !value.color) {
    return null;
  }
  return (
    <Tooltip title={value.name} arrow>
      <div
        className={classes.colorCircle}
        style={{ backgroundColor: value.color }}
      >
        {value.name.charAt(0).toUpperCase()}
      </div>
    </Tooltip>
  );
};
