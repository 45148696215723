import { ErrorModal } from "@dexteel/mesf-core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { get } from "lodash-es";
import { useEffect, useState } from "react";

import { getCartridgeChocks } from "../../../repositories/parts/CatalogCartridgeChockRepository";
import { usePageStyles } from "../../../styles/pageStyles";
import { TableCartridgeChock } from "./components/TableCartridgeChock";
import { useCatalogCartridgeChockContext } from "./context/CatalogCartridgeChockContext";

export const CatalogCartridgeChockPage = () => {
  const classes = usePageStyles();
  const {
    actions: { setCartridgeChocks },
  } = useCatalogCartridgeChockContext();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  const getCartridgeChocksFromAPI = async () => {
    setIsLoading(true);
    const resp = await getCartridgeChocks();
    if (resp.ok) {
      const cartridgeChocks = get(resp, "data.tables[0].rows", []);
      setCartridgeChocks(cartridgeChocks);
    } else {
      setError(resp.message!);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCartridgeChocksFromAPI();
  }, []);

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={12} xs={12} style={{ margin: 0 }}>
          <Paper elevation={1} className={classes.paper}>
            <TableCartridgeChock
              isLoading={isLoading}
              getCatalogCartridgeChocksFromAPI={getCartridgeChocksFromAPI}
            />
          </Paper>
        </Grid>
      </Grid>
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
