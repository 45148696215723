import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { CatalogCartridge } from "../../models/parts/CatalogCartridge";

export const getCartridges = async (partId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (partId)
		parameters.push({ name: "PartId", value: partId });

	return await apiService.callV2("[M2].[GetCartridges]", parameters);
};
export const upsertCartridge = async (cartridge: CatalogCartridge) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: cartridge.PartId },
		{ name: "PartName", value: cartridge.PartName },
	];

	if (cartridge.RivCartridgeChockId)
		parameters.push({ name: "RivCartridgeChockId", value: cartridge.RivCartridgeChockId })
	if (cartridge.AveCartridgeChockId)
		parameters.push({ name: "AveCartridgeChockId", value: cartridge.AveCartridgeChockId })

	return await apiService.callV2("[M2].[UpsertCartridge]", parameters);
};

export const deleteCartridge = async (partId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: partId }
	];

	return await apiService.callV2("[M2].[DeleteCartridge]", parameters);
};

export const getCartridgesV2 = async (partId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (partId)
		parameters.push({ name: "PartId", value: partId });

	const resp = await apiService.callV2("[M2].[GetCartridges]", parameters);

	if (resp.ok){
		return get(resp, "data.tables[0].rows", []) as CatalogCartridge[];
	}
	else {
		throw new Error(resp.message || "Error fetching cartridge chocks");
	}
};