import { createSlice } from "@reduxjs/toolkit";
import { DelayByShift } from "../models/DelayByShift";

const DelaysManagerInitialState: {
  isLoadingPage: boolean;
  isLoadingBackground: boolean;
  isLoadingGrid: boolean;
  errorMessage: string;
  notificationMessage: string;
  delays: DelayByShift[];
  selectedDelay: DelayByShift | null;
} = {
  isLoadingPage: false,
  isLoadingBackground: false,
  isLoadingGrid: false,
  errorMessage: "",
  notificationMessage: "",
  delays: [],
  selectedDelay: null,
};

export const DelaysManagerReducer = createSlice({
  name: "__",
  initialState: DelaysManagerInitialState,
  reducers: {
    setIsLoadingPage(state, { payload }) {
      state.isLoadingPage = payload;
    },
    setIsLoadingBackground(state, { payload }) {
      state.isLoadingBackground = payload;
    },
    setIsLoadingGrid(state, { payload }) {
      state.isLoadingGrid = payload;
    },
    setDelays(state, { payload }) {
      state.delays = payload;
    },
    setSelectedDelay(state, { payload }) {
      state.selectedDelay = payload;
    },
    setErrorMessage(state, { payload }) {
      state.errorMessage = payload;
    },
    setNotificationMessage(state, { payload }) {
      state.notificationMessage = payload;
    },
  },
});
