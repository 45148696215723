import { createSlice } from "@reduxjs/toolkit";
import { CatalogGuide } from "../../../../models/parts/CatalogGuide";

const CatalogGuideInitialState: {
	guides: CatalogGuide[] | null;
} = {
	guides: null,
};

export const CatalogGuideReducer = createSlice({
	name: "__",
	initialState: CatalogGuideInitialState,
	reducers: {
		setGuides(state, { payload }) {
			state.guides = payload;
		},
	},
});
