import { CatalogDonutPage } from "./CatalogDonutPage";
import { CatalogDonutProvider } from "./context/CatalogDonutContext";

const CatalogDonut = () => {
  return (
    <CatalogDonutProvider>
      <CatalogDonutPage />
    </CatalogDonutProvider>
  );
};
export default CatalogDonut;
