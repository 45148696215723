import { ErrorModal } from "@dexteel/mesf-core";
import { CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useQuery } from "@tanstack/react-query";
import { getCartridgeChocksV2 } from "../../../../repositories/parts/CatalogCartridgeChockRepository";
import { CatalogCartridgeChock } from "../../../../models/parts/CatalogCartridgeChock";
import { useState } from "react";
type Props = {
  label: string;
  value: number | null;
  onChange: Function;
  modal: "create" | "update" | "delete" | "";
};

const useCartridgeChocks = (partId?: number, onError?: (error: Error) => void) => {
  return useQuery<CatalogCartridgeChock[], Error>({
    queryKey: ["cartridgeChocks", partId],
    queryFn: () => getCartridgeChocksV2(partId),
    onError: onError,
  });
};

export const CartridgeChockField = ({
  label,
  value,
  onChange,
  modal,
}: Props) => {
  const [error, setError] = useState('')

  const { data: cartridgeChocks, isLoading } = useCartridgeChocks(undefined, (error) => setError(error.message));

  const selectedChock =
    cartridgeChocks?.find(
      (chock: CatalogCartridgeChock) => chock.PartId === value
    ) || null;

  return (
    <>
      <Autocomplete
        id="clear-on-escape"
        clearOnEscape
        options={cartridgeChocks || []}
        getOptionLabel={(option) => option.PartName}
        getOptionSelected={(option, value) => option.PartId === value.PartId}
        disabled={modal === "delete"}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </>
              ),
            }}
          />
        )}
        value={selectedChock}
        onChange={(event, newValue) => {
          onChange(newValue?.PartId);
        }}
      />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error loading cartridges"
      />
    </>
  );
};
