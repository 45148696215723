export interface CatalogCartridgeChock {
	PartId: number | null;
	PartName: string;
	RFIDTag: string | null;
	LocationName: string | null;
	IsActive: boolean | null;
}

export const CATALOG_CARTRIDGE_CHOCK_INITIAL_VALUES: CatalogCartridgeChock = {
	PartId: null,
	PartName: '',
	RFIDTag: '',
	LocationName: '',
	IsActive: true
};