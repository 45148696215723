import { DelaysManagerProvider } from "./context/DelaysManagerContext";
import { DelaysReportPage } from "./DelaysReportPage";

type Props = {};
const DelaysReport = (props: Props) => {
  return (
    <DelaysManagerProvider>
      <DelaysReportPage />
    </DelaysManagerProvider>
  );
};
export default DelaysReport;
