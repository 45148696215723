import { get } from "lodash-es";
import { CatalogLocation } from "../../../models/CatalogLocation";
import { getLocations } from "../../../repositories/CatalogLocationRepository";
import { useCatalogLocationContext } from "../context/CatalogLocationContext";
import { TransformLocationModelData } from "./TransformLocationModelData";
type Props = {
  setSearchError: (err: string) => void;
  setAllLocationLoading: (loader: boolean) => void;
};
export const useSearchLocations = ({
  setSearchError,
  setAllLocationLoading,
}: Props) => {
  const {
    actions: { setAllLocationNodes, setAllLocations },
  } = useCatalogLocationContext();
  const searchLocations = () => {
    getLocations().then((res: any) => {
      if (res.ok) {
        let mydataSource: any[] = [];
        const rows = get(res, "data.tables[0].rows", []);
        rows.forEach((row: CatalogLocation) => {
          const code = TransformLocationModelData(row);
          mydataSource = [...mydataSource, code];
        });
        setAllLocations(rows);
        setAllLocationNodes(mydataSource);
        setAllLocationLoading(false);
      } else setSearchError(res.message);
    });
  };
  return { searchLocations };
};
