import { ErrorModal, getMomentTz, MesfModal } from "@dexteel/mesf-core";
import { FormControl, FormHelperText, Grid } from "@material-ui/core";
import { DatePicker } from "@mui/x-date-pickers";
import { get } from "lodash-es";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { FooterModalButtons } from "../../../../../../controls/buttons/FooterModalButtons";
import { CenteredLazyLoading } from "../../../../../../controls/LazyLoading";
import { NotificationSnackBar } from "../../../../../../controls/snackbars/notification-snackbar";

import {
  CATALOG_CHOCK_INITIAL_VALUES,
  CatalogChock,
} from "../../../../models/parts/CatalogChock";
import {
  deleteChock,
  getChocks,
  upsertChock,
} from "../../../../repositories/parts/CatalogChockRepository";
import { PartFields } from "../../PartFields";

type Props = {
  ChockId: number | null;
  modal: "create" | "update" | "delete" | "";
  onHide: (shouldUpdate: boolean) => void;
};

const moment = getMomentTz();

export const UpsertDeleteModal = ({ ChockId, modal, onHide }: Props) => {
  const [title, setTitle] = useState<string>("CREATE");

  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const succesfullyMessage = `The chock was ${modal}d succesfully`;

  const { control, setValue, reset, handleSubmit } = useForm<CatalogChock>({
    defaultValues: CATALOG_CHOCK_INITIAL_VALUES,
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<CatalogChock> = async (data) => {
    setIsSubmitLoading(true);
    if (modal === "delete") {
      const chockResp = await deleteChock(data.PartId);
      if (chockResp.ok) {
        setNotificationMessage(succesfullyMessage);
        onHide(true);
      } else {
        setError(chockResp.message);
      }
    } else {
      const chockResp = await upsertChock(data);
      if (chockResp.ok) {
        setNotificationMessage(succesfullyMessage);
        onHide(true);
      } else {
        setError(chockResp.message);
      }
    }
    setIsSubmitLoading(false);
  };

  const validateNotInFuture = (description: string, value: Date | null) => {
    if (value && value > new Date()) {
      return `${description} time cannot be in the future.`;
    }
    return true;
  };

  useEffect(() => {
    if (modal !== "") {
      setIsSubmitLoading(false);
      reset();
      setTitle(
        modal === "create" ? "CREATE" : modal === "update" ? "EDIT" : "DELETE"
      );
      if (modal !== "create")
        (async () => {
          setIsLoading(true);
          const response = await getChocks(ChockId);
          if (response.ok) {
            const chock = get(response, "data[0]", null) as CatalogChock;
            if (chock) {
              setValue("PartId", chock?.PartId);
              setValue("PartName", chock?.PartName);
              setValue("RFIDTag", chock?.RFIDTag);
              setValue("LocationName", chock?.LocationName);
              setValue("RebuildTimestamp", chock?.RebuildTimestamp);
              setValue("BearingChangeTimestamp", chock?.BearingChangeTimestamp);
            }
          } else {
            setError(response.message!);
            return;
          }
          setIsLoading(false);
        })();
    }
  }, [modal]);

  return (
    <>
      <MesfModal
        title={`${title} CHOCK`}
        open={modal !== ""}
        handleClose={() => onHide(false)}
        maxWidth="sm"
        id="chock-modal"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <MesfModal.Content dividers style={{ padding: "10px 30px" }}>
            {isLoading && <CenteredLazyLoading />}
            {!isLoading && (
              <>
                <Grid container spacing={1}>
                  <PartFields<CatalogChock>
                    control={control}
                    disabled={modal === "delete"}
                  />
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="RebuildTimestamp"
                      control={control}
                      shouldUnregister
                      rules={{
                        validate: {
                          notInFuture: (value) =>
                            validateNotInFuture("Rebuild", value),
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth error={!!error}>
                          <DatePicker
                            disabled={modal === "delete"}
                            label="Rebuild Date"
                            format="MM/DD/YYYY"
                            views={["year", "month", "day"]}
                            value={moment(field.value)}
                            onChange={field.onChange}
                            slotProps={{
                              textField: {
                                size: "small",
                                error: !!error,
                              },
                            }}
                          />
                          {error && (
                            <FormHelperText>{error.message}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="BearingChangeTimestamp"
                      control={control}
                      shouldUnregister
                      rules={{
                        validate: {
                          notInFuture: (value) =>
                            validateNotInFuture("Bearing Change", value),
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth error={!!error}>
                          <DatePicker
                            disabled={modal === "delete"}
                            label="Bearing Change Date"
                            format="MM/DD/YYYY"
                            views={["year", "month", "day"]}
                            value={moment(field.value)}
                            onChange={field.onChange}
                            slotProps={{
                              textField: {
                                size: "small",
                                error: !!error,
                              },
                            }}
                          />
                          {error && (
                            <FormHelperText>{error.message}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </MesfModal.Content>
          <MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
            <FooterModalButtons
              isSubmitLoading={isSubmitLoading}
              onHide={onHide}
              buttonLabel={modal === "delete" ? "Delete" : "Save"}
              buttonColor={modal === "delete" ? "secondary" : "primary"}
            />
          </MesfModal.Actions>
        </form>
      </MesfModal>
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error In Chock"
      />
    </>
  );
};
