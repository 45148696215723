import { get } from "lodash-es";
import { useUTLSettingsContext } from "@dexteel/mesf-core";

import { ConfigurationAssetProvider } from "./context/ConfigurationAssetContext";
import { ConfigurationDelayCodeProvider } from "./context/ConfigurationDelayCodeContext";
import { CategoriesProvider } from "../categories/context/CategoriesContext";
import { CatalogDelayCodePage } from "./CatalogDelayCodePage";
type Props = {
	delayAreaAssetName?: string;
};
const CatalogDelayCodes = (props: Props) => {
	const {state} = useUTLSettingsContext();
	const assetId = Number(get(state, `settings.${props.delayAreaAssetName}`, 20));
	return (
		<ConfigurationDelayCodeProvider>
			<ConfigurationAssetProvider>
				<CategoriesProvider>
					<CatalogDelayCodePage
						delayAreaAssetId={assetId}
						delayAreaAssetName={props.delayAreaAssetName}
					/>
				</CategoriesProvider>
			</ConfigurationAssetProvider>
		</ConfigurationDelayCodeProvider>
	);
};

export default CatalogDelayCodes;