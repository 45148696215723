import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { CatalogDonut } from "../../models/parts/CatalogDonut";

export const getDonuts = async (partId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (partId)
		parameters.push({ name: "PartId", value: partId });

	return await apiService.callV2("[M2].[GetDonuts]", parameters);
};
export const upsertDonut = async (chock: CatalogDonut) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: chock.PartId },
		{ name: "PartName", value: chock.PartName },
	];

	return await apiService.callV2("[M2].[UpsertDonut]", parameters);
};

export const deleteDonut = async (partId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: partId }
	];

	return await apiService.callV2("[M2].[DeleteDonut]", parameters);
};