import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {TreeViewDelay} from "./components/DelayConfiguration/TreeViewDelay";
type Props = {
	delayAreaAssetId?: number;
	delayAreaAssetName?: string;
};
export const CatalogDelayCodePage = ({delayAreaAssetId, delayAreaAssetName}: Props) => {
	return (
		<DndProvider backend={HTML5Backend}>
			<TreeViewDelay
				delayAreaAssetId={delayAreaAssetId}
				delayAreaAssetName={delayAreaAssetName}
			/>
		</DndProvider>
	);
};
