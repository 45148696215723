import { ColDef, ICellRendererParams } from "ag-grid-enterprise";
import { GridButtons } from "../../../../../../controls/buttons/GridButtons";
import { CatalogRoll } from "../../../../models/parts/CatalogRoll";
import { useCatalogRollContext } from "../context/CatalogRollContext";

type Props = {
  setRollId: (id: number) => void;
  setShowModal: (modal: "create" | "update" | "delete" | "") => void;
  showContextMenu: Function;
};
export const useTableData = ({
  setRollId,
  setShowModal,
  showContextMenu,
}: Props) => {
  const { state } = useCatalogRollContext();

  const rows: CatalogRoll[] =
    state.rolls?.map((roll: CatalogRoll) => ({
      ...roll,
    })) || [];

  const cellStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const columnDefs: ColDef[] = [
    {
      field: "PartName",
      headerName: "Name",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "RFIDTag",
      headerName: "Tag",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "LocationName",
      headerName: "Location",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "Diameter",
      headerName: "Diameter",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "DonutPartName",
      headerName: "Donut",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "RollStatusCode",
      headerName: "Status",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
      valueFormatter: (params) => {
        const statusCode = (params.data as CatalogRoll).RollStatusCode;
        if (statusCode === "R") return "Ready";
        if (statusCode === "I") return "Installed";
        return "Damaged";
      },
    },
    {
      field: "RollTypeCode",
      headerName: "Type",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
      valueFormatter: (params) =>
        (params.data as CatalogRoll).RollTypeCode === "E"
          ? "Edger"
          : "Universal",
    },
    {
      cellRenderer: GridButtons,
      cellRendererParams: (params: ICellRendererParams) => ({
        setId: setRollId,
        id: (params.data as CatalogRoll).PartId,
        setShowModal: setShowModal,
        showContextMenu: () =>
          showContextMenu(event, params.data, "rollContext"),
      }),
      wrapText: false,
      autoHeight: false,
      wrapHeaderText: false,
      headerName: "",
      sortable: false,
      flex: 1,
      minWidth: 200,
      filter: false,
      autoHeaderHeight: true,
    },
  ];
  return { rows, columnDefs };
};
