import { dxtToLocalServerTime } from "@dexteel/mesf-core";
import { ColDef, ICellRendererParams } from "ag-grid-enterprise";
import { GridButtons } from "../../../../../../controls/buttons/GridButtons";
import { CatalogChock } from "../../../../models/parts/CatalogChock";
import { useCatalogChockContext } from "../context/CatalogChockContext";

type Props = {
  setChockId: (id: number) => void;
  setShowModal: (modal: "create" | "update" | "delete" | "") => void;
  showContextMenu: Function;
};
export const useTableData = ({
  setChockId,
  setShowModal,
  showContextMenu,
}: Props) => {
  const { state } = useCatalogChockContext();

  const rows: CatalogChock[] =
    state.chocks?.map((chock: CatalogChock) => ({
      ...chock,
    })) || [];

  const cellStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const columnDefs: ColDef[] = [
    {
      field: "PartName",
      headerName: "Name",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "RFIDTag",
      headerName: "Tag",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "LocationName",
      headerName: "Location",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "RebuildTimestamp",
      headerName: "Rebuild Date",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
      valueGetter: (params) =>
        dxtToLocalServerTime(
          (params.data as CatalogChock)?.RebuildTimestamp,
          "MM/dd/yy"
        ) || "",
    },
    {
      field: "BearingChangeTimestamp",
      headerName: "Bearing Change Date",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
      valueGetter: (params) =>
        dxtToLocalServerTime(
          (params.data as CatalogChock)?.BearingChangeTimestamp,
          "MM/dd/yy"
        ) || "",
    },
    {
      cellRenderer: GridButtons,
      cellRendererParams: (params: ICellRendererParams) => ({
        setId: setChockId,
        id: (params.data as CatalogChock).PartId,
        setShowModal: setShowModal,
        showContextMenu: () =>
          showContextMenu(event, params.data, "chockContext"),
      }),
      wrapText: false,
      autoHeight: false,
      wrapHeaderText: false,
      headerName: "",
      sortable: false,
      flex: 1,
      minWidth: 200,
      filter: false,
      autoHeaderHeight: true,
    },
  ];
  return { rows, columnDefs };
};
