import { createSlice } from "@reduxjs/toolkit";
import { CatalogCartridge } from "../../../../models/parts/CatalogCartridge";

const CatalogCartridgeInitialState: {
	cartridges: CatalogCartridge[] | null;
} = {
	cartridges: null,
};

export const CatalogCartridgeReducer = createSlice({
	name: "__",
	initialState: CatalogCartridgeInitialState,
	reducers: {
		setCartridges(state, { payload }) {
			state.cartridges = payload;
		},
	},
});
