import {
  ErrorModal,
  ShiftDayNavigatorControl,
  useShiftNavigator,
} from "@dexteel/mesf-core";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import {
  LazyBackDropLoading,
  LazyLoading,
} from "../../../controls/LazyLoading";
import { NotificationSnackBar } from "../../../controls/snackbars/notification-snackbar";
import { DelaysManagerTable } from "./components/table/DelaysManagerTable";
import { useDelaysManagerContext } from "./context/DelaysManagerContext";
import {
  getAssetsForDelayArea,
  getDelaysByShift,
} from "./repositories/DelaysManagerRepository";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { get } from "lodash-es";

type Props = {};
export const DelaysManagerPage = (props: Props) => {
  const { state, actions } = useDelaysManagerContext();
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { shiftId: shiftIdParam } = useParams();
  const [params] = useSearchParams();
  const { shiftInfo, getShiftDataFromAPI } = useShiftNavigator();
  const [showMicroDelays, setShowMicroDelays] = useState(false);

  const getDataFromAPI = async () => {
    actions.setIsLoadingGrid(true);
    const resp = await getDelaysByShift(
      shiftInfo?.CurrentShiftId || 0,
      showMicroDelays
    );
    if (resp.ok) {
      actions.setDelays(resp.data);
    } else {
      setError(resp.message);
    }
    actions.setIsLoadingGrid(false);

    const response = await getAssetsForDelayArea();
    if (response.ok) {
      const rows = get(response, 'data.tables[0].rows', []);
      actions.setAssetForDelayArea(rows);
    } else {
      setError(response.message);
    }
  };

  useEffect(() => {
    if (shiftInfo) {
      const shiftId = get(shiftInfo, "CurrentShiftId", null);
      if (state.delaySelected && state.delaySelected !== 0) {
        navigate(`/delays-manager/${shiftId}?delayId=${state.delaySelected}`);
      } else navigate(`/delays-manager/${shiftId}`);
      (async () => {
        await getDataFromAPI();
      })();
    }
  }, [shiftInfo, state.delaySelected, showMicroDelays]);

  useEffect(() => {
    if (shiftIdParam) {
      (async () => {
        const shiftId = Number(shiftIdParam);
        await getShiftDataFromAPI(null, shiftId);
      })();
    } else {
      (async () => {
        await getShiftDataFromAPI(null, 0);
      })();
    }
    const delayId = params.get("delayId");
    if (delayId) {
      actions.setDelaySelected(Number(delayId));
    }
  }, []);

  if (!state.isLoadingPage) {
    return (
      <>
        <Paper
          elevation={1}
          style={{
            width: "100%",
            padding: "15px 20px 10px",
            borderRadius: "0.75rem",
            height: "91vh",
          }}
        >
          <LazyBackDropLoading open={state.isLoadingBackground} />
          <Grid container justifyContent="center" alignItems="center">
            <Grid item md={12} xs={12} style={{ margin: 0 }}>
              <Grid item md={12} xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h5" style={{ fontWeight: 600 }}>
                    Delays Manager
                  </Typography>
                </Grid>
              </Grid>
              <Grid item md={12} xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item style={{ width: "fit-content" }}>
                    <ShiftDayNavigatorControl preventInit />
                  </Grid>
                  <Grid item xs={4} style={{ margin: 0, padding: "15px" }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={showMicroDelays}
                            color="primary"
                            onChange={(event) =>
                              setShowMicroDelays(event.target.checked)
                            }
                          />
                        }
                        label="Show microdelays"
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </Grid>
              <Divider style={{ marginBottom: 10 }} />
              <NotificationSnackBar
                message={state.notificationMessage}
                onHide={() => actions.setNotificationMessage("")}
              />
              <DelaysManagerTable
                loadingGrid={state.isLoadingGrid}
                rows={state.delays || []}
                refreshData={getDataFromAPI}
              />
              <ErrorModal error={error} onHide={() => setError("")} />
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <LazyLoading />
    </div>
  );
};
