import { ColDef } from "ag-grid-enterprise";
import {
	EditButtonCellRenderer,
	ToggleStatusButtonCellRenderer,
} from "./ButtonCellRenderer";

type Props = {
	OnEdit?: Function;
	OnSend?: Function;
};

export const useGridDefinitions = ({ OnEdit, OnSend }: Props) => {
	const columnDefaults: ColDef = {
		minWidth: 20,
		flex: 1,
		filter: true,
		floatingFilter: false,
		sortable: true,
		resizable: true,
		editable: false,
	};
	const columnSimpleDefaults: ColDef = {
		minWidth: 20,
		flex: 1,
		filter: false,
		floatingFilter: false,
		sortable: false,
		resizable: false,
		editable: false,
	};
	const columnGenericDefaults: ColDef = {
		minWidth: 20,
		filter: true,
		floatingFilter: false,
		sortable: true,
		resizable: false,
		editable: false,
	};
	const columnTypes: { [key: string]: ColDef } = {
		nonEditableColumn: { editable: false },
		nonSorteable: { sortable: false },
		nonFilterable: { filter: false },
		numericWidth: { width: 80 },
		centerAligned: {
			headerClass: "ag-center-aligned-header",
			cellClass: "ag-center-aligned-cell",
		},
		editButton: {
			field: "Edit",
			cellRenderer: EditButtonCellRenderer,
			cellRendererParams: {
				clicked: (field: any) => {
					if (OnEdit) OnEdit(field);
				},
			},
			cellClass: "ag-button-cell",
		},
		statusButton: {
			field: "Enabled",
			cellRenderer: ToggleStatusButtonCellRenderer,
			cellRendererParams: {
				isEnabled: "Enabled",
				clicked: (field: any) => {
					alert(`${field.Decimals} was clicked`);
				},
			},
			cellClass: "ag-button-cell",
		},
	};

	return {
		columnDefaults,
		columnSimpleDefaults,
		columnTypes,
		columnGenericDefaults,
	};
};
