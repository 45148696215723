import { ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { CatalogRollReducer } from "../reducer/CatalogRollReducer";

const CatalogRollContext = createContext({
  state: CatalogRollReducer.getInitialState(),
  actions: CatalogRollReducer.actions,
});

export const useCatalogRollContext = () => useContext(CatalogRollContext);

export const CatalogRollProvider = ({ children }: { children: ReactNode }) => {
  const [state, actions] = useComplexState({
    initialState: CatalogRollReducer.getInitialState(),
    reducers: CatalogRollReducer.caseReducers,
  });
  return (
    <CatalogRollContext.Provider value={{ state, actions }}>
      {children}
    </CatalogRollContext.Provider>
  );
};
