export interface CatalogGuide {
	PartId: number | null;
	PartName: string;
	RFIDTag: string | null;
	LocationName: string | null;
	GuideTypeCode: string | null;
	IsActive: boolean | null;
}

export const CATALOG_GUIDE_INITIAL_VALUES: CatalogGuide = {
	PartId: null,
	PartName: '',
	RFIDTag: '',
	LocationName: '',
	GuideTypeCode: null,
	IsActive: true
};