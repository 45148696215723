import { ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { CatalogCarrierReducer } from "../reducer/CatalogCarrierReducer";

const CatalogCarrierContext = createContext({
  state: CatalogCarrierReducer.getInitialState(),
  actions: CatalogCarrierReducer.actions,
});

export const useCatalogCarrierContext = () => useContext(CatalogCarrierContext);

export const CatalogCarrierProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [state, actions] = useComplexState({
    initialState: CatalogCarrierReducer.getInitialState(),
    reducers: CatalogCarrierReducer.caseReducers,
  });
  return (
    <CatalogCarrierContext.Provider value={{ state, actions }}>
      {children}
    </CatalogCarrierContext.Provider>
  );
};
