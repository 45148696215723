import { CatalogChockPage } from "./CatalogChockPage";
import { CatalogChockProvider } from "./context/CatalogChockContext";

const CatalogChock = () => {
  return (
    <CatalogChockProvider>
      <CatalogChockPage />
    </CatalogChockProvider>
  );
};
export default CatalogChock;
