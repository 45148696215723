import { ColDef, ICellRendererParams } from "ag-grid-enterprise";
import { GridButtons } from "../../../../../controls/buttons/GridButtons";
import { CatalogSection } from "../../../models/CatalogSection";
import { useCatalogSectionContext } from "../context/CatalogSectionContext";

type Props = {
  setSectionId: (id: number) => void;
  setShowModal: (modal: "create" | "update" | "delete" | "") => void;
  showContextMenu: Function;
};
export const useTableData = ({
  setSectionId,
  setShowModal,
  showContextMenu,
}: Props) => {
  const { state } = useCatalogSectionContext();

  const rows: CatalogSection[] =
    state.sections?.map((section: CatalogSection) => ({
      ...section,
    })) || [];

  const cellStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const columnDefs: ColDef[] = [
    {
      field: "SectionName",
      headerName: "Name",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      cellRenderer: GridButtons,
      cellRendererParams: (params: ICellRendererParams) => ({
        setId: setSectionId,
        id: (params.data as CatalogSection).SectionId,
        setShowModal: setShowModal,
        showContextMenu: () =>
          showContextMenu(event, params.data, "sectionContext"),
      }),
      wrapText: false,
      autoHeight: false,
      wrapHeaderText: false,
      headerName: "",
      sortable: false,
      flex: 1,
      minWidth: 200,
      filter: false,
      autoHeaderHeight: true,
    },
  ];
  return { rows, columnDefs };
};
