export interface CatalogRoll {
	PartId: number | null;
	PartName: string;
	RFIDTag: string | null;
	LocationName: string | null;
	Diameter: number | null;
	DonutPartId: number | null;
	DonutPartName: string | null;
	RollStatusCode: string | null;
	RollTypeCode: string | null;
	IsActive: boolean | null;
}

export const CATALOG_ROLL_INITIAL_VALUES: CatalogRoll = {
	PartId: null,
	PartName: '',
	RFIDTag: '',
	LocationName: '',
	Diameter: null,
	DonutPartId: null,
	DonutPartName: '',
	RollStatusCode: 'R',
	RollTypeCode: 'E',
	IsActive: true
};