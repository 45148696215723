import { createSlice } from "@reduxjs/toolkit";
import { CATALOG_DELAY_INITIAL_VALUES, CatalogDelayCode, DelayAreaAsset } from "../models/CatalogDelayCode";
import { TreeParameters } from "../models/TreeParameter";
const ConfigurationDelayCodeInitialState: {
	openDelays: { [delayId: number | string]: number | string };
	allDelayNodes: TreeParameters[];
	dataToCreateDelay: CatalogDelayCode;
	delayAreaAsset?: DelayAreaAsset;
	allDelayCodes: any;
	delayCodesDatasource: any;
	delayNodeSelectedInTree: CatalogDelayCode;
} = {
	openDelays: {},
	allDelayNodes: [],
	dataToCreateDelay: CATALOG_DELAY_INITIAL_VALUES,
	allDelayCodes: {},
	delayCodesDatasource: {},
	delayNodeSelectedInTree: CATALOG_DELAY_INITIAL_VALUES
};
export const ConfigurationDelayCodeReducer = createSlice({
	name: "__",
	initialState: ConfigurationDelayCodeInitialState,
	reducers: {
		setOpenDelays(state, { payload }) {
			state.openDelays = payload;
		},
		setDelayAreaAsset(state, { payload }) {
			state.delayAreaAsset = payload;
		},
		setAllDelayNodes(state, { payload }) {
			state.allDelayNodes = payload;
		},
		setDataToCreateDelay(state, { payload }) {
			state.dataToCreateDelay = payload;
		},
		setDelayNameToCreateDelay(state, { payload }) {
			state.dataToCreateDelay.DelayCodeName = payload;
		},
		setDelayParentDelayIdToCreateDelay(state, { payload }) {
			state.dataToCreateDelay.ParentDelayCodeId = payload;
		},
		setDelayCodes(state, { payload }) {
			state.delayCodesDatasource = payload;
		},
		setAllDelayCodes(state, { payload }) {
			state.allDelayCodes = payload;
		},
		setDelayNodeSelectedInTree(state, { payload }) {
			state.delayNodeSelectedInTree = payload;
		},
		setDelayCategoryToCreateDelay(state, { payload }) {
			state.dataToCreateDelay.DelayCategoryId = payload;
		},
	},
});
