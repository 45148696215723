export const tagColors: string[] = [
  "#5eaf60", // light green
  "green", // green
  "#62bd9c", // turquoise green
  "#53a285", // muted green
  "#add8e6", // pastel sky blue
  "#5293c5", // light blue
  "#3280ba", // blue
  "blue", // pure blue
  "#3d556f", // dark slate blue
  "#dab0d8", // lighter violet
  "#a362bf", // purple
  "#e74b3c", // red orange
  "red", // red
  "#bf3b2c", // dark red
  "#000000", // black
];

let availableColors: string[] = [...tagColors];
let lastColor: string | null = null;

export const getRandomColor = (): string => {
  if (availableColors.length === 0) {
    availableColors = [...tagColors];
    if (lastColor && availableColors.length > 1) {
      availableColors.splice(availableColors.indexOf(lastColor), 1);
    }
  }

  const randomIndex = Math.floor(Math.random() * availableColors.length);
  const color = availableColors[randomIndex];
  availableColors.splice(randomIndex, 1);
  lastColor = color;
  return color;
};
