import { CatalogLocation } from "../../../models/CatalogLocation";
import { TreeParameters } from "../../../models/tree/TreeParameter";
export const TransformLocationModelData = (node: CatalogLocation): TreeParameters => {
	const model: TreeParameters = {
		id: node.LocationId!,
		parent: node.ParentLocationId ? node.ParentLocationId : 0,
		text: node.LocationName,
		data: {
			fileType: "csv",
			fileSize: "",
		},
		droppable: node.IsBranch,
	};
	return model;
};
