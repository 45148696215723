import { ErrorModal } from "@dexteel/mesf-core";
import { Grid, makeStyles } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import {
  ColDef,
  FirstDataRenderedEvent,
  GridApi,
  SelectionChangedEvent,
} from "ag-grid-community";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { isNil } from "lodash-es";
import { useCallback, useEffect, useState } from "react";
import { CenteredLazyLoading } from "../../../../controls/LazyLoading";
import { Application } from "../../models/Application";
import { getApplications } from "../../repositories/ApplicationRepository";

const useStyles = makeStyles(() => ({
  table: {
    height: "41vh",
  },
}));

function simplifiedCustomSort(first: Application, second: Application) {
  const a = first.ApplicationName;
  const b = second.ApplicationName;
  // If both start with 'F', sort descendingly
  if (a.startsWith("F") && b.startsWith("F")) {
    return b.localeCompare(a);
  }
  // If both start with 'R', sort ascendingly
  if (a.startsWith("R") && b.startsWith("R")) {
    return b.localeCompare(a);
  }
  // If are differents, 'F' goes first than 'R'
  if (a.startsWith("F") && b.startsWith("R")) {
    return -1;
  }
  if (a.startsWith("R") && b.startsWith("F")) {
    return 1;
  }

  // For other cases, don't make changes
  return 0;
}

const useApplications = (partId?: number, onError?: (error: Error) => void) => {
  return useQuery<Application[], Error>({
    queryKey: ["applications", partId],
    queryFn: () => getApplications(partId),
    onError: onError,
  });
};

type Props = {
  onSelectionChanged: Function;
  disabled?: boolean;
  applicationsOfPart: Application[];
};

export const ApplicationGrid = ({
  onSelectionChanged,
  disabled = false,
  applicationsOfPart,
}: Props) => {
  const classes = useStyles();
  const [error, setError] = useState("");
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const { data: applications, isLoading } = useApplications(
    undefined,
    (error) => setError(error.message)
  );
  const sortedApplications = (applications as Application[])?.sort(
    simplifiedCustomSort
  );

  const onAgGridSelectionChanged = (event: SelectionChangedEvent) => {
    const selectedRows = event.api.getSelectedRows();
    onSelectionChanged(selectedRows);
  };

  const columnDefs: ColDef[] = [
    {
      field: "ApplicationName",
      headerName: "Applications",
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      headerCheckboxSelection: true,
      flex: 1,
    },
  ];

  useEffect(() => {
    if (isLoading) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  }, [isLoading, applicationsOfPart, gridApi]);

  useEffect(() => {
    if (gridApi) {
      gridApi.forEachNode((node) => {
        node.setSelected(
          !!node.data &&
            !isNil(
              applicationsOfPart.find(
                (p) =>
                  p.ApplicationId === (node.data as Application).ApplicationId
              )
            )
        );
      });
    }
  }, [applicationsOfPart, gridApi]);

  return (
    <>
      <Grid item md={12} xs={12} className={classes.table}>
        <div
          style={{ height: "100%", width: "100%" }}
          className="ag-theme-alpine"
        >
          <AgGridReact<Application>
            rowData={sortedApplications || []}
            columnDefs={columnDefs}
            rowHeight={22}
            headerHeight={30}
            animateRows={true}
            rowSelection="multiple"
            rowMultiSelectWithClick
            suppressContextMenu
            loadingOverlayComponent={CenteredLazyLoading}
            onSelectionChanged={onAgGridSelectionChanged}
            onGridReady={(params) => setGridApi(params.api)}
            getRowId={(params) => String(params.data.ApplicationId)}
          />
        </div>
      </Grid>
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
