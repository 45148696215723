import { createSlice } from "@reduxjs/toolkit";
import { CatalogCarrier } from "../../../../models/parts/CatalogCarrier";

const CatalogCarrierInitialState: {
	carriers: CatalogCarrier[] | null;
} = {
	carriers: null,
};

export const CatalogCarrierReducer = createSlice({
	name: "__",
	initialState: CatalogCarrierInitialState,
	reducers: {
		setCarriers(state, { payload }) {
			state.carriers = payload;
		},
	},
});
