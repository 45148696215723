import { MESFMain } from "@dexteel/mesf-core";
import "bootstrap/dist/css/bootstrap.css";
import "react-datepicker/dist/react-datepicker.css";
import { render } from "react-dom";
import "./app.css";
import "./setup/ag-grid-license";

import { AuthenticationConfig } from "./setup/auth-config";
import { customConfigurations } from "./setup/configurations";
import { CustomNavbar } from "./setup/navbar";
import { CustomRoutes } from "./setup/routes";

import {
	QueryClient,
	QueryClientProvider,
  } from '@tanstack/react-query'

const queryClient = new QueryClient()

render(
	<QueryClientProvider client={queryClient}>
		<MESFMain
			showAreaSelector={true}
			routes={CustomRoutes}
			navbarTitle={"SWV MILL"}
			navbar={CustomNavbar}
			authentication={AuthenticationConfig}
			configurations={customConfigurations}
		/>
	</QueryClientProvider>,
	document.getElementById("root"),
);
