import {
  FormControl,
  FormHelperText,
  Grid,
  GridSize,
  TextField,
} from "@material-ui/core";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

type PartFieldKeys = "RFIDTag" | "PartName" | "LocationName";

type Props<T extends FieldValues> = {
  control: Control<T>;
  disabled?: boolean;
  locationSizeMd?: GridSize;
};

export const PartFields = <T extends Record<PartFieldKeys, any>>({
  control,
  disabled = false,
  locationSizeMd = 12
}: Props<T>) => {
  return (
    <>
      <Grid item md={6} sm={12} xs={12}>
        <Controller<T>
          name={"PartName" as Path<T>}
          control={control}
          rules={{ required: "Name is required" }}
          render={({ field, fieldState: { error } }) => (
            <FormControl error={!!error} fullWidth>
              <TextField
                label="Name"
                variant="outlined"
                {...field}
                margin="dense"
                size="small"
                fullWidth
                disabled={disabled}
              />
              {error && <FormHelperText>{error.message}</FormHelperText>}
            </FormControl>
          )}
        />
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        <Controller<T>
          name={"RFIDTag" as Path<T>}
          control={control}
          render={({ field }) => (
            <TextField
              label="Tag"
              variant="outlined"
              {...field}
              margin="dense"
              size="small"
              fullWidth
              disabled
            />
          )}
        />
      </Grid>
      <Grid item md={locationSizeMd} sm={12} xs={12} style={{marginTop: -8}}>
        <Controller<T>
          name={"LocationName" as Path<T>}
          control={control}
          render={({ field }) => (
            <TextField
              label="Location Name"
              variant="outlined"
              {...field}
              fullWidth
              margin="dense"
              size="small"
              autoComplete="off"
              disabled
            />
          )}
        />
      </Grid>
    </>
  );
};
