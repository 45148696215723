import { ErrorModal, MesfModal } from "@dexteel/mesf-core";
import {
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from "@material-ui/core";
import { get } from "lodash-es";
import { ChangeEvent, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { FooterModalButtons } from "../../../../../controls/buttons/FooterModalButtons";
import { CenteredLazyLoading } from "../../../../../controls/LazyLoading";
import { NotificationSnackBar } from "../../../../../controls/snackbars/notification-snackbar";

import {
  CATALOG_BARREL_INITIAL_VALUES,
  CatalogBarrel,
} from "../../../models/CatalogBarrel";
import {
  deleteBarrel,
  getBarrels,
  upsertBarrel,
} from "../../../repositories/CatalogBarrelRepository";
import { useModalStyles } from "../../../styles/modalStyles";

type Props = {
  BarrelId: number | null;
  modal: "create" | "update" | "delete" | "";
  onHide: (shouldUpdate: boolean) => void;
};
export const UpsertDeleteModal = ({ BarrelId, modal, onHide }: Props) => {
  const classes = useModalStyles();

  const [title, setTitle] = useState<string>("CREATE");

  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const succesfullyMessage = `The barrel was ${modal}d succesfully`;

  const { control, getValues, setValue, reset, handleSubmit } =
    useForm<CatalogBarrel>({
      defaultValues: CATALOG_BARREL_INITIAL_VALUES,
    });

  const onSubmit: SubmitHandler<CatalogBarrel> = async (data) => {
    setIsSubmitLoading(true);
    if (modal === "delete") {
      const barrelResp = await deleteBarrel(data.BarrelId);
      if (barrelResp.ok) {
        setNotificationMessage(succesfullyMessage);
        onHide(true);
      } else {
        setError(barrelResp.message);
      }
    } else {
      const barrelResp = await upsertBarrel(data);
      if (barrelResp.ok) {
        setNotificationMessage(succesfullyMessage);
        onHide(true);
      } else {
        setError(barrelResp.message);
      }
    }
    setIsSubmitLoading(false);
  };

  const onChangeRealType = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (value: string) => void
  ) => {
    const value = e.target.value.replace(",", ".");
    if (value === "" || /^(\d*\.?\d{0,2}|\d+\.)$/.test(value)) {
      onChange(value);
    }
  };
  const onBlurRealType = (onChange: (value: string) => void, value: string) => {
    const numValue = parseFloat(value);
    if (!isNaN(numValue)) {
      onChange(numValue.toFixed(1));
    } else if (value === "") {
      onChange("");
    }
  };

  useEffect(() => {
    if (modal !== "") {
      setIsSubmitLoading(false);
      reset();
      setTitle(
        modal === "create" ? "CREATE" : modal === "update" ? "EDIT" : "DELETE"
      );
      if (modal !== "create")
        (async () => {
          setIsLoading(true);
          const response = await getBarrels(BarrelId);
          if (response.ok) {
            const barrel = get(
              response,
              "data.tables[0].rows[0]",
              null
            ) as CatalogBarrel;
            if (barrel) {
              setValue("BarrelId", barrel?.BarrelId);
              setValue("BarrelName", barrel?.BarrelName);
              setValue("BottomDiameter", barrel?.BottomDiameter);
              setValue("BottomDiameterMin", barrel?.BottomDiameterMin);
              setValue("TopDiameter", barrel?.TopDiameter);
              setValue("TopDiameterMin", barrel?.TopDiameterMin);
              setValue("Comments", barrel?.Comments);
            }
          } else {
            setError(response.message);
            return;
          }
          setIsLoading(false);
        })();
    }
  }, [modal]);

  return (
    <>
      <MesfModal
        title={`${title} BARREL`}
        open={modal !== ""}
        handleClose={() => onHide(false)}
        maxWidth="sm"
        id="barrel-modal"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <MesfModal.Content dividers style={{ padding: "10px 30px" }}>
            {isLoading && <CenteredLazyLoading />}
            {!isLoading && (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Controller
                      name="BarrelName"
                      control={control}
                      rules={{ required: "Barrel is required" }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormControl error={!!error} fullWidth>
                          <TextField
                            label="Name"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            autoComplete="off"
                            value={value}
                            disabled={modal === "delete"}
                            onChange={onChange}
                          />
                          {error && (
                            <FormHelperText>{error.message}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="BottomDiameter"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormControl error={!!error} fullWidth>
                          <TextField
                            label="Bottom Diameter"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            autoComplete="off"
                            value={value}
                            disabled={modal === "delete"}
                            onChange={(e) => onChangeRealType(e, onChange)}
                            onBlur={() =>
                              onBlurRealType(onChange, String(value))
                            }
                          />
                          {error && (
                            <FormHelperText>{error.message}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="TopDiameter"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormControl error={!!error} fullWidth>
                          <TextField
                            label="Top Diameter"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            autoComplete="off"
                            value={value}
                            disabled={modal === "delete"}
                            onChange={(e) => onChangeRealType(e, onChange)}
                            onBlur={() =>
                              onBlurRealType(onChange, String(value))
                            }
                          />
                          {error && (
                            <FormHelperText>{error.message}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="BottomDiameterMin"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormControl error={!!error} fullWidth>
                          <TextField
                            label="Bottom Diameter Min"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            autoComplete="off"
                            value={value}
                            disabled={modal === "delete"}
                            onChange={(e) => onChangeRealType(e, onChange)}
                            onBlur={() =>
                              onBlurRealType(onChange, String(value))
                            }
                          />
                          {error && (
                            <FormHelperText>{error.message}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="TopDiameterMin"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormControl error={!!error} fullWidth>
                          <TextField
                            label="Top Diameter Min"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            autoComplete="off"
                            value={value}
                            disabled={modal === "delete"}
                            onChange={(e) => onChangeRealType(e, onChange)}
                            onBlur={() =>
                              onBlurRealType(onChange, String(value))
                            }
                          />
                          {error && (
                            <FormHelperText>{error.message}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item md={12} sm={12}>
                    <Controller
                      name="Comments"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <FormControl error={!!error} fullWidth>
                            <TextField
                              label="Comments"
                              variant="outlined"
                              value={field?.value}
                              fullWidth
                              maxRows={5}
                              multiline
                              margin="dense"
                              autoComplete="off"
                              disabled={modal === "delete"}
                              onChange={(event) => {
                                if (event.target.value.length <= 200) {
                                  field.onChange(event.target.value);
                                }
                              }}
                            />
                            {error && (
                              <FormHelperText>{error.message}</FormHelperText>
                            )}
                          </FormControl>
                          {(getValues("Comments")?.length as number) !==
                            200 && (
                            <span
                              className={classes.infoLabel}
                              style={{ marginLeft: 5 }}
                            >
                              {`${getValues("Comments")?.length ?? 0}/200 max.`}
                            </span>
                          )}
                          {(getValues("Comments")?.length as number) == 200 && (
                            <span
                              className={classes.infoLabel}
                              style={{ marginLeft: 5 }}
                            >
                              Max. 200
                            </span>
                          )}
                        </>
                      )}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </MesfModal.Content>
          <MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
            <FooterModalButtons
              isSubmitLoading={isSubmitLoading}
              onHide={onHide}
              buttonLabel={modal === "delete" ? "Delete" : "Save"}
              buttonColor={modal === "delete" ? "secondary" : "primary"}
            />
          </MesfModal.Actions>
        </form>
      </MesfModal>
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error In Barrel"
      />
    </>
  );
};
