import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { CatalogBarrel } from "../models/CatalogBarrel";

export const getBarrels = async (barrelId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (barrelId)
		parameters.push({ name: "BarrelId", value: barrelId });

	return await apiService.callV2("[M2].[GetBarrels]", parameters);
};
export const upsertBarrel = async (barrel: CatalogBarrel) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "BarrelId", value: barrel.BarrelId },
		{ name: "BarrelName", value: barrel.BarrelName },
		{ name: "BottomDiameter", value: barrel.BottomDiameter },
		{ name: "BottomDiameterMin", value: barrel.BottomDiameterMin },
		{ name: "TopDiameter", value: barrel.TopDiameter },
		{ name: "TopDiameterMin", value: barrel.TopDiameterMin },
		{ name: "Comments", value: barrel.Comments },
	];

	return await apiService.callV2("[M2].[UpsertBarrel]", parameters);
};

export const deleteBarrel = async (barrelId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "BarrelId", value: barrelId }
	];

	return await apiService.callV2("[M2].[DeleteBarrel]", parameters);
};