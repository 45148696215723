import { ErrorModal, useContextMenuMESF } from "@dexteel/mesf-core";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { Tree } from "@minoru/react-dnd-treeview";
import {
  DragLayerMonitorProps,
  NodeModel,
} from "@minoru/react-dnd-treeview/dist/types";
import React, { useEffect, useMemo, useState } from "react";

import { FooterButton } from "../../../../../controls/buttons/FooterButton";
import { CenteredLazyLoading } from "../../../../../controls/LazyLoading";
import { NotificationSnackBar } from "../../../../../controls/snackbars/notification-snackbar";

import { CatalogLocation } from "../../../models/CatalogLocation";
import { FileProperties } from "../../../models/tree/FileProperties";
import { TreeParameters } from "../../../models/tree/TreeParameter";
import { useCatalogLocationContext } from "../context/CatalogLocationContext";
import { useLocationOptionsFunctions } from "../hooks/useLocationOptionsFunctions";
import { useSearchLocations } from "../hooks/useSearchLocations";
import { CustomNode } from "./CustomNode";
import { CustomDragPreview } from "./DragPreview/CustomDragPreview";
import { MultipleDragPreview } from "./DragPreview/MultipleDragPreview";
import { UpsertDeleteViewLocation } from "./UpsertDeleteViewLocation";

const useStyles = makeStyles(() => ({
  root: {},
  storyRoot: {
    display: "grid",
    height: "100%",
    gridTemplateRows: "auto 1fr",
    listStyleType: "none !important",
  },
  assetRoot: {
    boxSizing: "border-box",
    height: "100%",
    padding: "32px",
  },
  btnModal: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 20,
  },
  dropTarget: {
    boxShadow: "0 0 0 1px #1967d2 inset",
  },
}));
export const TreeViewLocation = () => {
  const classes = useStyles();

  //const [isCtrlPressing, setIsCtrlPressing] = useState(false);
  const [showModal, setShowModal] = useState<
    "create" | "update" | "delete" | "view" | ""
  >("");
  const [selectedNodes, setSelectedNodes] = useState<
    NodeModel<FileProperties>[]
  >([]);
  const [isDragging, setIsDragging] = useState(false);
  const [error, setError] = useState<string>("");
  const [errorUpdate, setErrorUpdate] = useState<string>("");
  const [searchError, setSearchError] = useState<string>("");
  const [updatedSuccessfullyMessage, setUpdatedSuccessfullyMessage] =
    useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [locationId, setLocationId] = useState<number | null>();

  const { searchLocations } = useSearchLocations({
    setSearchError,
    setAllLocationLoading: setIsLoading,
  });

  const {
    state: { allLocationNodes, allLocations, openLocations },
    actions: { setLocationNodeSelectedInTree },
  } = useCatalogLocationContext();

  const { showContextMenu, registerConfig } = useContextMenuMESF();
  const { getMenuOptions } = useLocationOptionsFunctions({
    setLocationId,
    setShowModal,
  });

  // In the future, this would be the drag and drop functionality
  /* const { handleDrop, handleCtrlClick, handleDragStart, handleDragEnd } =
    useLocationActions({
      location: allLocationNodes,
      selectedNodes,
      setSelectedNodes,
      setIsDragging,
      isCtrlPressing,
      setIsCtrlPressing,
      onLocationUpdateStart: () => {
        setIsLoading(true);
      },
      onLocationUpdateEnd: (result) => {
        if (result.ok) {
          setUpdatedSuccessfullyMessage(true);
          searchLocations();
        } else {
          setErrorUpdate(result.message);
        }
      },
    }); */

  const handleContextMenu = (
    data: NodeModel<FileProperties>,
    e: React.MouseEvent<HTMLElement>
  ) => {
    e.preventDefault();
    if (e.type === "contextmenu") {
      setSelectedNodes([data]);
      showContextMenu(e, data, "locationContext");
      const selectedNode = allLocations.find(
        (node: CatalogLocation) => node.LocationId === data.id
      );
      if (selectedNode) {
        setLocationNodeSelectedInTree(selectedNode);
      }
    }
  };

  const onNodeDoubleClicked = (
    data: NodeModel<FileProperties>,
    e: React.MouseEvent<HTMLElement>
  ) => {
    e.preventDefault();
    setLocationId((data as TreeParameters).id);
    setShowModal("view");
  };

  const onModalHide = (shouldUpdate: boolean) => {
    if (shouldUpdate) searchLocations();
    setShowModal("");
  };

  const openLocationsArray = useMemo(() => {
    return Object.keys(openLocations).map((el) => parseInt(el));
  }, [openLocations]);

  useEffect(() => {
    searchLocations();
    registerConfig({
      id: "locationContext",
      getOptions: getMenuOptions,
    });
  }, []);

  if (!isLoading)
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.root}
        >
          <Paper
            elevation={1}
            style={{
              width: "100%",
              padding: "15px 20px 25px",
              borderRadius: "0.75rem",
            }}
          >
            <div
              onContextMenu={(e) => {
                e.preventDefault();
                showContextMenu(e, null, "locationContext");
              }}
            >
              <Typography
                variant="h5"
                style={{ margin: "20px 0 20px", fontWeight: 600 }}
              >
                Locations Tree
              </Typography>
              {isLoading ? (
                <CenteredLazyLoading />
              ) : (
                <Paper
                  elevation={2}
                  style={{
                    width: "100%",
                    padding: "15px 20px 10px",
                    borderRadius: "0.75rem",
                    marginBottom: 15,
                  }}
                >
                  <Grid container alignItems="center">
                    <Grid
                      container
                      style={{
                        height: "60vh",
                        overflowY: "auto",
                        paddingBottom: 10,
                      }}
                    >
                      <Grid>
                        <Tree
                          rootId={0}
                          initialOpen={openLocationsArray}
                          tree={allLocationNodes}
                          enableAnimateExpand={true}
                          classes={{
                            root: classes.assetRoot,
                            dropTarget: classes.dropTarget,
                          }}
                          onDrop={() => {}}
                          render={(node, options) => {
                            const selected = selectedNodes.some(
                              (selectedNode) => selectedNode.id === node.id
                            );
                            return (
                              <CustomNode
                                setContextMenuOver={handleContextMenu}
                                node={node}
                                {...options}
                                isSelected={selected}
                                isDragging={selected && isDragging}
                                onClick={() => {}}
                                onDoubleClick={onNodeDoubleClicked}
                              />
                            );
                          }}
                          dragPreviewRender={(
                            monitorProps: DragLayerMonitorProps<FileProperties>
                          ) => {
                            if (selectedNodes.length > 1) {
                              return (
                                <MultipleDragPreview
                                  dragSources={selectedNodes}
                                />
                              );
                            }
                            return (
                              <CustomDragPreview monitorProps={monitorProps} />
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              )}
            </div>
            <FooterButton
              label="LOCATION"
              showCreateModal={showModal === "create"}
              setShowCreateModal={(showCreateModal) =>
                showCreateModal ? setShowModal("create") : setShowModal("")
              }
            />
          </Paper>
        </Grid>
        <NotificationSnackBar
          message={updatedSuccessfullyMessage}
          onHide={() => setUpdatedSuccessfullyMessage("")}
        />
        <UpsertDeleteViewLocation
          LocationId={locationId}
          show={showModal !== ""}
          modal={showModal}
          onHide={onModalHide}
        />
        <ErrorModal error={errorUpdate} onHide={() => setErrorUpdate("")} />
        <ErrorModal error={error} onHide={() => setError("")} />
        <ErrorModal error={searchError} onHide={() => setSearchError("")} />
      </div>
    );

  return <CenteredLazyLoading />;
};
