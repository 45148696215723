import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { useCatalogCategoryContext } from "../../context/CategoriesContext";
import { Asset } from "@dexteel/mesf-core/dist/models/Asset";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiSelect-root": {
      backgroundColor: "#FFF",
    },
  },
  inputLabel: {
    position: "absolute",
    top: -6,
    left: 8,
    fontSize: 11.3,
    backgroundColor: "#FFF",
    color: "#0000008A",
    width: "auto",
    height: "auto",
    zIndex: 100,
    padding: "0 3px",
    borderRadius: 3,
    fontWeight: 400,
    lineHeight: "1.1876em",
    letterSpacing: "0.04208em",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  },
}));

export const TypesFilter = () => {
  const classes = useStyles();
  const {
    state: { assetCodes, assetIdToFilter },
    actions: { setAssetIdToFilter },
  } = useCatalogCategoryContext();

  const handleFilter = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValue = event.target.value;
    setAssetIdToFilter(selectedValue);
  };

  return (
    <div className={classes.root} style={{ position: "relative" }}>
      <p className={classes.inputLabel}>Assets</p>
      <Select
        variant="outlined"
        margin="dense"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={assetIdToFilter}
        onChange={handleFilter}
        style={{ minWidth: "100%" }}
      >
        <MenuItem style={{ fontWeight: "bold" }} value={0}>
          <em>All</em>
        </MenuItem>
        {assetCodes?.map((asset: Asset) => (
          <MenuItem key={`logId-${asset.AssetId}`} value={asset.AssetId}>
            <Grid container alignItems="center">
              {asset.AssetName}
            </Grid>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
