export interface CatalogChock {
	PartId: number | null;
	PartName: string;
	RFIDTag: string | null;
	LocationName: string | null;
	RebuildTimestamp: Date | null;
	BearingChangeTimestamp: Date | null;
	IsActive: boolean | null;
}

export const CATALOG_CHOCK_INITIAL_VALUES: CatalogChock = {
	PartId: null,
	PartName: '',
	RFIDTag: '',
	LocationName: '',
	RebuildTimestamp: null,
	BearingChangeTimestamp: null,
	IsActive: true
};