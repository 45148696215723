import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { MenuOptionType } from "../../../../../controls/contextMenu/models/MenuOptionType";
import { TreeParameters } from "../../../models/tree/TreeParameter";
type Props = {
  setLocationId: Function;
  setShowModal: (modal: "create" | "update" | "delete" | "view" | "") => void;
};
export const useLocationOptionsFunctions = ({
  setLocationId,
  setShowModal,
}: Props) => {
  const getMenuOptions = (data: TreeParameters) => {
    let options: MenuOptionType[] = [];
    options = options.concat([
      {
        name: "New Location",
        key: "new_location",
        onClick: () => {
          setShowModal("create");
        },
        icon: <PlaylistAddIcon />,
      },
    ]);
    if (data) {
      options = options.concat([
        {
          name: "Edit Location",
          key: "edit_location",
          onClick: () => {
            setLocationId(data.id);
            setShowModal("update");
          },
          icon: <EditIcon />,
        },
        {
          name: "View Location",
          key: "view_location",
          onClick: () => {
            setLocationId(data.id);
            setShowModal("view");
          },
          icon: <FindInPageIcon />,
        },
      ]);
      if (!data.droppable) {
        options = options.concat([
          {
            name: "Delete Location",
            key: "delete_location",
            onClick: () => {
              setLocationId(data.id);
              setShowModal("delete");
            },
            icon: <DeleteIcon />,
          },
        ]);
      }
    }
    return options;
  };
  return {
    getMenuOptions,
  };
};
