export interface CatalogLocation {
	LocationId: number | null;
 	ParentLocationId: number | null;
	LocationName: string;
	LocationTypeCode: string;
	IsBranch: boolean;
	IsExclusive: boolean;
	IsActive: boolean | null;
}

export const CATALOG_LOCATION_INITIAL_VALUES: CatalogLocation = {
	LocationId: null,
	ParentLocationId: null,
	LocationName: '',
	LocationTypeCode: 'M',
	IsBranch: false,
	IsExclusive: false,
	IsActive: true
};