export interface CatalogCartridge {
	PartId: number | null;
	PartName: string;
	RFIDTag: string | null;
	LocationName: string | null;
	RivCartridgeChockId: number | null;
	RivCartridgeChockName: string | null;
	AveCartridgeChockId: number | null;
	AveCartridgeChockName: string | null;
	IsActive: boolean | null;
}

export const CATALOG_CARTRIDGE_INITIAL_VALUES: CatalogCartridge = {
	PartId: null,
	PartName: '',
	RFIDTag: '',
	LocationName: '',
	RivCartridgeChockId: null,
	RivCartridgeChockName: null,
	AveCartridgeChockId: null,
	AveCartridgeChockName: null,
	IsActive: true
};