import { createSlice } from "@reduxjs/toolkit";
import { CatalogChock } from "../../../../models/parts/CatalogChock";

const CatalogChockInitialState: {
	chocks: CatalogChock[] | null;
} = {
	chocks: null,
};

export const CatalogChockReducer = createSlice({
	name: "__",
	initialState: CatalogChockInitialState,
	reducers: {
		setChocks(state, { payload }) {
			state.chocks = payload;
		},
	},
});
