import { useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { GridApi, RowClassParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { ErrorModal } from "@dexteel/mesf-core";

import { useDelaysManagerContext } from "../context/DelaysManagerContext";
import { DelayByShift } from "../models/DelayByShift";
import { useGridStyles } from "../styles/gridStyles";
import { useTableData } from "../hooks/hooks";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    width: "100%",
    height: "calc(100% - 20px)",
    "& .ag-root-wrapper": {
      border: "none",
    },
    "& .ag-header": {
      borderTop: "1px solid #e0e0e0",
    },
  },
  gridWrapper: {
    border: "4px solid #ccc",
    borderRadius: 10,
    padding: 10,
    height: "calc(100vh - 300px)",
    minHeight: "calc(100vh - 300px)",
    width: "100%",
    margin: "20px 0 10px",
  },
}));

type Props = {
  loadingGrid: boolean;
  rows: DelayByShift[];
};

export const DelaysReportTable = ({ loadingGrid, rows }: Props) => {
  const classes = useStyles();
  const gridStyles = useGridStyles();
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const { columns } = useTableData({});

  const {
    state: { errorMessage },
    actions: { setErrorMessage },
  } = useDelaysManagerContext();

  const getRowStyle = (params: RowClassParams) => {
    if (!params.data.IsActive) {
      return { background: "#E8EBEB" };
    }
  };

  useEffect(() => {
    if (loadingGrid) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  }, [loadingGrid, rows, gridApi]);

  return (
    <div onContextMenu={(e) => e.preventDefault()}>
      <div className={classes.tableContainer}>
        <Grid container className={gridStyles.root}>
          <Grid
            item
            md={12}
            xs={12}
            style={{
              border: "4px solid #ccc",
              borderRadius: 10,
              padding: 10,
              height: "calc(100vh - 300px)",
              minHeight: "calc(100vh - 300px)",
              margin: "20px 0 10px",
            }}
            className={"ag-theme-balham"}
          >
            <AgGridReact
              onGridReady={(params) => setGridApi(params.api)}
              rowSelection="single"
              pagination={false}
              animateRows={true}
              rowHeight={35}
              headerHeight={35}
              singleClickEdit={true}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              rowData={rows}
              columnDefs={columns}
              getRowStyle={getRowStyle}
            />
          </Grid>
        </Grid>
      </div>
      <ErrorModal error={errorMessage} onHide={() => setErrorMessage("")} />
    </div>
  );
};
