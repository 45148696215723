import { MenuOptionType } from "@dexteel/mesf-core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";

import { CatalogGuide } from "../../../../models/parts/CatalogGuide";

type Props = {
  setSelectedRowData: (guide: CatalogGuide) => void;
  setShowModal: (modal: "create" | "update" | "delete" | "") => void;
};
export const useGuideOptionsFunctions = ({
  setSelectedRowData,
  setShowModal,
}: Props) => {
  const getMenuOptions = (data: CatalogGuide) => {
    let options: MenuOptionType[] = [];
    options = options.concat([
      {
        name: "New Guide",
        key: "new_guide",
        onClick: () => {
          setShowModal("create");
        },
        icon: <PlaylistAddIcon />,
      },
    ]);
    if (data) {
      options = options.concat([
        {
          name: "Edit Guide",
          key: "edit_guide",
          onClick: () => {
            setSelectedRowData(data);
            setShowModal("update");
          },
          icon: <EditIcon />,
        },
        {
          name: "Delete Guide",
          key: "delete_guide",
          onClick: () => {
            setSelectedRowData(data);
            setShowModal("delete");
          },
          icon: <DeleteIcon />,
        },
      ]);
    }
    return options;
  };
  return {
    getMenuOptions,
  };
};
