import { ErrorModal } from "@dexteel/mesf-core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { get } from "lodash-es";
import { useEffect, useState } from "react";

import { getBarrels } from "../../repositories/CatalogBarrelRepository";
import { usePageStyles } from "../../styles/pageStyles";
import { TableBarrel } from "./components/TableBarrel";
import { useCatalogBarrelContext } from "./context/CatalogBarrelContext";

export const CatalogBarrelPage = () => {
  const classes = usePageStyles();
  const {
    actions: { setBarrels },
  } = useCatalogBarrelContext();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  const getBarrelsFromAPI = async () => {
    setIsLoading(true);
    const resp = await getBarrels();
    if (resp.ok) {
      const barrels = get(resp, "data.tables[0].rows", []);
      setBarrels(barrels);
    } else {
      setError(resp.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getBarrelsFromAPI();
  }, []);

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={12} xs={12} style={{ margin: 0 }}>
          <Paper elevation={1} className={classes.paper}>
            <TableBarrel
              isLoading={isLoading}
              getCatalogBarrelsFromAPI={getBarrelsFromAPI}
            />
          </Paper>
        </Grid>
      </Grid>
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
