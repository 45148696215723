import { Grid, IconButton, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ListIcon from "@material-ui/icons/List";
import Comment from "@material-ui/icons/Comment";
import { ICellRendererParams } from "ag-grid-community";
const useStyles = makeStyles(() => ({
  buttons: {
    opacity: 0,
    transition: "opacity 0.1s ease-in-out",
    ".ag-row-hover &": {
      opacity: 1,
    },
    ".ag-row-focus &": {
      opacity: 1,
    },
    ".ag-row-selected &": {
      opacity: 1,
    },
  },
}));
type Props = {
  params: ICellRendererParams;
  justifyData: (params: ICellRendererParams) => void;
  deleteData: (params: ICellRendererParams) => void;
  showContextMenu: () => void;
};
export const GridButtons = ({
  params,
  justifyData,
  deleteData,
  showContextMenu,
}: Props) => {
  const classes = useStyles();
  return (
    <Grid
      container
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className={classes.buttons}
    >
      <Grid
        item
        xs={12}
        md={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IconButton
            aria-label="justify-icon"
            title="Justify"
            style={{ cursor: "pointer" }}
            onClick={() => justifyData(params.data)}
          >
          <Comment
            fontSize="inherit"
            color="primary"
            style={{ cursor: "pointer" }}
          />
        </IconButton>
        {params.data.IsDelayManual && (
          <IconButton
            aria-label="delete-icon"
            style={{ cursor: "pointer" }}
            title="Delete"
            onClick={() => {
              deleteData(params.data);
            }}
          >
            <DeleteIcon
              fontSize="inherit"
              color="secondary"
              style={{ cursor: "pointer" }}
            />
          </IconButton>
        )}
        <IconButton
          aria-label="Options"
          title="Options"
          style={{ cursor: "pointer" }}
          onClick={showContextMenu}
        >
          <ListIcon
            fontSize="inherit"
            color="primary"
            style={{ cursor: "pointer" }}
          />
        </IconButton>
      </Grid>
    </Grid>
  );
};
