import React, { useEffect, useState, useRef } from "react";
import { Moment } from "moment-timezone";
import { get } from "lodash-es";
import {
  Button,
  Divider,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import { ErrorModal, getMomentTz } from "@dexteel/mesf-core";

import { useDelaysManagerContext } from "./context/DelaysManagerContext";
import {
  LazyBackDropLoading,
  LazyLoading,
} from "../../../controls/LazyLoading";
import { NotificationSnackBar } from "../../../controls/snackbars/notification-snackbar";
import { DelaysReportTable } from "./components/DelaysReportTable";
import { Filters } from "./components/filters";
import {
  getDelays,
  getDelayAreas,
} from "./repositories/DelaysReportRepository";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "15px 20px 10px",
    borderRadius: "0.75rem",
    height: "91vh",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  filterContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginBottom: theme.spacing(2),
  },
  filterGroup: {
    display: "flex",
    alignItems: "flex-end",
  },
  buttonGroup: {
    display: "flex",
    gap: theme.spacing(2),
  },
  button: {
    whiteSpace: "nowrap",
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
    width: "100%",
  },
  tableContainer: {
    width: "100%",
    height: "calc(100% - 20px)",
    padding: "2px",
    "& .ag-root-wrapper": {
      border: "none",
    },
    "& .ag-header": {
      borderTop: "1px solid #e0e0e0",
    },
  },
}));

const moment = getMomentTz();

export const DelaysReportPage: React.FC = () => {
  const classes = useStyles();
  const { state, actions } = useDelaysManagerContext();
  const [error, setError] = useState("");
  const [filterStartDate, setFilterStartDate] = useState<Moment>(
    moment().startOf("day")
  );
  const [filterEndDate, setFilterEndDate] = useState<Moment>(
    moment().endOf("day")
  );
  const [selectedDelayArea, setSelectedDelayArea] = useState<number | null>(
    null
  );
  const [delayAreas, setDelayAreas] = useState<
    Array<{ AssetId: number; AssetName: string }>
  >([]);
  const [includeMicrodelays, setIncludeMicrodelays] = useState<boolean>(false);
  const gridRef = useRef<AgGridReact>(null);

  useEffect(() => {
    fetchDelayAreas();
  }, []);

  const fetchDelayAreas = async () => {
    const resp = await getDelayAreas();
    if (resp.ok) {
      const areas = get(resp, "data.tables[0].rows", []);
      setDelayAreas(areas);
    } else {
      setError(resp.message);
    }
  };

  const getDataFromAPI = async () => {
    if (!filterStartDate || !filterEndDate) {
      setError("Please select both start and end dates.");
      return;
    }

    actions.setIsLoadingGrid(true);
    const resp = await getDelays(
      filterStartDate.toDate(),
      filterEndDate.toDate(),
      selectedDelayArea,
      includeMicrodelays
    );
    if (resp.ok) {
      const delays = get(resp, "data", []);
      actions.setDelays(delays);
    } else {
      setError(resp.message);
    }
    actions.setIsLoadingGrid(false);
  };

  const handleFilterChange = (startDate: Moment, endDate: Moment) => {
    setFilterStartDate(startDate);
    setFilterEndDate(endDate);
  };

  const handleDelayAreaChange = (assetId: number | null) => {
    setSelectedDelayArea(assetId);
  };

  const handleIncludeMicrodelaysChange = (include: boolean) => {
    setIncludeMicrodelays(include);
  };

  const handleSearch = () => {
    getDataFromAPI();
  };

  const handleExportToExcel = () => {
    if (gridRef.current && gridRef.current.api) {
      const params = {
        skipHeader: false,
        skipFooters: true,
        skipGroups: true,
        fileName: "delays_report.xlsx",
      };
      gridRef.current.api.exportDataAsExcel(params);
    } else {
      setError("Unable to export data. Please try again.");
    }
  };

  if (!state.isLoadingPage) {
    return (
      <Paper elevation={1} className={classes.root}>
        <LazyBackDropLoading open={state.isLoadingBackground} />
        <Typography
          variant="h5"
          className={classes.header}
          style={{ fontWeight: 600 }}
        >
          Delays Report
        </Typography>
        <div className={classes.filterContainer}>
          <div className={classes.filterGroup}>
            <Filters
              onFilterChange={handleFilterChange}
              delayAreas={delayAreas}
              selectedDelayArea={selectedDelayArea}
              onDelayAreaChange={handleDelayAreaChange}
              initialStartDate={filterStartDate}
              initialEndDate={filterEndDate}
              includeMicrodelays={includeMicrodelays}
              onIncludeMicrodelaysChange={handleIncludeMicrodelaysChange}
            />
          </div>
          <div className={classes.buttonGroup}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearch}
              className={classes.button}
            >
              Search
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleExportToExcel}
              disabled={!state.delays || state.delays.length === 0}
              className={classes.button}
            >
              Export to Excel
            </Button>
          </div>
        </div>
        <Divider style={{ marginBottom: 10 }} />
        <div className={classes.tableContainer}>
          <ErrorModal
            error={state.errorMessage || error}
            onHide={() => {
              actions.setErrorMessage("");
              setError("");
            }}
          />
          <NotificationSnackBar
            message={state.notificationMessage}
            onHide={() => actions.setNotificationMessage("")}
          />
          <DelaysReportTable
            loadingGrid={state.isLoadingGrid}
            rows={state.delays || []}
          />
        </div>
      </Paper>
    );
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <LazyLoading />
    </div>
  );
};
