import { ErrorModal } from "@dexteel/mesf-core";
import { CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { CatalogGuide } from "../../../../../models/parts/CatalogGuide";
import { getGuidesV2 } from "../../../../../repositories/parts/CatalogGuideRepository";

type Props = {
  label: string;
  typeCode: string;
  value: number | null;
  onChange: Function;
  modal: "create" | "update" | "delete" | "";
};

const useGuides = (partId?: number, onError?: (error: Error) => void) => {
  return useQuery<CatalogGuide[], Error>({
    queryKey: ["guides", partId],
    queryFn: () => getGuidesV2(partId),
    onError: onError,
  });
};

export const GuideField = ({
  label,
  typeCode,
  value,
  onChange,
  modal,
}: Props) => {
  const [error, setError] = useState("");

  const { data: guides, isLoading } = useGuides(undefined, (error) =>
    setError(error.message)
  );

  const selectedValue = guides?.find((guide) => guide.PartId === value);

  return (
    <>
      <Autocomplete
        id="clear-on-escape"
        clearOnEscape
        options={
          guides?.filter((guide) => guide.GuideTypeCode === typeCode) || []
        }
        getOptionLabel={(option) => option.PartName}
        disabled={modal === "delete"}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </>
              ),
            }}
          />
        )}
        value={selectedValue}
        onChange={(event, newValue) => {
          onChange(newValue?.PartId);
        }}
      />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error loading guides"
      />
    </>
  );
};
