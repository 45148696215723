import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { CatalogPassShape } from "../models/CatalogPassShape";

export const getPassShapes = async (passShapeId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (passShapeId)
		parameters.push({ name: "PassShapeId", value: passShapeId });

	return await apiService.callV2("[M2].[GetPassShapes]", parameters);
};
export const upsertPassShape = async (passShape: CatalogPassShape) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PassShapeId", value: passShape.PassShapeId },
		{ name: "PassShapeName", value: passShape.PassShapeName }
	];

	return await apiService.callV2("[M2].[UpsertPassShape]", parameters);
};

export const deletePassShape = async (passShapeId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PassShapeId", value: passShapeId }
	];

	return await apiService.callV2("[M2].[DeletePassShape]", parameters);
};