import { ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { CatalogChockReducer } from "../reducer/CatalogChockReducer";

const CatalogChockContext = createContext({
  state: CatalogChockReducer.getInitialState(),
  actions: CatalogChockReducer.actions,
});

export const useCatalogChockContext = () => useContext(CatalogChockContext);

export const CatalogChockProvider = ({ children }: { children: ReactNode }) => {
  const [state, actions] = useComplexState({
    initialState: CatalogChockReducer.getInitialState(),
    reducers: CatalogChockReducer.caseReducers,
  });
  return (
    <CatalogChockContext.Provider value={{ state, actions }}>
      {children}
    </CatalogChockContext.Provider>
  );
};
