import { Grid, TextField, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { ErrorModal } from "@dexteel/mesf-core";
import { Autocomplete } from "@material-ui/lab";
import { get } from "lodash-es";
import { FooterModalButtons } from "../../../../../../controls/buttons/FooterModalButtons";
import { CenteredLazyLoading } from "../../../../../../controls/LazyLoading";
import { NotificationSnackBar } from "../../../../../../controls/snackbars/notification-snackbar";
import { Application } from "../../../../models/Application";
import {
  getApplicationsByPart,
  setApplicationsToPart,
} from "../../../../repositories/ApplicationRepository";
import {
  CATALOG_GUIDE_INITIAL_VALUES,
  CatalogGuide,
} from "../../../../models/parts/CatalogGuide";
import {
  deleteGuide,
  upsertGuide,
} from "../../../../repositories/parts/CatalogGuideRepository";
import { ApplicationGrid } from "../../ApplicationGrid";
import { PartFields } from "../../PartFields";

type Props = {
  guide: CatalogGuide | null;
  modal: "create" | "update" | "delete" | "";
  onHide: (shouldUpdate: boolean) => void;
};
export const GuideDetails = ({ guide, modal, onHide }: Props) => {
  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const [applicationsOfPart, setApplicationsOfPart] = useState<Application[]>(
    []
  );
  const [applicationsSelected, setApplicationsSelected] = useState<
    Application[]
  >([]);

  const succesfullyMessage = `The guide was ${modal}d succesfully`;
  const optionsGuideCode = [{ Name: "Entry" }, { Name: "Exit" }];

  const { control, setValue, reset, handleSubmit } = useForm<CatalogGuide>({
    defaultValues: CATALOG_GUIDE_INITIAL_VALUES,
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<CatalogGuide> = async (data) => {
    setIsSubmitLoading(true);
    if (modal === "delete") {
      const guideResp = await deleteGuide(data.PartId);
      if (guideResp.ok) {
        setNotificationMessage(succesfullyMessage);
        onHide(true);
      } else {
        setError(guideResp.message);
      }
    } else {
      if (
        guide?.GuideTypeCode !== data.GuideTypeCode ||
        guide?.PartName !== data.PartName
      ) {
        const guideResp = await upsertGuide(data);
        if (guideResp.ok) {
          const partId =
            guide?.PartId || get(guideResp, "data.returnValue", null);
          if (partId) {
            const resp = await setApplicationsToPart(
              partId,
              applicationsSelected
            );
            if (!resp.ok) setError(resp.message);
          }
          setNotificationMessage(succesfullyMessage);
          onHide(true);
        } else {
          setError(guideResp.message);
        }
      }
    }
    setIsSubmitLoading(false);
  };

  useEffect(() => {
    reset(CATALOG_GUIDE_INITIAL_VALUES);

    setIsSubmitLoading(false);
    if (guide) {
      (async () => {
        setIsLoading(true);

        const resp = await getApplicationsByPart(guide.PartId);
        if (resp.ok)
          setApplicationsOfPart(get(resp, "data.tables[0].rows", []));
        else setError(resp.message);

        setValue("PartId", guide?.PartId);
        setValue("PartName", guide?.PartName ?? "");
        setValue("RFIDTag", guide?.RFIDTag ?? "");
        setValue("LocationName", guide?.LocationName ?? "");
        setValue(
          "GuideTypeCode",
          guide?.GuideTypeCode ? guide?.GuideTypeCode : "I"
        );
        setIsLoading(false);
      })();
    } else {
      setApplicationsOfPart([]);
      setApplicationsSelected([]);
    }
  }, [modal, guide]);

  return (
    <>
      {guide === null && modal !== "create" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Typography>Select a guide or create a new</Typography>
        </div>
      ) : (
        <>
          {isLoading ? (
            <CenteredLazyLoading />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={1}>
                <PartFields<CatalogGuide>
                  control={control}
                  locationSizeMd={6}
                  disabled={modal === "delete"}
                />
                <Grid item xs={12} md={6}>
                  <Controller
                    name="GuideTypeCode"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        id="clear-on-escape"
                        clearOnEscape
                        options={optionsGuideCode}
                        getOptionLabel={(option) => option.Name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Code"
                            variant="outlined"
                            fullWidth
                            size="small"
                          />
                        )}
                        value={
                          field.value === "O"
                            ? optionsGuideCode[1]
                            : optionsGuideCode[0]
                        }
                        onChange={(event, newValue) => {
                          if (newValue) {
                            const formattedValue =
                              newValue.Name === "Exit" ? "O" : "I";
                            field.onChange(formattedValue);
                          }
                        }}
                        disabled={modal === "delete"}
                      />
                    )}
                  />
                </Grid>
                {!isLoading && (
                  <ApplicationGrid
                    onSelectionChanged={setApplicationsSelected}
                    disabled={modal === "delete"}
                    applicationsOfPart={applicationsOfPart}
                  />
                )}
                <Grid
                  container
                  spacing={2}
                  justifyContent="flex-end"
                  style={{ margin: 15 }}
                >
                  <FooterModalButtons
                    isSubmitLoading={isSubmitLoading}
                    onHide={() => onHide(false)}
                    buttonLabel={modal === "delete" ? "Delete" : "Save"}
                    buttonColor={modal === "delete" ? "secondary" : "primary"}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </>
      )}
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
