import { useContextMenuMESF } from "@dexteel/mesf-core";
import { Grid, Typography } from "@material-ui/core";
import {
  GetContextMenuItemsParams,
  GridApi,
  MenuItemDef,
} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";

import { useGridDefinitions } from "../../../../../../controls/ag-grid/components/GridDefinitions";
import { FooterButton } from "../../../../../../controls/buttons/FooterButton";
import { CenteredLazyLoading } from "../../../../../../controls/LazyLoading";

import { CatalogCarrier } from "../../../../models/parts/CatalogCarrier";
import { useGridStyles } from "../../../../styles/gridStyles";
import { useCarrierOptionsFunctions } from "../hooks/useCarrierOptionsFunctions";
import { useTableData } from "../hooks/useTableData";
import { UpsertDeleteModal } from "./UpsertDeleteModal";

type Props = {
  getCatalogCarriersFromAPI: Function;
  isLoading: boolean;
};
export const TableCarrier = ({
  getCatalogCarriersFromAPI,
  isLoading,
}: Props) => {
  const classes = useGridStyles();

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [showModal, setShowModal] = useState<
    "create" | "update" | "delete" | ""
  >("");
  const [carrierId, setCarrierId] = useState<number | null>(null);

  const { showContextMenu, registerConfig } = useContextMenuMESF();
  const { getMenuOptions } = useCarrierOptionsFunctions({
    setCarrierId,
    setShowModal,
  });
  const { rows, columnDefs } = useTableData({
    setCarrierId,
    setShowModal,
    showContextMenu,
  });

  const { columnDefaults } = useGridDefinitions({
    OnEdit: (data: any) => {
      setShowModal("update");
      setCarrierId(data.Id);
    },
  });
  const onRowDoubleClicked = (event: any) => {
    setCarrierId((event.data as CatalogCarrier).PartId);
    setShowModal("update");
  };

  const getContextMenuItems = React.useCallback(
    (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      const data = params.node?.data;
      showContextMenu(event as any, data, "carrierContext");
      if (data) {
        params.api.deselectAll();
        params.node?.setSelected(true);
      }
      return [];
    },
    [rows]
  );

  const onModalHide = (shouldUpdate: boolean) => {
    if (shouldUpdate)
      getCatalogCarriersFromAPI().then(() => {
        gridApi?.paginationGoToLastPage();
      });
    setShowModal("");
  };

  useEffect(() => {
    if (isLoading) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  }, [isLoading, rows, gridApi]);

  useEffect(() => {
    registerConfig({
      id: "carrierContext",
      getOptions: getMenuOptions,
    });
  }, []);

  return (
    <Grid container justifyContent="center">
      <Grid item md={12} xs={12} style={{ padding: "0 15px" }}>
        <Typography
          variant="h5"
          style={{ margin: "1px 0 10px", fontWeight: 600 }}
        >
          Carriers
        </Typography>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.root}
        style={{ width: "100%" }}
      >
        <Grid
          item
          md={12}
          xs={12}
          style={{ padding: "0 15px" }}
          className={classes.relative}
        >
          <Grid item md={12} xs={12} className={classes.table}>
            <div
              style={{ height: "100%", width: "100%" }}
              className="ag-theme-alpine"
            >
              <AgGridReact<CatalogCarrier>
                rowData={rows || []}
                columnDefs={columnDefs}
                defaultColDef={columnDefaults}
                rowHeight={34}
                headerHeight={34}
                animateRows={true}
                pagination={true}
                rowSelection="single"
                paginationPageSize={10}
                getContextMenuItems={getContextMenuItems}
                onRowDoubleClicked={onRowDoubleClicked}
                onGridReady={(params) => setGridApi(params.api)}
                getRowId={(params) => String(params.data.PartId)}
                loadingOverlayComponent={CenteredLazyLoading}
              />
            </div>
          </Grid>
          <FooterButton
            label="CARRIER"
            showCreateModal={showModal === "create"}
            setShowCreateModal={(showCreateModal) =>
              showCreateModal ? setShowModal("create") : setShowModal("")
            }
          />
        </Grid>
      </Grid>
      <UpsertDeleteModal
        CarrierId={carrierId}
        onHide={onModalHide}
        modal={showModal}
      />
    </Grid>
  );
};
