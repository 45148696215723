import { useRef, useState } from "react";
import Tippy from "@tippyjs/react";
import numeral from 'numeral';
import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import UnCheckBoxIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import LinkIcon from "@material-ui/icons/Link";
import UnLinkIcon from "@material-ui/icons/LinkOff";
import OptionsIcon from "@material-ui/icons/MoreVert";
import RestoreIcon from "@material-ui/icons/Restore";
import SendIcon from "@material-ui/icons/Send";
import { ICellRendererParams } from "ag-grid-enterprise";
import { dxtToLocalServerTime, dxtToUTC } from "@dexteel/mesf-core";
import { useGridStyles } from "../styles/gridStyles";
import { useStyles } from "../styles/styles";

type Props = ICellRendererParams & {
	dateTimeFormat?: string;
	convertToLocalTime?: boolean;
	numericFormat?: string;
	clicked: Function;
	isEnabled: string;
	linkProperty?: string;
	link?: string;
	popUpOptions?: Record<string, string>[];
};
export const ToggleStatusButtonCellRenderer = (props: Props) => {
	if (props.data[props.isEnabled] === true)
		return (
			<>
				<DeleteButtonCellRenderer {...props} />
			</>
		);
	else
		return (
			<>
				<ActiveButtonCellRenderer {...props} />
			</>
		);
};
export const ToggleActiveButtonCellRenderer = (props: Props) => {
	if (props.value === true)
		return (
			<>
				<DeleteButtonCellRenderer {...props} />
			</>
		);
	else
		return (
			<>
				<ActiveButtonCellRenderer {...props} />
			</>
		);
};
export const EditButtonCellRenderer = (props: Props) => {
	function btnClickedHandler() {
		(async () => {
			await props.clicked(props.data);
		})();
	}
	return (
		<>
			<IconButton
				aria-label="edit"
				size="small"
				title="Edit"
				color="primary"
				style={{ cursor: "pointer" }}
				onClick={btnClickedHandler}
			>
				<EditIcon fontSize="inherit" style={{ cursor: "pointer" }} />
			</IconButton>
		</>
	);
};
export const DeleteButtonCellRenderer = (props: Props) => {
	function btnClickedHandler() {
		(async () => {
			if (props.clicked === undefined) return;
			await props.clicked(props.data);
		})();
	}
	return (
		<>
			<IconButton
				aria-label="delete"
				size="small"
				title="Delete"
				style={{ cursor: "pointer" }}
				onClick={btnClickedHandler}
			>
				<DeleteIcon
					fontSize="inherit"
					color="secondary"
					style={{ cursor: "pointer" }}
				/>
			</IconButton>
		</>
	);
};
export const ActiveButtonCellRenderer = (props: Props) => {
	function btnClickedHandler() {
		if (props.clicked === undefined) return;
		props.clicked(props.data);
	}
	return (
		<>
			<IconButton
				aria-label="active"
				title="Active"
				size="small"
				style={{ cursor: "pointer" }}
				onClick={btnClickedHandler}
			>
				<RestoreIcon
					fontSize="inherit"
					color="primary"
					style={{ cursor: "pointer" }}
				/>
			</IconButton>
		</>
	);
};
export const SendButtonCellRenderer = (props: Props) => {
	function btnClickedHandler() {
		(async () => {
			await props.clicked(props.data);
		})();
	}
	if (props.data.Sent === true) return <></>;

	if (props.data.ChecksPassed === false) {
		return (
			<>
				<IconButton
					aria-label="edit"
					size="small"
					title="Edit"
					color="primary"
					style={{ cursor: "pointer" }}
					onClick={btnClickedHandler}
				>
					<SendIcon fontSize="inherit" style={{ cursor: "pointer" }} />
				</IconButton>
			</>
		);
	} else return <></>;
};
export const LinkButtonCellRenderer = (props: Props) => {
	function btnClickedHandler() {
		(async () => {
			await props.clicked(props.data);
		})();
	}
	return (
		<>
			<IconButton
				aria-label="link"
				size="small"
				title="Link"
				color="primary"
				style={{ cursor: "pointer" }}
				onClick={btnClickedHandler}
			>
				<LinkIcon fontSize="inherit" style={{ cursor: "pointer" }} />
			</IconButton>
		</>
	);
};
export const BooleanCellRenderer = (props: Props) => {
	if (props.value === true)
		return (
			<>
				<CheckBoxIcon fontSize="small" color="primary" />
			</>
		);
	return (
		<>
			<UnCheckBoxIcon fontSize="small" color="primary" />
		</>
	);
};
export const BooleanTextCellRenderer = (props: Props) => {
	if (props.value === true)
		return (
			<>
				<span>Y</span>
			</>
		);
	return (
		<>
			<span>F</span>
		</>
	);
};
export const ManualIconCellRenderer = (props: Props) => {
	const isManual = props.data.IsManual;
	const classes = useStyles();
	return (
		<>
			{isManual ? (
				<div>
					<Button
						aria-label={"Manual"}
						title={"Manual"}
						variant="text"
						size="small"
						className={`${classes.simpleButton} ${classes.manualButton}`}
						style={{ cursor: "auto" }}
					>
						M
					</Button>
				</div>
			) : (
				<></>
			)}
		</>
	);
};

export const UnLinkButtonCellRenderer = (props: Props) => {
	function btnClickedHandler() {
		(async () => {
			await props.clicked(props.data);
		})();
	}
	return (
		<>
			<IconButton
				aria-label="unlink"
				size="small"
				title="Unlink"
				color="primary"
				style={{ cursor: "pointer" }}
				onClick={btnClickedHandler}
			>
				<UnLinkIcon fontSize="inherit" style={{ cursor: "pointer" }} />
			</IconButton>
		</>
	);
};
export const DateTimeCellRenderer = (props: Props) => {
	if (props.value === null || props.value === undefined) return <></>;
	let value;
	if (props.convertToLocalTime) {
		value = dxtToLocalServerTime(props.value, props.dateTimeFormat || "HH:mm");
	} else {
		value = dxtToUTC(props.value, props.dateTimeFormat || "HH:mm");
	}
	return (
		<>
			<span>{value}</span>
		</>
	);
};
export const NumericCellRenderer = (props: Props) => {
	if (props.value === null || props.value === undefined) return <></>;

	const m = numeral(props.value);
	const format = props.numericFormat === "" ? "0.00" : props.numericFormat;
	const formatCell = Number.isNaN(m.value) ? "NaN" : m.format(format);

	return <span>{formatCell}</span>;
};
export const LinkCellRenderer = (props: Props) => {
	if (props.value === null || props.value === undefined) return <></>;
	if (props.link) {
		return (
			<>
				<a href={props.link} target="_self" rel="noopener noreferrer">
					{props.value}
				</a>
			</>
		);
	}
	const link = props.data[props.linkProperty ?? ""] ?? "";
	return (
		<>
			<a href={link} target="_self" rel="noopener noreferrer">
				{props.value}
			</a>
		</>
	);
};
export const LinkAndIconCellRenderer = (props: Props) => {
	if (props.value === null || props.value === undefined) return <></>;
	const link = props.data[props.linkProperty ?? ""] ?? "";
	const isManual = props.data.IsManual;
	const classes = useStyles();
	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<div>
				<a href={link} target="_self" rel="noopener noreferrer">
					{props.value}
				</a>
			</div>
			{!isManual && (
				<div style={{ padding: "0 2rem" }}>
					<Button
						aria-label={"Manual"}
						title={"Manual"}
						variant="text"
						size="small"
						className={`${classes.simpleButton} ${classes.manualButton}`}
						style={{ cursor: "auto" }}
					>
						M
					</Button>
				</div>
			)}
		</div>
	);
};
export const PopupCellRenderer = (props: Props) => {
	const tippyRef = useRef();
	const [visible, setVisible] = useState(false);
	const show = () => setVisible(true);
	const hide = () => setVisible(false);
	const classes = useGridStyles();

	const dropDownContent = (
		<div className={classes.menuContainer}>
			{props.popUpOptions?.map((record) => (
				<div
					key={record.key}
					onClick={() => onClickHandler(record.key)}
					className={classes.menuItem}
				>
					{record.value}
				</div>
			))}
		</div>
	);

	const onClickHandler = (option: string) => {
		hide();
		if (props.clicked === undefined) return;
		props.clicked(props.data, option);
	};

	return (
		<Tippy
			content={dropDownContent}
			visible={visible}
			onClickOutside={hide}
			allowHTML={true}
			arrow={false}
			appendTo={document.body}
			interactive={true}
			placement="right"
		>
			<IconButton
				aria-label="active"
				title="Options"
				size="small"
				style={{ cursor: "pointer" }}
				onClick={visible ? hide : show}
			>
				<OptionsIcon
					fontSize="inherit"
					color="primary"
					style={{ cursor: "pointer" }}
				/>
			</IconButton>
		</Tippy>
	);
};
