import { CatalogCarrierPage } from "./CatalogCarrierPage";
import { CatalogCarrierProvider } from "./context/CatalogCarrierContext";

const CatalogCarrier = () => {
  return (
    <CatalogCarrierProvider>
      <CatalogCarrierPage />
    </CatalogCarrierProvider>
  );
};
export default CatalogCarrier;
